import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import If from 'app/components/If';
import { isEmpty } from 'lodash';

function BranchCell(params: CustomCellRendererProps) {
  return (
    <If condition={!isEmpty(params.value.branchNameData)} otherwise="_">
      <Typography fontSize="0.75rem">{params.value.branchNameData}</Typography>
    </If>
  );
}

export default BranchCell;
