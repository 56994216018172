import { Grid3x3Outlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { Star } from '@phosphor-icons/react';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getImgCdn } from 'common/helpers/cdn';
import { themes } from 'styles/theme/themes';
import { selectTheme } from 'styles/theme/slice/selectors';

import { CatalogContext } from 'app/pages/Ordoria/Catalog';
import { Selector } from '../../../Category/Selector';
import { CookState } from '../../../CookState';
import { StoringState } from '../../../StoringState';
import { UCCell } from '../../../Cells/UCCell';
import { WeightCell } from '../../../Cells/WeightCell';
import { PriceCell } from '../../../Cells/PriceCell';
import { QuantityInputCell } from '../../../Cells/QuantityInputCell';

import EmptyBoxIcon from 'assets/img/ORDORIA/Orders/EmptyBoxIcon.svg';
import FavoriteDefault from 'assets/img/ORDORIA/Catalog/FavoriteDefault.svg';
import FavoriteFilled from 'assets/img/ORDORIA/Catalog/FavoriteFilled.svg';

import { Icon } from 'app/components/Icon';
import If from 'app/components/If';
import EllipsisText from 'app/components/EllipsisText';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { toTitleCase } from 'common/helpers/stringFormatter';
import { useToggleFavoriteItemsMutation } from 'common/services/customerApi';
import { useToaster } from 'hooks/useToaster';
import { FlagCell } from '../../../Cells/FlagCell';

export const ProductCard = ({ product, categoryId, handleSelectionChange }) => {
  const { selectedProducts, viewMode, isCustomer } = useContext(CatalogContext);
  const [isFavorite, setIsFavorite] = useState(false);

  const theme = useSelector(selectTheme);
  const { t } = useTranslation();
  const toast = useToaster();
  const [toggleFavoriteItems] = useToggleFavoriteItemsMutation();

  const gridContext = useManagedContext<IGridContext>('grid');

  const isSelected = useMemo(
    () => selectedProducts?.categories?.[categoryId]?.includes(product?._id),
    [selectedProducts, product],
  );

  const imageSrc = useMemo(
    () =>
      product?.pictures?.[0]?.key
        ? getImgCdn(product.pictures[0].key, 512)
        : '',
    [product],
  );

  const toggleFavorite = async () => {
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);
    try {
      const response = await toggleFavoriteItems({
        products: [product?._id],
      }).unwrap();
      if (response?.error) {
        throw new Error(response.error);
      }
      toast(3000, 'success', t('toggle_fav.success'));
    } catch (error: any) {
      setIsFavorite(!newFavoriteStatus);
      toast(3000, 'error', error?.data?.message);
    }
  };

  const handleOpenDetailsDrawer = () => {
    gridContext.updateDataWithFunction((prev) => {
      prev.selectedRow = product;
    });
  };

  return (
    <Wrapper selected={isSelected}>
      <ProductIconContainer
        viewMode={viewMode}
        onClick={handleOpenDetailsDrawer}
      >
        <If condition={!!imageSrc} otherwise={<NoImage src={EmptyBoxIcon} />}>
          <img src={imageSrc} alt={product?.name || 'Product'} />
        </If>

        <SelectorContainer>
          <Selector
            parent="item"
            action={() => handleSelectionChange(product?._id)}
            active={isSelected}
            styles={{ border: `1px solid ${themes?.default?.silver}` }}
          />
        </SelectorContainer>

        <If condition={isCustomer}>
          <IconButton
            onClick={(e) => {
              e?.stopPropagation();
              e?.preventDefault();
              toggleFavorite();
            }}
            sx={favoriteButtonStyle}
          >
            <Icon
              icon={
                <img
                  src={isFavorite ? FavoriteFilled : FavoriteDefault}
                  alt="Favorite"
                />
              }
              size={20}
              color={themes?.default?.iconColor}
              style={{ cursor: 'pointer' }}
            />
          </IconButton>
        </If>
      </ProductIconContainer>

      <Stack gap="10px">
        <ProductInfo
          product={product}
          viewMode={viewMode}
          theme={theme}
          t={t}
          handleProductDetails={handleOpenDetailsDrawer}
        />

        <Stack flexDirection="row" gap="4px">
          <FlagCell value={{ orderFrequency: product?.orderFrequency }} />

          <PriceCell
            value={{
              cellData: {
                price: product?.boxPrice,
                discount: product?.discount,
                inverseValues: true,
              },
            }}
          />
        </Stack>
      </Stack>

      <Stack alignItems="flex-end">
        <QuantityInputCell value={{ cellData: { id: product?._id } }} />
      </Stack>
    </Wrapper>
  );
};

const ProductInfo = ({ product, viewMode, theme, t, handleProductDetails }) => (
  <div>
    <Stack flexDirection="row" gap="8px" alignItems="center">
      <ItemCodeContainer viewMode={viewMode} onClick={handleProductDetails}>
        <Icon
          icon={<Grid3x3Outlined sx={{ width: 16, height: 16 }} />}
          size={8}
          color={`${themes?.default?.black}99`}
        />
        {product?.code}
      </ItemCodeContainer>

      <If condition={product?.cookState}>
        <CookState state={product.cookState} />
      </If>
      <If condition={product?.storingState}>
        <StoringState state={product.storingState} />
      </If>
      <If condition={product?.new}>
        <NewChip theme={theme}>
          <Icon
            icon={<Star weight="fill" />}
            size={10}
            color={themes?.default?.accordionWhiteBg}
          />
          {t('new')}
        </NewChip>
      </If>
    </Stack>

    <ItemNameContainer viewMode={viewMode} onClick={handleProductDetails}>
      <EllipsisText text={toTitleCase(product?.name || '')} width={240} />
    </ItemNameContainer>

    <Stack gap="8px" flexDirection="row" marginTop="6px">
      <If condition={product?.boxingUnit}>
        <UCCell value={{ cellData: product.boxingUnit }} />
      </If>
      <If condition={product?.weight}>
        <WeightCell value={{ cellData: product.weight?.toFixed(2) }} />
      </If>
    </Stack>
  </div>
);

const Wrapper = styled.div<{ selected?: boolean }>`
  height: fit-content;
  width: 100%;
  padding: 12px;
  border: 1px solid
    ${(props) =>
      props.selected
        ? themes?.default?.ordoriaLightBorder
        : `${themes?.default?.black}1F`};
  background: ${(props) =>
    props.selected
      ? themes?.default?.ordoriaLightBackground
      : themes?.default?.accordionWhiteBg};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  transition-duration: 0.15s;
  position: relative;
`;

const ProductIconContainer = styled.div<{ viewMode }>`
  height: 126px;
  width: 100%;
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
`;

const SelectorContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 7px;
  right: 7px;
  background: ${themes?.default?.accordionWhiteBg};
  border-radius: 2px;
`;

const ItemCodeContainer = styled.div<{ viewMode }>`
  font-size: 0.75rem;
  line-height: 18px;
  color: ${themes?.default?.black}99;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 18px;
`;

const ItemNameContainer = styled.div<{ viewMode }>`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16px;
  color: ${themes?.default?.black}DE;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const NewChip = styled.div`
  height: 14px;
  padding: 1px 6px;
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.primary};
  font-size: 0.625rem;
  line-height: 10px;
  color: ${themes?.default?.accordionWhiteBg};
`;

const NoImage = ({ src }) => (
  <div
    style={{
      height: '126px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `10px solid ${themes?.default?.gainsboro2}`,
      borderRadius: '6px',
    }}
  >
    <img src={src} alt="No image" style={{ height: '45px', width: '80px' }} />
  </div>
);

const favoriteButtonStyle = {
  position: 'absolute',
  top: '2px',
  left: '2px',
  borderRadius: '50px',
  padding: '4px',
};
