import { useGetUserAcl } from 'common/hooks/useGetUserAcl';
import { CreateOrderDrawer } from '../CreateOrderDrawer';
import { CreateOrderDrawerSales } from '../CreateOrderDrawerSales';
import moment from 'moment';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedCustomer?: any;
  selectedProducts?: any;
  defaultDeliveryDate?: any;
  type?: string;
  callId?: string;
  statusOrderedCallId?: string;
}

export const ManagedCreateOrderDrawer = ({
  open,
  onClose,
  selectedCustomer,
  selectedProducts,
  defaultDeliveryDate,
  type,
  callId,
  statusOrderedCallId,
}: Props) => {
  const { isCustomer } = useGetUserAcl();

  return (
    <>
      {isCustomer() ? (
        <CreateOrderDrawer
          open={open}
          closeDrawer={onClose}
          selectedProducts={selectedProducts}
        />
      ) : (
        <CreateOrderDrawerSales
          open={open}
          closeDrawer={onClose}
          selectedCustomer={selectedCustomer}
          selectedProducts={selectedProducts}
          defaultDeliveryDate={moment(defaultDeliveryDate)}
          type={type}
          callId={callId}
          statusOrderedCallId={statusOrderedCallId}
        />
      )}
    </>
  );
};
