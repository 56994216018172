import { ActionPopover } from 'app/components/Popover';
import { Typography } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { DotsThree } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { useMemo } from 'react';

interface EllipsisProps {
  status: string;
  createdBySales: boolean;
  handleDeleteOrderDialogOpen: Function;
  handleSaveAsTemplate: Function;
  handleCancelOrderDialogOpen?: Function;
}

export function InsideOrderEllipsisSales({
  status,
  createdBySales,
  handleDeleteOrderDialogOpen,
  handleSaveAsTemplate,
  handleCancelOrderDialogOpen,
}: EllipsisProps) {
  const actions = useMemo(() => {
    return getStatusActions(status, createdBySales);
  }, [status, createdBySales]);

  const shouldRenderActionPopover = status !== 'blocked';

  return shouldRenderActionPopover ? (
    <ActionPopover
      id={status}
      styling={{ margin: '0' }}
      iconAction={
        <Icon
          icon={<DotsThree weight="bold" />}
          size={18}
          color={themes.default.actionButtonColor}
        />
      }
      handleAction={(_event, action) => {
        if (action === 'delete' && handleDeleteOrderDialogOpen) {
          handleDeleteOrderDialogOpen();
        } else if (action === 'saveTemplate' && handleSaveAsTemplate) {
          handleSaveAsTemplate();
        } else if (action === 'cancel' && handleCancelOrderDialogOpen) {
          handleCancelOrderDialogOpen();
        }
      }}
      actions={actions}
    />
  ) : null;
}

const getStatusActions = (status, createdBySales) => {
  const commonActions = [
    {
      action: 'saveTemplate',
      element: (
        <Typography sx={{ padding: '0px 12px' }}>Save as Template</Typography>
      ),
    },
  ];

  const deleteAction = {
    action: 'delete',
    element: (
      <Typography sx={{ color: themes.default.chipRedBg, padding: '0px 12px' }}>
        Delete
      </Typography>
    ),
  };

  const cancelAction = {
    action: 'cancel',
    element: (
      <Typography sx={{ color: themes.default.chipRedBg, padding: '0px 12px' }}>
        Cancel Order
      </Typography>
    ),
  };

  const salesActions = {
    initiated: [...commonActions, deleteAction],
    'initiated-customer': [...commonActions, deleteAction],
    pending: [...commonActions],
    'pending-updates': [...commonActions, cancelAction],
    'pending-customer': [...commonActions, cancelAction],
    validated: [...commonActions, cancelAction],
    confirmed: [...commonActions, cancelAction],
    prepared: [...commonActions, cancelAction],
    loaded: [...commonActions, cancelAction],
    'ready-for-delivery': [...commonActions, cancelAction],
    'on-the-road': [...commonActions, cancelAction],
    delivered: commonActions,
    canceled: [...commonActions, deleteAction],
  };

  const customerActions = {
    initiated: [...commonActions, deleteAction],
    'initiated-customer': [...commonActions, deleteAction],
    pending: [...commonActions, cancelAction],
    'pending-updates': [...commonActions, cancelAction],
    'pending-customer': [...commonActions, cancelAction],
    validated: [...commonActions, cancelAction],
    confirmed: [...commonActions],
    prepared: [...commonActions],
    loaded: [...commonActions],
    'ready-for-delivery': [...commonActions],
    'on-the-road': [...commonActions],
    delivered: commonActions,
    canceled: commonActions,
  };

  return createdBySales
    ? salesActions[status] || []
    : customerActions[status] || [];
};
