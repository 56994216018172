import {
  useChangeStatusMutation,
  usePatchConversationMutation,
} from 'common/services/conversationApi';
import { useUploadMutation } from 'common/services/files';
import { createContext, useState } from 'react';

export const ClaimsContext = createContext({
  openDetail: false,
  setOpenDetail: (_openDetail: boolean) => {},
  selectedClaimId: '',
  setSelectedClaimId: (_selectedClaimId: string) => {},
  handleUploadFile: async (
    _file: File,
    _objectType: string,
    _objectId: string,
  ): Promise<string> => {
    return '';
  },
  changeClaimStatus: (_status: string, _claimId: string) => {},
  updateArchiveClaim: (_archiveState: boolean, _claimId: string) => {},
});

export const ClaimsProvider = ({ children }) => {
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [selectedClaimId, setSelectedClaimId] = useState<string>('');

  const [uploadFiles] = useUploadMutation();
  const [patchConversation] = usePatchConversationMutation();
  const [changeStatus] = useChangeStatusMutation();

  async function handleUploadFile(
    file: File,
    objectType: string,
    _objectId: string,
  ): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await uploadFiles({
      objectType: objectType,
      objectId: _objectId,
      formData: formData,
      persist: true,
      preserveName: false,
    }).unwrap();
    return response?.url;
  }

  function changeClaimStatus(status: string, claimId: string) {
    changeStatus({
      conversationId: claimId,
      body: {
        status: status,
      },
    });
  }

  function updateArchiveClaim(archiveState: boolean, claimId: string) {
    patchConversation({
      conversationId: claimId,
      body: {
        isArchived: archiveState,
      },
    });
  }

  return (
    <ClaimsContext.Provider
      value={{
        openDetail,
        setOpenDetail,
        selectedClaimId,
        setSelectedClaimId,
        handleUploadFile,
        changeClaimStatus,
        updateArchiveClaim,
      }}
    >
      {children}
    </ClaimsContext.Provider>
  );
};
