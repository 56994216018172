import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { AcceptedFiles } from 'app/components/AcceptedFiles/Index';
import { FormActions } from 'app/components/Form/styles';
import { UploadedFile } from 'app/components/UploadComponent';
import { UploadZone } from 'app/components/UploadFiles';
import { CLAIMS_TYPES } from 'app/pages/Ordoria/Claims/config';
import { useDebounce } from 'common/hooks/useDebounce';
import { usePostConversationMutation } from 'common/services/conversationApi';
import {
  useGetCustomersBasicQuery,
  useGetCustomersBasicsBranchesQuery,
  useGetCustomersOrdersBasicsQuery,
} from 'common/services/customerApi';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import {
  selectClaimReasons,
  selectContactAddresses,
} from 'common/store/organization/selectors';
import { useQueryParams } from 'hooks/useQueryParams';
import { useToaster } from 'hooks/useToaster';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ClaimIcon } from '../../../ClaimIcon';
import { useUploadMutation } from 'common/services/files';
import { stringAvatar } from 'app/helpers/helpers';
import { UserDoc } from 'common/types/User';
import CancelIcon from '@mui/icons-material/Cancel';
import If from 'app/components/If';

interface ClaimFormProps {
  handleClose: () => void;
  customer?: any;
  order?: any;
}

export function ClaimForm({ handleClose, customer, order }: ClaimFormProps) {
  const { i18n, t } = useTranslation();
  const toast = useToaster();

  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    customer?._id || null,
  );
  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
  const [selectedReason, setSelectedReason] = useState<string | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [selectedOrderId, setSelectedOrderId] = useState<any | null>(
    order?._id || null,
  );
  const [topic, setTopic] = useState<string | null>(null);
  const [content, setContent] = useState<string | null>(null);
  const [subject, setSubject] = useState<string>('');
  const [selectedAgents, setSelectedAgents] = useState<UserDoc[]>([]);
  const [customersSearch, setCustomersSearch] = useState<string>('');
  const [ordersSearch, setOrdersSearch] = useState<string>('');
  const [usersSearch, setUsersSearch] = useState<string>('');
  const [branchesSearch, setBranchesSearch] = useState<string>('');
  const [reminder, setReminder] = useState<boolean>(false);

  const claimReasons = useSelector(selectClaimReasons);

  const contactAddresses = useSelector(selectContactAddresses);

  const locale = i18n.language;

  const debouncedCustomersSearch = useDebounce(customersSearch, 200);
  const debouncedOrdersSearch = useDebounce(ordersSearch, 200) || '';
  const debouncedUsersSearch = useDebounce(usersSearch, 200);
  const debouncedBranchesSearch = useDebounce(branchesSearch, 200);

  const { data: customers } = useGetCustomersBasicQuery(
    `text=${debouncedCustomersSearch}`,
  );

  const { data: users } = useGetUsersBasicQuery(
    `?text=${debouncedUsersSearch}`,
  );

  const branchesQueryParams = useQueryParams({
    text: debouncedBranchesSearch,
  });

  const { data: branches, refetch: refetchBranches } =
    useGetCustomersBasicsBranchesQuery(
      { customerId: selectedCustomerId, queryParams: branchesQueryParams },
      { skip: !selectedCustomerId },
    );

  const [postConversation] = usePostConversationMutation();
  const [uploadFiles] = useUploadMutation();

  const queryParams = useQueryParams({
    text: debouncedOrdersSearch,
  });

  const { data: orders, refetch } = useGetCustomersOrdersBasicsQuery(
    { customerId: selectedCustomerId, queryParams: queryParams },
    {
      skip: !selectedCustomerId,
    },
  );

  const onFileChange = useCallback(async (newFiles: UploadedFile[]) => {
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }, []);

  const handleFileDelete = (fileName: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  async function handleUploadFile(file): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await uploadFiles({
      objectType: 'attachment',
      objectId: selectedCustomerId,
      formData: formData,
      persist: true,
      preserveName: false,
    }).unwrap();
    return response?.url;
  }

  async function handlePostConversation() {
    try {
      let uploadedFiles: any = [];
      if (files.length > 0) {
        uploadedFiles = await Promise.all(
          files.map((file) => handleUploadFile(file)),
        );
      }

      const payload: any = {
        customerId: selectedCustomerId,
        reasonKey: selectedReason,
        contactAddressId: topic,
        customerDeckId: selectedBranchId,
        _agents: selectedAgents.map((agent) => agent?._currentAgent?._id),
        files: uploadedFiles,
        activateReminder: reminder,
      };

      if (selectedOrderId) {
        payload.orderId = selectedOrderId;
      }

      if (content) {
        payload.content = content;
      }

      if (subject) {
        payload.reasonDetail = subject;
      }

      await postConversation(payload)
        .then(() => {
          handleClose();
          toast(3000, 'success', t('claims.post.toast.success'));
        })
        .catch(() => {
          toast(3000, 'error', t('claims.post.toast.error'));
        });
    } catch (error) {
      toast(3000, 'error', t('claims.post.toast.error'));
    }
  }

  useEffect(() => {
    if (selectedCustomerId) {
      refetch();
      refetchBranches();
      setOrdersSearch('');
      setBranchesSearch('');
      setSelectedBranchId('');
    }
  }, [selectedCustomerId]);

  useEffect(() => {
    setSubject('');
  }, [selectedReason]);

  const disabledButton =
    !selectedCustomerId ||
    !selectedReason ||
    !topic ||
    (selectedReason === 'other' && !subject) ||
    !selectedBranchId;

  return (
    <Wrapper>
      <div
        className="firstSection"
        style={{
          marginBottom: '24px',
        }}
      >
        <Typography fontWeight={500} mb="16px">
          {t('reportabug-details')}
        </Typography>
        <Autocomplete
          options={contactAddresses}
          getOptionLabel={(option) => t(`claims.type.${option?.topic}`)}
          renderInput={(params) => (
            <TextField {...params} label={t('field.type')} required />
          )}
          onChange={(event, value) => {
            setTopic(value?._id || null);
          }}
          renderOption={(props, option: any) => (
            <li {...props}>
              <Chip
                label={
                  <Grid container alignItems="center" gap="6px">
                    <ClaimIcon claimType={option?.topic} />
                    <Typography fontSize="0.825rem">
                      {t(`claims.type.${option?.topic}`)}
                    </Typography>
                  </Grid>
                }
                size="small"
                sx={{
                  backgroundColor:
                    CLAIMS_TYPES[option?.topic?.toUpperCase()]?.backgroundColor,
                  color: CLAIMS_TYPES[option?.topic?.toUpperCase()]?.color,
                  borderRadius: '4px',
                }}
              />
            </li>
          )}
        />
        <Autocomplete
          options={customers?.docs || []}
          defaultValue={customer ?? null}
          getOptionLabel={(option: any) => option?.legalName || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('common.customer')}
              onChange={(e) => {
                setCustomersSearch(e?.target?.value);
              }}
              required
            />
          )}
          renderOption={(props, option: any) => (
            <li {...props}>
              <Typography>{option.legalName}</Typography>
            </li>
          )}
          onChange={(event, value: any) => {
            setSelectedCustomerId(value?._id || null);
          }}
          isOptionEqualToValue={(option, value) => option._id === value._id}
        />
        <Autocomplete
          options={branches || []}
          getOptionLabel={(option: any) =>
            option?.location?.shortAddress || option?.location?.name || ''
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('claims.field.client.branch')}
              required
              onChange={(e) => {
                setBranchesSearch(e?.target?.value);
              }}
            />
          )}
          renderOption={(props, option: any) => (
            <li {...props}>
              <Typography>
                {option.location.shortAddress || option.location.name}
              </Typography>
            </li>
          )}
          onChange={(event, value: any) => {
            setSelectedBranchId(value?._id || null);
          }}
        />
        <Autocomplete
          options={orders?.docs || []}
          defaultValue={order ?? null}
          value={selectedOrderId?.code}
          renderOption={(props, option: any) => (
            <li {...props}>
              <Typography>{option.code}</Typography>
            </li>
          )}
          onChange={(event, value: any) => {
            setSelectedOrderId(value?._id || null);
          }}
          getOptionLabel={(option: any) => option.code}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('autocomplete.title.orders')}
              onChange={(e) => {
                setOrdersSearch(e?.target?.value);
              }}
            />
          )}
        />
        <Autocomplete
          options={claimReasons}
          renderInput={(params) => (
            <TextField {...params} label={t('common.reason')} required />
          )}
          getOptionLabel={(option) => option.i18n[locale]}
          onChange={(event, value) => {
            setSelectedReason(value?.key || null);
          }}
        />
        <If condition={selectedReason === 'other'}>
          <TextField
            label={t('common.specify_reason')}
            onChange={(event) => {
              setSubject(event.target.value);
            }}
            required
            sx={{
              width: '100%',
            }}
          />
        </If>
        <Autocomplete
          options={users?.docs || []}
          getOptionLabel={(option) => option?.fullName || ''}
          value={selectedAgents}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('accessories.assignee')}
              onChange={(e) => {
                setUsersSearch(e?.target?.value);
              }}
            />
          )}
          onChange={(event, value: any[]) => {
            setSelectedAgents(value);
          }}
          renderTags={(values) => (
            <>
              {values.map((option) => (
                <Chip
                  key={option?._id || option.id}
                  label={
                    <Grid container alignItems="center" gap="6px">
                      <Avatar
                        sx={{
                          width: '22px',
                          height: '22px',
                          fontSize: '0.725rem',
                        }}
                      >
                        {stringAvatar(option?.fullName)}
                      </Avatar>
                      <Typography fontSize="0.825rem">
                        {option?.fullName}
                      </Typography>
                    </Grid>
                  }
                  sx={{
                    margin: '4px',
                  }}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={() => {
                    setSelectedAgents((prev) =>
                      prev.filter((agent) => agent._id !== option._id),
                    );
                  }}
                />
              ))}
            </>
          )}
          isOptionEqualToValue={(option, value) => option._id === value._id}
        />
        <TextField
          label="Message"
          sx={{
            width: '100%',
          }}
          multiline
          rows={4}
          onChange={(event) => {
            setContent(event.target.value);
          }}
        />
        <FormControlLabel
          control={<Switch />}
          label={t('claims.ask-reminders')}
          onChange={() => {
            setReminder(!reminder);
          }}
        />
      </div>
      <div className="secondSection">
        <Typography fontWeight={500} mb="16px">
          {t('common.attachments')}
        </Typography>
        <UploadZone onOk={onFileChange} onUnOk={() => setFiles([])} />
        <div style={{ marginBottom: '20px' }}>
          {files.map((file) => (
            <AcceptedFiles
              key={file.name}
              file={file}
              statusFile={{ [file.name]: true }}
              hideProgressBar={{ [file.name]: true }}
              fileProgress={{ [file.name]: true }}
              deleteFile={() => handleFileDelete(file.name)}
            />
          ))}
        </div>
      </div>
      <FormActions>
        <Button
          disableElevation
          sx={{ color: themes.default.black60 }}
          onClick={handleClose}
        >
          {t('common.buttons.cancel')}
        </Button>

        <Button
          variant="contained"
          onClick={handlePostConversation}
          disabled={disabledButton}
        >
          {t('add')}
        </Button>
      </FormActions>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 24px 20px;
`;
