import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Grid, Typography } from '@mui/material';
import { Paperclip } from '@phosphor-icons/react';
import { useState } from 'react';
import { themes } from 'styles/theme/themes';
import { AttachmentModal } from '../components/ClaimDetail/components/LeftDetail/components/AttachmentsModal';

function AttachmentsCell(params: CustomCellRendererProps) {
  const [open, setOpen] = useState<boolean>(false);
  const filesCount = params.value.attachmentsData?.length || 0;

  return (
    <>
      <Grid
        container
        alignItems="center"
        gap="4px"
        onClick={() => {
          filesCount > 0 && setOpen(true);
        }}
        sx={{
          width: 'fit-content',
          padding: '4px',
          ':hover': {
            cursor: filesCount > 0 ? 'pointer' : 'default',
            background:
              filesCount > 0 ? themes?.default?.rippleGrey : 'transparent',
          },
        }}
      >
        <Paperclip
          color={themes?.default?.grey2}
          style={{
            width: '20px',
            height: '20px',
          }}
        />
        <Typography color={themes?.default?.grey2} fontWeight="0.75rem">
          {filesCount}
        </Typography>
      </Grid>
      <AttachmentModal
        open={open}
        handleClose={() => setOpen(false)}
        files={params?.value?.attachmentsData}
      />
    </>
  );
}

export default AttachmentsCell;
