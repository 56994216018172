import React, { useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
// import { Check } from '@phosphor-icons/react';
import If from 'app/components/If';
import { CSSProperties } from '@mui/styles';
import { Icon } from 'app/components/Icon';
import CheckIcon from 'assets/img/ORDORIA/Catalog/CheckIcon.svg';

interface Props {
  parent: 'category' | 'item';
  action: any;
  active: boolean;
  totalItems?: number;
  selectedItems?: number;
  styles?: CSSProperties;
}

export const Selector: React.FC<Props> = ({
  parent,
  action,
  active,
  totalItems,
  selectedItems,
  styles,
}) => {
  const selectionState = useMemo<'all' | 'some' | 'none'>(() => {
    if (!totalItems || !selectedItems) {
      return active ? 'all' : 'none';
    }

    if (totalItems === selectedItems) {
      return 'all';
    } else if (selectedItems > 0) {
      return 'some';
    } else {
      return 'none';
    }
  }, [totalItems, selectedItems, active]);

  const color = useMemo(() => {
    return parent === 'category'
      ? themes?.default?.accordionWhiteBg
      : selectionState !== 'none'
      ? themes?.ordoria?.primary
      : themes?.default?.silver;
  }, [parent, selectionState]);

  return (
    <Wrapper
      onClick={(e) => {
        e?.stopPropagation();
        action();
      }}
      backgroundColor={selectionState !== 'none' ? color : ''}
      typeOfSelector={parent}
      style={{ ...styles }}
    >
      <If condition={selectionState === 'all'}>
        <Icon
          icon={<img src={CheckIcon} alt="" height="14px" width="14px" />}
          color={themes?.default?.baseWhite}
          size={10}
        />
      </If>
      <If condition={selectionState === 'some'}>
        <CustomMinusIcon />
      </If>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ backgroundColor: string; typeOfSelector: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid
    ${(props) =>
      props.typeOfSelector === 'item' ? themes?.ordoria?.primary : 'white'};
  background: ${(props) => props.backgroundColor};
  cursor: pointer;
  user-select: none;
`;

const CustomMinusIcon = styled.div`
  height: 2px;
  width: calc(100% - 4px);
  border-radius: 1px;
  background: ${themes?.default?.baseWhite};
`;
