import { GridRow } from 'app/components/Grid/components/styles';
import { GridTableCell } from 'app/components/Grid/components/GridTableCell';
import { useContext, useMemo, useState } from 'react';
import EllipsisText from 'app/components/EllipsisText';
import { themes } from 'styles/theme/themes';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { useTranslation } from 'react-i18next';
import MapPopover from 'app/components/MapPopover';
import { handleCloseStopMap } from 'app/pages/AddRoundTrips/functions';
import { PopoverEl } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { PopoverText } from 'hooks/Orders/useFormatOrderData';
import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import { useSelector } from 'react-redux';
import { selectConfiguration } from 'common/store/organization/selectors';
import {
  CrosshairSimple,
  DotsThree,
  Note,
  ShoppingBagOpen,
} from '@phosphor-icons/react';
import { CurrencyCircleDollar } from '@phosphor-icons/react/dist/ssr';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import { LocationInterface } from 'common/types/Location';
import { StatusCustomer } from 'app/components/StatusCustomer';
import { Branch } from '../Type';
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfFirstThreeInEachWord,
  getBranchStatusesToShow,
} from '../../helper/helpers';
import { usePlaceContext } from '../../hooks/usePlaceContext';
import { useNavigate, useParams } from 'react-router-dom';
import { TypoMain } from '../../../styles';
import If from 'app/components/If';
import { CustomerStatus } from 'common/types/Customer';
import { CustomerFormcontext } from '../../../Form/context';
interface Props {
  branch: Branch;
}
const statusActions: Array<{ action: string; element: JSX.Element }> = [];
export const Row = ({ branch }: Props) => {
  const { t } = useTranslation();
  const configuration = useSelector(selectConfiguration);
  const { id } = useParams<Record<string, string>>();
  const navigate = useNavigate();
  const context = useContext(CustomerFormcontext);

  const branchStatusesList = getBranchStatusesToShow(
    configuration?.orderStatusLogistic || [],
  );

  const { customer } = context;

  const status = useMemo(() => {
    return [
      {
        label: t('customer.status.in-review'),
        value: CustomerStatus.IN_REVIEW,
        color: themes?.default?.tapa,
      },
      {
        label: t('user.status.active'),
        value: CustomerStatus.ACTIVE,
        color: themes?.default?.greenStatus,
      },
      {
        label: t('customer.status.on-watch'),
        value: CustomerStatus.ON_WATCH,
        color: themes?.default?.warningMain,
      },
      {
        label: t('customer.status.blocked'),
        value: CustomerStatus.BLOCKED,
        color: themes?.default?.brown,
      },
      {
        label: t('customer.status.archived'),
        value: CustomerStatus.ARCHIVED,
        color: themes?.default?.errorMain,
      },
    ];
  }, [t]);

  branchStatusesList.map((status: any) => {
    statusActions.push({
      action: status.status,
      element: (
        <ItemStatusComponent
          action={async () => {}}
          label={status.status}
          color={status.color}
        />
      ),
    });
  });

  const handlViewBranchOrders = () => {
    navigate(
      '/orders?customerId=' + id + '&customerName=' + customer.publicName,
    );
  };

  const { handleDelete, handleEdit, handleChangeStatus, canEdit } =
    usePlaceContext();

  const [selectedAddress, setSelectedAddress] = useState<LocationInterface>();

  const capitalizedSupportUnits = branch.requestedSupport
    ? branch.requestedSupport.map(capitalizeFirstLetter).join(', ')
    : '';

  const capitalizedDeliveryTypes = branch.deliveryTypes
    ? branch.deliveryTypes.map(capitalizeFirstLetter).join(', ')
    : '';

  const result = branch.deliveryDays
    ? branch.deliveryDays
        .map(capitalizeFirstLetterOfFirstThreeInEachWord)
        .join(', ')
    : '';

  const menuActions = useMemo(() => {
    const actions: any = [
      {
        action: 'edit',
        onClick: () => handleEdit(branch._id),
        element: (
          <PopoverText>
            <PopoverEl>
              <div
                style={{
                  marginLeft: themes.default.popoverLabelPadding,
                  paddingTop: '5px',
                }}
              >
                {canEdit ? t('edit.branch') : t('branch.actions.view')}
              </div>
            </PopoverEl>
          </PopoverText>
        ),
      },
    ];

    if (canEdit) {
      actions.push(
        {
          action: 'Change_Status',
          nested: true,
          actions: status
            .filter((item) => item.value !== branch.status)
            .map((status: any) => ({
              element: (
                <ItemStatusComponent
                  action={async () => {
                    if (id) {
                      handleChangeStatus(id, branch._id, status.value);
                    }
                  }}
                  label={status.label}
                  color={status.color}
                />
              ),
            })),
          element: (
            <PopoverText>
              <PopoverEl>
                <div
                  style={{
                    marginLeft: themes.default.popoverLabelPadding,
                    paddingTop: '5px',
                  }}
                >
                  {t('common.change_status')}
                </div>
              </PopoverEl>
            </PopoverText>
          ),
        },
        {
          seperator: true,
          action: 'seperator',
          element: <Divider />,
        },
        {
          action: 'delete',
          onClick: () => {
            if (id) {
              handleDelete(id, branch._id);
            }
          },
          element: (
            <PopoverText>
              <PopoverEl>
                <div
                  style={{
                    marginLeft: themes.default.popoverLabelPadding,
                    paddingTop: '5px',
                    color: themes?.default?.chipRedBg,
                  }}
                >
                  {t('orders.actions.delete')}
                </div>
              </PopoverEl>
            </PopoverText>
          ),
        },
      );
    }

    return actions;
  }, [
    branch._id,
    branch.status,
    canEdit,
    handleChangeStatus,
    handleDelete,
    handleEdit,
    id,
    status,
    t,
  ]);

  return (
    <GridRow hover tabIndex={-1} key={branch._id}>
      <GridTableCell columnId="code">
        <Typography
          fontWeight={400}
          fontSize="12px"
          sx={{
            ':hover': {
              color: themes?.default?.primary,
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          }}
          onClick={() => handleEdit(branch._id)}
        >
          {branch.code}
        </Typography>
      </GridTableCell>
      <GridTableCell columnId="branchName">
        <Grid container alignItems="center">
          <EllipsisText width={350} text={branch.shortName} />
          <If condition={branch.markAsMain}>
            <TypoMain
              fontWeight={500}
              fontSize="10px"
              sx={{
                ml: '4px',
              }}
            >
              Main
            </TypoMain>
          </If>
        </Grid>
      </GridTableCell>

      <GridTableCell
        columnId="address"
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          height: '47.57px',
        }}
      >
        <IconButton
          onClick={() => {
            setSelectedAddress(branch.location);
          }}
          sx={{
            p: '4px',
          }}
        >
          <CrosshairSimple
            style={{ color: themes?.default?.primary }}
            size={15}
          />
        </IconButton>
        <EllipsisText
          width={350}
          tooltip={branch?.location?.formattedAddress}
          text={branch.location?.name}
        />
        <MapPopover
          open={!!selectedAddress?.geometry?.coordinates?.length}
          handleClose={() => {
            handleCloseStopMap(setSelectedAddress);
          }}
          latitude={selectedAddress?.geometry?.coordinates?.[1]}
          longitude={selectedAddress?.geometry?.coordinates?.[0]}
          editMode={false}
        />
      </GridTableCell>
      <GridTableCell columnId="siretNumber">
        <EllipsisText width={350} text={branch.siretNumber} />
      </GridTableCell>
      <GridTableCell columnId="vatNumber">
        <EllipsisText width={350} text={branch.vatNumber} />
      </GridTableCell>
      <GridTableCell columnId="apeCode">
        <EllipsisText width={350} text={branch.apeCode} />
      </GridTableCell>
      <GridTableCell columnId="mainsSalesContact">
        <Typography fontWeight={400} fontSize="12px">
          {branch.contacts
            ?.filter((contact) => contact.jobType === 'sales' && contact.main)
            .map((contact) => contact.name)
            ?.join(', ') || ''}
        </Typography>
        <Typography
          fontWeight={400}
          fontSize="10px"
          sx={{
            color: themes?.default?.greyColorLabel,
          }}
        >
          {branch.contacts
            ?.filter((contact) => contact.jobType === 'sales' && contact.main)
            .map((contact) => contact.phone)
            ?.join(', ') || ''}
        </Typography>
      </GridTableCell>
      <GridTableCell columnId="mainLogisticsContact">
        <Typography fontWeight={400} fontSize="12px">
          {branch.contacts
            ?.filter(
              (contact) => contact.jobType === 'logistics' && contact.main,
            )
            .map((contact) => contact.name)
            ?.join(', ') || ''}
        </Typography>
        <Typography
          fontWeight={400}
          fontSize="10px"
          sx={{
            color: themes?.default?.greyColorLabel,
          }}
        >
          {branch.contacts
            ?.filter(
              (contact) => contact.jobType === 'logistics' && contact.main,
            )
            .map((contact) => contact.phone)
            ?.join(', ') || ''}
        </Typography>
      </GridTableCell>
      <GridTableCell columnId="deliveryType">
        <EllipsisText width={350} text={capitalizedDeliveryTypes} />
      </GridTableCell>
      <GridTableCell columnId="preferredSupportUnits">
        <EllipsisText width={350} text={capitalizedSupportUnits} />
      </GridTableCell>
      <GridTableCell columnId="vehicleCondition">
        <EllipsisText
          width={350}
          text={branch.vehicleCondition?.join(', ').toUpperCase()}
        />
      </GridTableCell>
      <GridTableCell columnId="deliveryDays">
        <EllipsisText width={350} text={result} />
      </GridTableCell>
      <GridTableCell columnId="status">
        <StatusCustomer status={branch.status} />
      </GridTableCell>
      <GridTableCell
        columnId="actions"
        className="actions-cell"
        style={{
          display: 'inline-flex',
          gap: 10,
          alignItems: 'center',
          height: '47.57px',
        }}
      >
        <Tooltip title="View Branch Orders">
          <IconButton
            size="medium"
            sx={{
              p: '2px',
            }}
          >
            <ShoppingBagOpen
              color={themes?.default?.grey2}
              onClick={handlViewBranchOrders}
            />
          </IconButton>
        </Tooltip>
        <Note
          color={themes?.default?.grey2}
          size={20}
          style={{
            cursor: 'not-allowed',
          }}
        />
        <CurrencyCircleDollar
          color={themes?.default?.grey2}
          size={20}
          style={{
            cursor: 'not-allowed',
          }}
        />
        <NestedMenuComponent
          leftPosition={true}
          id={branch._id}
          transformOriginHorizontal="left"
          anchorOriginHorizontal="right"
          iconAction={
            <Icon
              icon={<DotsThree />}
              color={themes?.default?.iconColor}
              size={20}
            />
          }
          actions={menuActions}
        />
      </GridTableCell>
    </GridRow>
  );
};
