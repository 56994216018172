import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Tooltip } from '@mui/material';
import { BoxArrowDown } from '@phosphor-icons/react';
import If from 'app/components/If';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

function ArchivedCell(params: CustomCellRendererProps) {
  const { t } = useTranslation();

  return (
    <If condition={params.value.archivedData}>
      <Tooltip title={t('customer.status.archived')}>
        <div>
          <BoxArrowDown
            style={{
              width: '16px',
              height: '16px',
              cursor: 'pointer',
              color: themes?.default?.red800,
            }}
          />
        </div>
      </Tooltip>
    </If>
  );
}

export default ArchivedCell;
