export interface User {
  id: string;
  createdAt: string;
  currentAgent: any;
  deleted: boolean;
  email: string;
  phone?: string;
  enabled: boolean;
  firstName: string;
  fullName: string;
  isActive: boolean;
  isAdmin: boolean;
  isLocked: boolean;
  isSuperAdmin: boolean;
  language: 'fr' | 'en';
  lastLoginAt: string;
  lastName: null;
  shortName: string;
  updatedAt: string;
  username: string;
  lastViewAt?: string;
  cache?: any;
  agents?: [{ _role: { localeName: { en: string } }; _organization: any }];
  fullNameGridFormat?: string;
  isArchived?: boolean;
  companyEmail?: string;
  missingRequiredFields?: boolean;
}

export enum UserType {
  internal = 'internal',
  external = 'external',
}

export enum UserStatus {
  inactive = 'inactive',
  invited = 'invited',
  active = 'active',
}

export enum Status {
  available = 'available',
  unavailable = 'unavailable',
  busy = 'busy',
  disabled = 'disabled',
}

export enum ReportsToType {
  noOne = 'noOne',
  users = 'users',
}
