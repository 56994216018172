import { capitalize, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';

export const useErrorFormatter = () => {
  const { t } = useTranslation();

  function formatError(error, fieldName = 'email') {
    if (!error) {
      return;
    }
    if (error.message) {
      return error.message;
    }

    const field = !error.ref?.label
      ? fieldName
      : capitalize(t(error.ref?.label));
    const requiredMessage = !isUndefined(error.ref?.label)
      ? t('rules.required.field', { field })
      : error.message;
    // TODO: add more cases and tranlations / improve this to be more flexible
    switch (error.type) {
      case 'required':
        return requiredMessage;
      case 'validate':
        return t('field_already_exists', { field });
      case 'pattern':
        return error.message;
      default:
        return requiredMessage;
    }
  }

  return { formatError };
};
