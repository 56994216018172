import React, { useMemo } from 'react';
import { Icon } from 'app/components/Icon';
import { useTranslation } from 'react-i18next';
import SmokingDish from 'assets/img/ORDORIA/Catalog/IconsProduct/SmokingDish.svg';
import TallDish from 'assets/img/ORDORIA/Catalog/IconsProduct/TallDish.svg';
import Ice from 'assets/img/ORDORIA/Catalog/IconsProduct/Ice.svg';
import MeltingDish from 'assets/img/ORDORIA/Catalog/IconsProduct/MeltingDish.svg';

interface Props {
  state: string;
}

// product_state.ready_to_cook  SmokingDish
// product_state.to_grow  TallDish
// product_state.ice  Ice
// product_state.to_defrost MeltingDish

export const StoringState: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation();

  const icon = useMemo(() => {
    if (state === 'ready_to_cook') {
      return SmokingDish;
    } else if (state === 'to_grow') {
      return TallDish;
    } else if (state === 'ice') {
      return Ice;
    } else if (state === 'to_defrost') {
      return MeltingDish;
    } else return '';
  }, [state]);

  return (
    <Icon
      icon={<img src={icon} alt="" />}
      tooltip={t(`product_state.${state}`)}
    />
  );
};
