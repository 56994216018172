import api from './api';
import { ValidationResponse } from '../types/User';

export const clientGroupsApi = api.injectEndpoints({
  endpoints: builder => ({
    getClientGroupsList: builder.query<any, string>({
      query: urlParams => ({
        url: `/customer-types${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['ClientGroups'],
    }),

    getClientGroupsPricingList: builder.query<any, string>({
      query: () => ({
        url: `pricing-groups/options`,
        method: 'GET',
      }),
      providesTags: ['ClientGroups'],
    }),

    getClientGroupsDiscountList: builder.query<any, string>({
      query: () => ({
        url: `/discount-groups/options`,
        method: 'GET',
      }),
      providesTags: ['ClientGroups'],
    }),
    createClientGroup: builder.mutation<any, any>({
      query: body => ({
        url: `/customer-types`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ClientGroups'],
    }),
    getClientGroupsOptions: builder.query<any, string>({
      query: () => ({
        url: `/customer-types/options`,
        method: 'GET',
      }),
      providesTags: ['ClientGroups'],
    }),
    archiveClientGroup: builder.mutation<any, { body }>({
      query: ({ body }) => ({
        url: `/customer-types/archive`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ClientGroups'],
    }),
    unArchiveClientGroup: builder.mutation<any, { body }>({
      query: ({ body }) => ({
        url: `/customer-types/unarchive`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ClientGroups'],
    }),
    setAsDefaultClientGroup: builder.mutation<any, { id }>({
      query: ({ id }) => ({
        url: `/customer-types/${id}/set-default`,
        method: 'PATCH',
      }),
      invalidatesTags: ['ClientGroups'],
    }),
    renameClientGroup: builder.mutation<any, { id }>({
      query: ({ id }) => ({
        url: `/customer-types/${id}/rename`,
        method: 'PATCH',
      }),
      invalidatesTags: ['ClientGroups'],
    }),
    updateClientGroup: builder.mutation<any, { body; id }>({
      query: ({ body, id }) => ({
        url: `/customer-types/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['ClientGroups'],
    }),
    importClientGroupValidate: builder.mutation<ValidationResponse, FormData>({
      query: body => ({
        url: '/customer-types/validate',
        method: 'PUT',
        body,
      }),
    }),
    clientGroupImport: builder.mutation<unknown, FormData>({
      query: body => ({
        url: '/customer-types/import',
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useGetClientGroupsListQuery,
  useGetClientGroupsPricingListQuery,
  useGetClientGroupsDiscountListQuery,
  useCreateClientGroupMutation,
  useGetClientGroupsOptionsQuery,
  useArchiveClientGroupMutation,
  useUnArchiveClientGroupMutation,
  useSetAsDefaultClientGroupMutation,
  useRenameClientGroupMutation,
  useImportClientGroupValidateMutation,
  useUpdateClientGroupMutation,
  useClientGroupImportMutation,
} = clientGroupsApi;

export default clientGroupsApi;
