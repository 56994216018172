import { CLAIMS_TYPES } from '../../config';

interface ClaimIconProps {
  claimType: keyof typeof CLAIMS_TYPES;
  width?: string;
  height?: string;
}

export const ClaimIcon = ({
  claimType,
  width = '16px',
  height = '16px',
}: ClaimIconProps): JSX.Element | null => {
  const claim =
    CLAIMS_TYPES[claimType?.toUpperCase() as keyof typeof CLAIMS_TYPES] ||
    CLAIMS_TYPES.OTHER;

  const IconComponent = claim.icon;

  return (
    <IconComponent
      style={{ width: width, height: height, color: claim.color }}
    />
  );
};
