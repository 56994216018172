import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import If from 'app/components/If';
import { OrderDetails } from 'app/components/OrderDetails';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { themes } from 'styles/theme/themes';

function OrderCell(params: CustomCellRendererProps) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <If condition={!isEmpty(params.value.orderCodeData)} otherwise="_">
        <Typography
          fontSize="0.75rem"
          onClick={() => setOpen(true)}
          sx={{
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
              color: themes?.ordoria?.primary,
            },
          }}
        >
          #{params.value.orderCodeData}
        </Typography>
      </If>
      <If condition={open}>
        <OrderDetails
          orderId={params.value.orderIdData}
          open={open}
          onClose={() => setOpen(false)}
        />
      </If>
    </>
  );
}

export default OrderCell;
