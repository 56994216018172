import { Stack } from '@mui/material';
import { Card } from 'app/components/KpiCard';
import { useTranslation } from 'react-i18next';
import { IGridContext, IStatsConfig } from '../types';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { CardSkeleton } from 'app/components/KpiCard/skeleton';
import { useTheme } from 'common/hooks/useTheme';

export function ManagedStatsCards<T>() {
  const { t } = useTranslation();
  const theme = useTheme();
  const gridContext = useManagedContext<IGridContext<T>>('grid');
  const statsConfig = useManagedContext<IStatsConfig>('statsConfig');
  const { facet, title, colors, grandTotal, calculateStatusMetrics, statuses } =
    statsConfig;

  const isLoading = !!gridContext.loadingStats;

  if (isLoading) {
    return (
      <Stack direction="row" gap="10px" position="relative" zIndex="2">
        <CardSkeleton key={Math.random()} withCircle={false} />

        {Object.entries(colors).map(() => (
          <CardSkeleton key={Math.random()} />
        ))}
      </Stack>
    );
  }

  return (
    <>
      {gridContext.data.showStatsCards && (
        <Stack direction="row" gap="10px" position="relative" zIndex="2">
          <Card
            displayType="numbers"
            cardTitle={t('common.all')}
            grandTotal={grandTotal || 0}
            onClick={() => {
              gridContext.updateDataWithFunction((prev) => {
                prev.queryParams.status = '';
              });
            }}
            backgroundColor={theme.statsCardBg}
            thumbColor={theme.primaryActiveColor}
            railColor={theme.statsCardBorder}
            isActive={!gridContext.queryParams.status}
            tooltip={t('common.all')}
          />
          {Object.entries(colors).map(([key, values]) => {
            return (
              <Card
                key={key}
                displayType="chart"
                cardTitle={t(`${title.toLowerCase()}.${key.toLowerCase()}`)}
                grandTotal={
                  facet
                    ? calculateStatusMetrics(statuses[key], facet)?.total
                    : undefined
                }
                percentage={
                  facet
                    ? calculateStatusMetrics(statuses[key], facet)?.percentage
                    : undefined
                }
                thumbColor={values.thumbColor}
                backgroundColor={values.backgroundColor}
                railColor={values.railColor}
                onClick={() => {
                  gridContext.updateDataWithFunction((prev) => {
                    prev.queryParams.status = statuses[key].toLowerCase();
                    prev.queryParams.page = 1;
                  });
                }}
                isActive={
                  gridContext.queryParams.status.toLowerCase() ===
                  statuses[key].toLowerCase()
                }
                tooltip={values.tooltip ? t(values.tooltip) : undefined}
              />
            );
          })}
        </Stack>
      )}
    </>
  );
}

export default ManagedStatsCards;
