import { CustomCellRendererProps } from '@ag-grid-community/react';
import dayjs from 'dayjs';

function CreatedAtCell(params: CustomCellRendererProps) {
  return (
    <div>
      <span>{dayjs(params.value.createdAtData).format('YYYY/MM/DD')}</span>
    </div>
  );
}

export default CreatedAtCell;
