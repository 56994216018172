import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

function DueDateCell(params: CustomCellRendererProps) {
  let isOverdue = false;

  const dueDate = params?.value?.dueDateData
    ? dayjs(params.value.dueDateData)
    : null;

  if (params?.value?.accountingPaymentStatus !== 'paid' && dueDate) {
    isOverdue = dueDate.isBefore(dayjs(), 'day');
  }

  return (
    <div>
      {dueDate ? (
        <Typography fontSize="0.9rem" color={isOverdue ? 'error' : 'default'}>
          {dueDate.format('L')}
        </Typography>
      ) : (
        <Typography fontSize="0.9rem" color="default">
          N/A
        </Typography>
      )}
    </div>
  );
}

export default DueDateCell;
