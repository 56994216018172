import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';

interface Props {
  content: string | null;
}

export const CollectorNotification = ({ content }: Props) => {
  if (!content) {
    return null;
  }

  const cleanedContent = content.replace(/<\/?strong>/g, '');
  const regex = /^(.*?)\s+(common\.\S+)\s+(of_order)\s+(#BC\S+)$/;
  const parts = cleanedContent.match(regex);

  if (!parts) {
    return (
      <Typography fontWeight={700} fontSize="0.75rem">
        Invalid content format
      </Typography>
    );
  }

  const [_, name, status, orderType, orderId] = parts;

  return (
    <Stack
      direction="row"
      gap="5px"
      fontSize="0.75rem"
      sx={{ cursor: 'pointer' }}
    >
      <Typography fontWeight={700} fontSize="0.75rem">
        {name}
      </Typography>
      <Typography fontWeight={400} fontSize="0.75rem">
        {t(status) as string}
      </Typography>
      <Typography fontWeight={400} fontSize="0.75rem">
        {t(orderType) as string}
      </Typography>
      <Typography fontWeight={700} fontSize="0.75rem">
        {orderId}
      </Typography>
    </Stack>
  );
};
