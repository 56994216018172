import EllipsisText from 'app/components/EllipsisText';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const CategoryCell = (params) => {
  return (
    <CategoryName>
      <EllipsisText
        width={247 - 15 - 15} // padding: 0 15px
        text={params?.value?.cellData?.name || '__'}
      />
    </CategoryName>
  );
};

const CategoryName = styled.p`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${themes?.default?.textColorDashboard};
`;
