import { MultiplePaiementsData } from './data';
import { IMultiplePaymentsData } from './types';

export function getProcessedMultiplePaiements(
  multiplePaymentsData: MultiplePaiementsData[],
  paymentEnable: boolean = true,
) {
  return multiplePaymentsData.map((paymentsObj, index) => {
    return {
      id: paymentsObj?._id || index,
      type: {
        typeData: paymentsObj?.metadata?.type || '',
      },
      invoiceCode: {
        invoiceCodeData: paymentsObj?.metadata?.invoiceCode || '',
        initialUrlData: paymentsObj?.initialUrl || '',
      },
      name: {
        nameData: paymentsObj?.name || '',
        initialUrlData: paymentsObj?.initialUrl || '',
      },
      createdAt: {
        createdAtData: paymentsObj?.createdAt || '',
      },
      accountingAmount: {
        amountData:
          paymentsObj?.metadata?.structured?.totalAmountTax ||
          paymentsObj?.accountingAmount ||
          0,
      },
      accountingDueDate: {
        dueDateData: paymentsObj?.accountingDueDate || '',
        accountingPaymentStatus: paymentsObj?.accountingPaymentStatus || '',
      },
      accountingPayedAmount: {
        payedAmountData: paymentsObj?.accountingPayedAmount || 0,
      },
      status: {
        paymentStatusData: paymentsObj || {},
      },
      action: {
        paymentEnable,
        document: paymentsObj || {},
      },
    } as IMultiplePaymentsData;
  });
}
