import { TableRow } from '@mui/material';
import styled from 'styled-components';

export const GridRow = styled(TableRow)<{ theme: any; loading?: boolean }>`
  position: relative;
  height: 40px;
  & td,
  & th {
    height: 40px;
    padding: 6px 16px;
    z-index: 1;
  }
  & td {
    background: ${(props) => props.theme.whiteStickyBackGround};
    opacity: ${(props) => (props.loading ? 0.5 : 1)};
  }
  & th {
    background: ${(props) => props.theme.greyLight};
  }
  :hover {
    & td {
      background: ${(props) => props.theme.grey};
    }
  }

  .checkbox-cell,
  .actions-cell {
    position: sticky;
    z-index: 3;
  }
  .checkbox-cell {
    left: 0;
    width: ${(props) => props.theme.gridCheckboxWidth}px !important;
  }
  .actions-cell {
    right: 0;
    text-align: end;
  }
`;
