import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { AssigneePopover } from '../components/AssigneePopover';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import {
  useAddAssigneeMutation,
  useRemoveAssigneeMutation,
} from 'common/services/conversationApi';
import { AssigneesAvatar } from '../components/AssigneesAvatar';

function AssigneeCell(params: CustomCellRendererProps) {
  const [openPopup, setOpenPopup] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [limit, setLimit] = useState(20);
  const [selectedEmployees, setSelectedEmployees] = useState(() => {
    return (params.value?.assigneeData || []);
  });

  const [search, setSearch] = useState('');

  const fetchQueryParams = useQueryParams({
    limit: limit,
    text: search,
  });

  const [addAssignee] = useAddAssigneeMutation();
  const [removeAssignee] = useRemoveAssigneeMutation();
  const { data: employeesData } = useGetUsersBasicQuery(fetchQueryParams);

  useEffect(() => {
    if (params?.data?.id && openPopup) {
      addAssignee({
        conversationId: params.data.id,
        body: {
          assigneeAgent: selectedEmployees,
        },
      });
    }
  }, [selectedEmployees]);

  function handleClearClaim(currentAgentId) {
    setSelectedEmployees((prev) => prev.filter((id) => id !== currentAgentId));

    removeAssignee({
      conversationId: params.data.id,
      body: {
        assignedAgentId: currentAgentId,
      },
    });
  }

  return (
    <>
      <Wrapper
        ref={wrapperRef}
        openPopup={openPopup}
        onClick={() => setOpenPopup(true)}
      >
        <AssigneesAvatar
          assignees={params.value.assigneeData}
          handleClearClaim={handleClearClaim}
        />
      </Wrapper>
      <AssigneePopover
        popoverId={params.value.id}
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        wrapperRef={wrapperRef.current}
        data={employeesData?.docs}
        setLimit={setLimit}
        setSelectedEmployees={setSelectedEmployees}
        search={search}
        setSearch={setSearch}
        selectedEmployees={selectedEmployees}
      />
    </>
  );
}

const Wrapper = styled.div<{ openPopup: boolean }>`
  width: 100%;
  height: 100%;
  align-content: center;
  cursor: pointer;
  background-color: ${({ openPopup }) =>
    openPopup ? themes?.default?.lightGrey : 'transparent'};
`;

export default AssigneeCell;
