import { createContext, useState } from 'react';

interface ListPaymentContextType {
  selectedPaymentsIds: any;
  setSelectedPaymentsIds: (_selectedPaymentsId: any) => void;
  selectedPeriod: any;
  setSelectedPeriod: (_selectedPeriod: any) => void;
  rowDataList: any;
  setRowDataList: (_rowDataList: any) => void;
}

export const ListPaymentContext = createContext<ListPaymentContextType>({
  selectedPaymentsIds: [],
  setSelectedPaymentsIds: () => {},
  selectedPeriod: [],
  setSelectedPeriod: () => {},
  rowDataList: [],
  setRowDataList: () => {},
});

export const ListPaymentProvider = ({ children }) => {
  const [selectedPaymentsIds, setSelectedPaymentsIds] = useState<string[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');
  const [rowDataList, setRowDataList] = useState<any>([]);

  return (
    <ListPaymentContext.Provider
      value={{
        selectedPaymentsIds,
        setSelectedPaymentsIds,
        selectedPeriod,
        setSelectedPeriod,
        rowDataList,
        setRowDataList,
      }}
    >
      {children}
    </ListPaymentContext.Provider>
  );
};
