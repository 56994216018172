import { useSelector } from 'react-redux';
import { useGetCustomerPortalInvoicesByListQuery } from 'common/services/customerPortalApi';
import Each from 'common/UtilityComponents/Each';
import styled from 'styled-components';
import { ListCollapsablePayment } from './components/ListCollapsablePayment';
import { Pagination } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useQueryParams } from 'hooks/useQueryParams';
import { ListPaymentContext } from './ListPaymentProvider';
import { selectAuthCustomer } from 'common/store/auth/selectors';

export function ListComponent() {
  const [page, setPage] = useState<number>(1);
  const authCustomer = useSelector(selectAuthCustomer);
  const [expandedIndex, setExpandedIndex] = useState<number | null>(0);

  const commonQueryParams = {
    page: page,
  };

  const queryParams = useQueryParams(commonQueryParams);

  const {
    data: invoices,
    isLoading,
    isFetching,
    refetch,
  } = useGetCustomerPortalInvoicesByListQuery(queryParams);

  const { setRowDataList } = useContext(ListPaymentContext);

  useEffect(() => {
    if (authCustomer?._id) {
      refetch();
    }
  }, [authCustomer?._id]);

  useEffect(() => {
    if (invoices?.docs) {
      const allInvoices = Object.values(invoices.docs).flat();
      setRowDataList(allInvoices);
    }
  }, [invoices]);

  return (
    <>
      <Wrapper>
        <Each
          of={Object.entries(invoices?.docs || {})}
          render={([date, payments], index) => (
            <ListCollapsablePayment
              key={index}
              period={payments}
              date={date}
              expanded={expandedIndex === index}
              onToggle={() =>
                setExpandedIndex(expandedIndex === index ? null : index)
              }
              isLoading={isLoading || isFetching}
            />
          )}
        />
      </Wrapper>
      <Pagination
        count={invoices?.totalPages}
        onChange={(e, val) => setPage(val)}
        sx={{
          mb: '20px',
          ml: 'auto',
        }}
      />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 16px 0px 16px 20px;
  padding: 0px 20px 0px 0px;
  gap: 20px;
  overflow-y: auto;
  height: fit-content;
`;
