import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Box, IconButton, Popover, SxProps, Tab, Portal } from '@mui/material';
import styled from 'styled-components';
import { VerticalDivider } from '../EnhancedTableToolbar/components/VerticalDivider';
import If from '../If';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { themes } from 'styles/theme/themes';
import { Icon } from '../Icon';
import { DotsThree } from '@phosphor-icons/react';
import { StateSetter } from 'types';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

export interface TabType {
  value?: string;
  label?: JSX.Element;
  isDivider?: boolean;
}

interface TabsMenuProps {
  selectedTab: string;
  handleOnSwitchTab: (e: any, tab: string) => void;
  defaultTabs?: TabType[];
  tabs: TabType[];
  setTabs?: StateSetter<TabType[]>;
  withDivider?: boolean;
  maxTabs?: number;
}

export const TabsMenu = ({
  selectedTab,
  handleOnSwitchTab,
  defaultTabs = [],
  tabs,
  setTabs = () => {},
  withDivider = true,
  maxTabs,
}: TabsMenuProps) => {
  const visibleTabsNumber = Math.min(maxTabs || tabs?.length, tabs?.length);

  const getDefaultVisibleTabs = useCallback(() => {
    const defaultTab = tabs.find((t) => t.value === selectedTab);
    const visible = [...tabs.slice(0, visibleTabsNumber)];

    if (visible?.find((t) => t.value === selectedTab)) {
      return visible;
    } else if (defaultTab) {
      return [...tabs.slice(0, visibleTabsNumber - 1), defaultTab];
    }

    return visible;
  }, [tabs, selectedTab, visibleTabsNumber]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dragOverTabValue, setDragOverTabValue] = useState('');
  const [draggedTabValue, setDraggedTabValue] = useState('');
  const [draggedTabLabel, setDraggedTabLabel] = useState('');
  const [draggedElementWidth, setDraggedElementWidth] = useState(0);
  const [visibleTabs, setVisibleTabs] = useState<TabType[]>(
    getDefaultVisibleTabs(),
  );
  const [hiddenTabs, setHiddenTabs] = useState<TabType[]>([
    ...tabs
      .filter((tab) => tab.value !== selectedTab)
      .slice(visibleTabsNumber - 1),
  ]);
  const dragImageRef: any = useRef(null);
  const theme = useSelector(selectTheme);

  const tabItems = useMemo(() => {
    if (!withDivider) {
      return visibleTabs;
    }

    const items: TabType[] = [];
    visibleTabs.forEach((t, index) => {
      items.push(t);
      if (index !== visibleTabs.length - 1) {
        items.push({
          isDivider: true,
        });
      }
    });
    return items;
  }, [visibleTabs, withDivider]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setVisibleTabs(getDefaultVisibleTabs());
  }, [tabs, getDefaultVisibleTabs]);

  const clickHiddenTab = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tab: TabType,
  ) => {
    if (!tab?.value) {
      return;
    }
    const updatedVisibleTabs = [...visibleTabs];
    if (visibleTabs?.length === visibleTabsNumber) {
      const movedTab = updatedVisibleTabs.pop();
      if (!!movedTab) {
        setHiddenTabs((prevHiddenTabs) => [
          ...prevHiddenTabs.filter((t) => t.value !== tab.value),
          movedTab,
        ]);
        setVisibleTabs([...updatedVisibleTabs, tab]);
      }
    } else {
      const updatedHiddenTabs = hiddenTabs.filter((t) => t.value !== tab.value);
      setHiddenTabs(updatedHiddenTabs);

      const updatedVisibleTabs = [...visibleTabs, tab];
      setVisibleTabs(updatedVisibleTabs);
    }

    setAnchorEl(null);
    handleOnSwitchTab(e, tab.value);
  };

  const handleDragStart = async (e, value, label) => {
    await new Promise((resolve) => {
      setDraggedElementWidth(e?.target?.offsetWidth || 0);
      setDraggedTabValue(value);
      setDraggedTabLabel(label);
      resolve({});
    });

    const dragImage = dragImageRef.current;
    const rect = e.target.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;

    e.dataTransfer.setDragImage(dragImage, offsetX, offsetY);
  };

  const handleDragEnter = (value) => {
    setDragOverTabValue(value);
  };

  const handleDragLeave = (e) => {
    const relatedTargetNode = e.relatedTarget as Node;
    if (!relatedTargetNode || !e.currentTarget.contains(relatedTargetNode)) {
      setDragOverTabValue('');
    }
  };

  const handleCleanup = useCallback(() => {
    setDraggedElementWidth(0);
    setDragOverTabValue('');
    setDraggedTabValue('');
    setDraggedTabLabel('');
  }, [
    setDraggedElementWidth,
    setDragOverTabValue,
    setDraggedTabValue,
    setDraggedTabLabel,
  ]);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let tabsCopy = tabs?.map((tab) => {
      return { value: tab?.value };
    });
    let droppedTab: any = null;

    // remove dropped tab
    tabsCopy = tabsCopy.filter((tab) => {
      if (tab?.value === draggedTabValue) {
        droppedTab = tab;
        return false;
      } else {
        return true;
      }
    });

    if (e?.target?.innerText === 'placeholder') {
      tabsCopy.splice(tabsCopy.length, 0, droppedTab);
      setTabs(tabsCopy);
      handleCleanup();
      return;
    }

    // get destination index;
    let destinationIndex: number = -1;
    tabsCopy.forEach((tab, index) => {
      if (
        tab?.value === dragOverTabValue ||
        e?.target?.id.indexOf(tab?.value) > -1
      ) {
        destinationIndex = index;
      }
    });

    if (destinationIndex === -1) return;

    // insert the dragged tab;
    tabsCopy.splice(destinationIndex, 0, droppedTab);

    tabsCopy = tabsCopy?.map((tabCopy) => {
      return { value: tabCopy?.value };
    });

    // save new order;
    setTabs(tabsCopy);
    handleCleanup();
  };

  // handle drop on window
  useEffect(() => {
    window.addEventListener('dragend', handleCleanup);

    return () => {
      window.removeEventListener('dragend', handleCleanup);
    };
  }, [handleCleanup]);

  return (
    <If condition={!!tabs?.length}>
      <TabContext value={selectedTab}>
        <TabList
          onChange={handleOnSwitchTab}
          sx={{
            height: '100%',
            minHeight: '10px',
            '& .MuiTabs-flexContainer': {
              alignItems: 'center',
              height: '100%',
            },
          }}
        >
          {tabItems.map((tab, index) => {
            let tabSx: SxProps = {
              px: 1,
              minHeight: '41px',
              transition: 'padding 0.2s ease-out',
            };
            if (tab.value === selectedTab) {
              tabSx = {
                ...tabSx,
                '& svg': {
                  fill: themes.default.primaryActiveColor,
                },
              };
            }
            if (withDivider) {
              if (index === 0) {
                tabSx = { ...tabSx, marginRight: '10px' };
              } else if (index === tabItems.length - 1) {
                tabSx = { ...tabSx, marginLeft: '10px' };
              } else {
                tabSx = { ...tabSx, mx: '10px' };
              }
            }

            if (tab.isDivider) {
              return (
                <VerticalDivider key={`divider-${index}`} sx={{ height: 20 }} />
              );
            } else {
              const isDragging = draggedTabValue === tab.value;
              return (
                <StyledTab
                  label={
                    <TabLabel
                      className={
                        tab.value === selectedTab
                          ? 'tab-label-active'
                          : 'tab-label'
                      }
                    >
                      {defaultTabs?.filter(
                        (defaultTab) => defaultTab.value === tab.value,
                      )[0]?.label || tab.label}
                    </TabLabel>
                  }
                  value={tab.value}
                  sx={{
                    ...tabSx,
                    paddingLeft:
                      draggedTabValue !== tab.value &&
                      dragOverTabValue === tab.value
                        ? `${draggedElementWidth}px`
                        : '8px',
                    minWidth: isDragging ? '0px' : null,
                    maxWidth: isDragging ? '0px' : null,
                    padding: isDragging ? '0px' : null,
                    margin: isDragging ? '0px' : null,
                    transition: 'all 0.2s',
                  }}
                  key={index}
                  draggable={true}
                  onDragStart={(e) =>
                    handleDragStart(
                      e,
                      tab?.value || '',
                      defaultTabs?.filter(
                        (defaultTab) => defaultTab.value === tab.value,
                      )[0]?.label || tab.label,
                    )
                  }
                  onDrop={handleDrop}
                  onDragEnter={() => handleDragEnter(tab?.value || '')}
                  onDragLeave={handleDragLeave}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                />
              );
            }
          })}
          <Portal>
            <DragImageElement
              width={draggedElementWidth}
              fillColor={theme.primaryLight}
              strokeColor={theme.primary}
              ref={dragImageRef}
            >
              <TabLabel>{draggedTabLabel}</TabLabel>
            </DragImageElement>
          </Portal>
          <div
            onDrop={handleDrop}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            style={{
              width: `${draggedElementWidth || 0}px`,
              height: '41px',
              opacity: 0,
              cursor: 'unset',
            }}
          >
            placeholder
          </div>
        </TabList>

        {hiddenTabs.length > 0 && (
          <Box paddingLeft="10px">
            <IconButton onClick={handlePopoverOpen}>
              <Icon icon={<DotsThree />} />
            </IconButton>
            <Popover
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {hiddenTabs.map((tab, index) => (
                <div key={index}>
                  <StyledTab
                    label={
                      <TabLabel
                        className={
                          tab.value === selectedTab
                            ? 'tab-label-active'
                            : 'tab-label'
                        }
                      >
                        {defaultTabs?.filter(
                          (defaultTab) => defaultTab.value === tab.value,
                        )[0]?.label || tab.label}
                      </TabLabel>
                    }
                    value={tab.value}
                    onClick={(e) => clickHiddenTab(e, tab)}
                  />
                </div>
              ))}
            </Popover>
          </Box>
        )}
      </TabContext>
    </If>
  );
};

const TabLabel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-transform: none;
  font-size: 0.85rem;
  line-height: 1rem;
  padding: 5px 8px;
  border-radius: 4px;
  transition: all 0.3s;
`;

const StyledTab = styled(Tab)`
  padding: 8px 0px !important;
  transition: background-color 0.2s ease;
  flex: 1;
  min-width: max-content !important;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    .tab-label {
      background-color: ${(props) => props.theme.grey};
    }
    .tab-label-active {
      background-color: ${(props) => props.theme.lightBackground};
    }
  }
`;

const DragImageElement = styled.div<{
  width: number;
  fillColor: string;
  strokeColor: string;
}>`
  width: ${(props) => props.width}px;
  opacity: ${(props) => (props.width ? '1' : '0')};
  height: 39px;
  padding: 12px 8px;
  background: ${(props) => props.fillColor};
  border-radius: 8px;
  border: 1px solid ${(props) => props.strokeColor};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1000px;
  left: -1000px;
`;
