import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Chip } from '@mui/material';
import { CLAIMS_STATS_STATUSES, CLAIMS_STATUS_CHIPS_COLORS } from '../config';
import { useTranslation } from 'react-i18next';

function StatusCell(params: CustomCellRendererProps) {
  const rawStatus = params?.value?.statusData || 'PENDING';
  const status = rawStatus.replace(/-/g, '').toUpperCase();

  const chipStyle =
    CLAIMS_STATUS_CHIPS_COLORS[status as CLAIMS_STATS_STATUSES] ||
    CLAIMS_STATUS_CHIPS_COLORS.PENDING;

  const { t } = useTranslation();

  return (
    <Chip
      label={t(chipStyle.label) || '__'}
      size="small"
      sx={{
        backgroundColor: chipStyle.backgroundColor,
        color: chipStyle.color,
      }}
    />
  );
}
export default StatusCell;
