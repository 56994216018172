import { ManagedTooltip } from '../TableComponent/helpers/Components/ManagedTooltip';
import { useTranslation } from 'react-i18next';
import { IconButton, SxProps } from '@mui/material';
import { Icon } from '../Icon';
import { IconProps } from '@phosphor-icons/react';

interface ActionIconButtonProps {
  onClick: (e?: any) => void;
  title: string;
  icon: JSX.Element;
  iconProps?: IconProps;
  disabled?: boolean;
  buttonSx?: SxProps;
}
export const ActionIconButton = ({
  onClick,
  title,
  icon,
  disabled,
  iconProps,
  buttonSx,
}: ActionIconButtonProps) => {
  const { t } = useTranslation();

  return (
    <ManagedTooltip title={t(title)}>
      <IconButton
        disabled={disabled}
        onClick={onClick}
        sx={{ height: '32px', ...buttonSx }}
        disableRipple
      >
        <Icon icon={icon} {...iconProps} />
      </IconButton>
    </ManagedTooltip>
  );
};
