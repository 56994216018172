import { IconButton } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import FavoriteDefault from 'assets/img/ORDORIA/Catalog/FavoriteDefault.svg';
import FavoriteFilled from 'assets/img/ORDORIA/Catalog/FavoriteFilled.svg';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { useToggleFavoriteItemsMutation } from 'common/services/customerApi';
import { useToaster } from 'hooks/useToaster';
import { CatalogContext } from '../../..';

export const FavBtnCell = (params) => {
  const { setFavoriteItems, favoriteItems } = useContext(CatalogContext);

  const { t } = useTranslation();
  const [isFavorite, setIsFavorite] = useState(
    params?.value?.cellData?.favorite,
  );
  const toast = useToaster();

  const [toggleFavoriteItems] = useToggleFavoriteItemsMutation();

  const toggleFavorite = async () => {
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);
    try {
      const response = await toggleFavoriteItems({
        products: [params?.value?.cellData?.id],
      }).unwrap();
      if (response?.error) {
        throw new Error(response.error);
      }
      setFavoriteItems(response?.result || []);
      toast(3000, 'success', t('toggle_fav.success'));
    } catch (error: any) {
      setIsFavorite(!newFavoriteStatus);
      toast(3000, 'error', error?.data?.message);
    }
  };

  useEffect(() => {
    if (!favoriteItems) return;
    const favorite = favoriteItems.includes(params?.value?.cellData?.id);
    if (favorite !== isFavorite) {
      setIsFavorite(favorite);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteItems]);

  return (
    <Wrapper>
      <IconButton onClick={toggleFavorite} disabled={false}>
        <Icon
          icon={
            <img
              src={isFavorite ? FavoriteFilled : FavoriteDefault}
              alt="fav"
              width="20px"
              height="20px"
            />
          }
          tooltip={t('favorite')}
          size={20}
        />
      </IconButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;
