import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Typography } from '@mui/material';
import If from 'app/components/If';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { ClaimsContext } from '../ClaimsProvider';
import { themes } from 'styles/theme/themes';

function ClaimIdCell(params: CustomCellRendererProps) {

  const {
    setOpenDetail,
    setSelectedClaimId
  } = useContext(ClaimsContext);
  
  return (
    <If condition={!isEmpty(params.value.claimIdData)} otherwise="_">
      <Typography fontSize="0.75rem" 
        onClick={() => {
          setOpenDetail(true);
          setSelectedClaimId(params.value.id);
        }}
        sx={{
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
          color: themes?.ordoria?.primary,
        },
      }}>#{params.value.claimIdData}</Typography>
    </If>
  );
}

export default ClaimIdCell;
