import { Grid } from '@mui/material';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerFormcontext } from '../../context';

export const BusinessInformation = ({ control }) => {
  const { t } = useTranslation();
  const { canEdit, isCustomer } = useContext(CustomerFormcontext);

  const disabled = isCustomer || !canEdit;

  return (
    <FormSection>
      <SectionTitle>{t('business_information')}</SectionTitle>
      <Grid container spacing="12px">
        <Grid item sm={4} xs={12}>
          <FormTextField
            name="legalName"
            label="brand_name"
            control={control}
            disabled={disabled}
            rules={{
              required: {
                value: true,
                message: `${t('brand_name')} ${t('is_required')}`,
              },
            }}
            required
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormTextField
            name="vatNumber"
            label="intracommunity_vat"
            control={control}
            disabled={disabled}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FormTextField
            name="activityCode"
            label="customer.activityCode"
            control={control}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
