import Toolbar from '@mui/material/Toolbar';
import { Alert } from '@mui/material';
import SearchField from 'app/components/Search/SearchField';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import If from 'app/components/If';
import { ComponentsStack } from 'app/components/EnhancedTableToolbar/components/ComponentsStack';
import { AddIconButton } from 'app/components/AddIconButton';
import ExportButton from 'app/components/FilterComponents/components/ExportButton';
import { useModal } from 'app/components/Dialog/hooks';
import { useDisabledExport } from 'app/components/EnhancedTableToolbar/hooks/useDisabledExport';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { Icon } from 'app/components/Icon';
import { TrashSimple, UploadSimple } from '@phosphor-icons/react';
import { useDebounce } from 'common/hooks/useDebounce';
import React, { useCallback, useEffect, useState } from 'react';
import { IGridContext, IToolbarConfig } from '../types';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import ManagedColumnOrderer from './ManagedColumnOrderer';
import { Menu } from '../../CustomMenu';
import { ManagedGridFilters } from './ManagedGridFilters';
import ManagedViewToggler from './ManagedViewToggler';

export const ManagedGridActions = ({
  onExportClick,
  onAddClick,
  onBulkDelete,
  ActionsComponent,
  FilterComponent,
  BulkActionsComponent,
  MenuOptions,
  hasSearchField = true,
}: IToolbarConfig) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const gridContext = useManagedContext<IGridContext>('grid');
  const {
    selectedRows,
    totalRows,
    queryParams,
    rowData,
    updateData,
    updateDataWithFunction,
    canEdit,
    canExport,
    hasMultipleViews,
    disableToolBar: disabled,
  } = gridContext;
  const [menuAnchor, setMenuAcnhor] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchor);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAcnhor(event.currentTarget);
    updateData('menuIsOpen', true);
  };

  useEffect(() => {
    updateDataWithFunction((prev) => {
      prev.api.openToolbarMenu = openMenu;
      prev.menuOptions = MenuOptions;
    });
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAcnhor(null);
    updateData('menuIsOpen', false);
  }, []);

  const { disabledExport } = useDisabledExport({
    total: totalRows,
    numSelected: selectedRows?.length,
  });

  const debouncedDisabled = useDebounce(disabled, 300);

  const onExportModal = () => {
    if (!canExport) return;
    openModal({
      title: t(selectedRows?.length ? 'export_selected' : 'export'),
      content: (
        <If
          condition={!disabledExport}
          otherwise={
            <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
              {t('export_data_exceeds_max', { max: '25,000' })}
            </Alert>
          }
        >
          <Alert variant="standard" severity="info" sx={{ width: '100%' }}>
            {t('export_count_rows', {
              count: selectedRows?.length || totalRows,
            })}
          </Alert>
        </If>
      ),
      action: {
        actionText: t('export'),
        actionCallback: () => {
          onExportClick?.();
          closeModal();
          gridContext.api.clearSelection();
        },
        buttonProps: { disabled: disabledExport },
      },
      cancel: true,
    });
  };

  return (
    <>
      <Toolbar
        sx={{
          background: 'white',
          padding: '0 !important',
          minHeight: 'fit-content !important',
          // borderBottom: `1px solid ${themes?.default?.lightFill}`,
          height: themes.default.toolBarContainerHeight,
          // ...sx,
        }}
      >
        <ToolbarContainer>
          <If condition={!selectedRows?.length}>
            <ComponentsStack>
              {hasSearchField && (
                <SearchField
                  value={queryParams.search || ''}
                  setValue={(value) =>
                    updateDataWithFunction((prev) => {
                      prev.queryParams.search = value;
                    })
                  }
                  disabled={disabled || false}
                  textFieldProps={{
                    placeholder: t('common.place_holders.search'),
                    label: '',
                    margin: 'none',
                  }}
                />
              )}
              <ManagedColumnOrderer disabled={disabled} />
              <If condition={hasMultipleViews}>
                <VerticalDivider />
                <ManagedViewToggler disabled={disabled} />
              </If>
              {!!FilterComponent && (
                <ManagedGridFilters
                  filterComponent={<FilterComponent />}
                  disabled={disabled}
                />
              )}
              <If
                condition={
                  !!ActionsComponent || !!onExportClick || !!onAddClick
                }
              >
                <VerticalDivider />
              </If>
              {ActionsComponent && <ActionsComponent />}
              <If condition={!!onExportClick && canExport}>
                <ExportButton
                  onClick={() => onExportModal()}
                  placeholder={t('common.export_all')}
                  disabled={disabled || !rowData.length}
                />
              </If>
              <If condition={!debouncedDisabled}>
                <If condition={!!onAddClick && canEdit}>
                  <AddIconButton
                    onClick={(e: React.MouseEvent<HTMLElement>) =>
                      onAddClick!(e, openMenu)
                    }
                  />
                </If>
              </If>
            </ComponentsStack>
          </If>

          <If condition={!!selectedRows?.length}>
            <ComponentsStack>
              {BulkActionsComponent && <BulkActionsComponent />}

              {BulkActionsComponent && BulkActionsComponent}
              <If condition={!!onExportClick && canExport}>
                <ActionIconButton
                  title="export"
                  onClick={() => onExportModal?.()}
                  icon={<UploadSimple />}
                />
              </If>
              <If condition={!!onBulkDelete && canEdit}>
                <ActionIconButton
                  title="delete"
                  onClick={() => {
                    onBulkDelete?.(selectedRows);
                    updateData('selectedRows', []);
                  }}
                  icon={<Icon icon={<TrashSimple />} />}
                />
              </If>
            </ComponentsStack>
          </If>
        </ToolbarContainer>
      </Toolbar>
      {MenuOptions && (
        <Menu
          options={MenuOptions}
          open={open}
          anchorEl={menuAnchor}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        />
      )}
    </>
  );
};

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 100%;
  height: 38px;
`;
