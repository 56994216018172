import styled from 'styled-components';

export const Form = styled.form`
  margin-bottom: 5vh;
  flex-direction: column;
`;
// height: 100vh !important;
export const FormWrapper = styled.div`
  padding: 16px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormSection = styled.div`
  position: relative;
`;

export const FormActions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  z-index: 2;
  border-top: ${(props) => `1px solid ${props.theme.lightFill}`};
`;

export const SectionTitle = styled.div`
  font-size: 0.9375rem;
  font-weight: 500;
  padding-bottom: 16px;
  color: ${(props) => props.theme.black};
`;

export const ValidationAdornmentWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 14px;
  padding-left: 14px;
  background: white;
  height: 100%;
`;
