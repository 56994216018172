import { useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const StockCell = (params) => {
  const fontColor = useMemo(() => {
    if (params?.value?.cellData === 0) {
      return themes?.default?.errorMain;
    } else if (params?.value?.cellData <= 20) {
      return themes?.default?.orange700;
    } else {
      return themes?.default?.grayA700;
    }
  }, [params?.value?.cellData]);

  return <Stock color={fontColor}>{`${params?.value?.cellData}`}</Stock>;
};

const Stock = styled.p<{ color: string }>`
  font-size: 0.75rem;
  line-height: 1.1rem;
  letter-spacing: 0.17px;
  color: ${(props) => props.color};
`;
