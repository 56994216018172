import api from './api';

export const customerPortalApi = api.injectEndpoints({
  endpoints: builder => ({
    getCustomerPortalStats: builder.query<any, string>({
      query: () => ({
        url: `/customer-portal/stats`,
      }),
      providesTags: ['CustomerPortalStats'],
    }),
    getCustomerPortalInvoices: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customer-portal/invoices${urlQuery}`,
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    getCustomerPortalInvoicesByList: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customer-portal/invoices/by-list${urlQuery}`,
      }),
      providesTags: ['CustomerPortalInvoices'],
    }),
    getCustomerPortalOrdersId: builder.query<any, string>({
      query: id => ({
        url: `/customer-portal/orders/${id}`,
      }),
      providesTags: ['CustomerPortalOrdersId'],
    }),
    getCustomerPortalConversationsFacets: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customer-portal/conversations/facets${urlQuery}`,
      }),
      providesTags: ['CustomerPortalConversationsFacets'],
    }),
    getCustomerPortalConversations: builder.query<any, string>({
      query: urlQuery => ({
        url: `/customer-portal/conversations${urlQuery}`,
      }),
      providesTags: ['CustomerPortalConversations'],
    }),
  }),
});

export const {
  useGetCustomerPortalStatsQuery,
  useGetCustomerPortalInvoicesQuery,
  useGetCustomerPortalInvoicesByListQuery,
  useGetCustomerPortalOrdersIdQuery,
  useGetCustomerPortalConversationsFacetsQuery,
  useGetCustomerPortalConversationsQuery,
} = customerPortalApi;

export default customerPortalApi;
