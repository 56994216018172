import { themes } from 'styles/theme/themes';
import { IColDef } from '../../../components/ManagedGrid/types';
import ReportedDateCell from './cells/ReportedDateCell';
import ClientCell from './cells/clientCell';
import ClaimIdCell from './cells/claimIdCell';
import TypeCell from './cells/typeCell';
import StatusCell from './cells/StatusCell';
import ActionsCell from './cells/ActionCell';
import ClaimReasonCell from './cells/ClaimReasonCell';
import AttachmentsCell from './cells/AttachmentsCell';
import SalesCell from './cells/SalesCell';
import ReportedByCell from './cells/ReportedByCell';
import AssigneeCell from './cells/AssigneeCell';
import ArchivedCell from './cells/ArchivedCell';
import OrderCell from './cells/OrderCell';
import StatusDotCell from './cells/StatusDotCell';
import {
  CirclesFour,
  CurrencyCircleDollar,
  Package,
  Receipt,
  Star,
} from '@phosphor-icons/react';
import BranchCell from './cells/BranchCell';

export enum CLAIMS_STATS_STATUSES {
  PENDING = 'pending',
  INPROGRESS = 'in-progress',
  RESOLVED = 'resolved',
}

export const CLAIMS_TYPES = {
  BILLING: {
    backgroundColor: themes?.default?.primaryBackgroundColor,
    color: themes?.default?.blue900,
    type: 'billing',
    label: 'claims.type.billing',
    icon: CurrencyCircleDollar,
  },
  ACCOUNTING: {
    backgroundColor: themes?.default?.pink50,
    color: themes?.default?.plannedStatusColor,
    type: 'accounting',
    label: 'claims.type.accounting',
    icon: Receipt,
  },
  DELIVERY: {
    backgroundColor: themes?.default?.amber100,
    color: themes?.default?.amber1000,
    type: 'delivery',
    label: 'claims.type.delivery',
    icon: Package,
  },
  QUALITY: {
    backgroundColor: themes?.default?.teal50,
    color: themes?.default?.teal800,
    type: 'quality',
    label: 'claims.type.quality',
    icon: Star,
  },
  OTHER: {
    backgroundColor: themes?.default?.whisper,
    color: themes?.default?.buttonGreyText,
    type: 'other',
    label: 'claims.type.other',
    icon: CirclesFour,
  },
};

export const CLAIMS_STATUS_CHIPS_COLORS = {
  PENDING: {
    backgroundColor: themes.default?.orange50,
    color: themes.default?.orange1000,
    dotColor: themes.default?.textPendingCustomer,
    label: 'events.status.pending',
    key: 'pending',
  },
  INPROGRESS: {
    backgroundColor: themes.default?.blue50,
    color: themes.default?.blue700,
    dotColor: themes.default?.blue600,
    label: 'events.status.inprogress',
    key: 'in-progress',
  },
  RESOLVED: {
    backgroundColor: themes.default?.chipGreenFont,
    color: themes.default?.greenMain,
    dotColor: themes.default?.greenLaurel,
    label: 'claims.status.resolved',
    key: 'resolved',
  },
};

export const CLAIMS_STATS_COLORS = {
  PENDING: {
    thumbColor: themes.default?.progressOrange,
    backgroundColor: themes.default?.orange50,
    railColor: themes.default?.orange100,
  },
  INPROGRESS: {
    thumbColor: themes.default?.blue600,
    backgroundColor: themes.default?.blue50,
    railColor: themes.default?.blue200,
  },
  RESOLVED: {
    thumbColor: themes.default?.fruitSaladGreen,
    backgroundColor: themes.default?.chipGreenFont,
    railColor: themes.default?.green200,
  },
};

export const CUSTOM_CLAIMS_COLUMN_CONFIG: IColDef[] = [
  {
    cellRenderer: ArchivedCell,
    width: 10,
    resizable: true,
    lockPinned: true,
    pinned: 'left',
    hide: true,
  },
  {
    cellRenderer: StatusDotCell,
    width: 10,
    resizable: true,
    lockPinned: true,
    pinned: 'left',
    hide: true,
  },
  {
    cellRenderer: TypeCell,
    width: 56,
    resizable: true,
    lockPinned: true,
    pinned: 'left',
    headerName: 'Type',
  },
  {
    cellRenderer: ClaimIdCell,
    minWidth: 94,
    resizable: true,
    lockPinned: false,
    headerName: 'Claim ID',
  },
  {
    cellRenderer: ClientCell,
    minWidth: 198,
    resizable: true,
    lockPinned: false,
    headerName: 'Client',
  },
  {
    cellRenderer: BranchCell,
    minWidth: 145,
    resizable: true,
    lockPinned: false,
    headerName: 'Branch',
  },
  {
    cellRenderer: OrderCell,
    minWidth: 137,
    resizable: true,
    lockPinned: false,
    headerName: 'Order',
  },
  {
    cellRenderer: ClaimReasonCell,
    minWidth: 198,
    resizable: true,
    lockPinned: false,
    headerName: 'Claim Reason',
  },
  {
    cellRenderer: SalesCell,
    minWidth: 59,
    resizable: true,
    lockPinned: false,
    headerName: 'Sales',
  },
  {
    cellRenderer: ReportedByCell,
    minWidth: 90,
    resizable: true,
    lockPinned: false,
    headerName: 'Reported By',
  },
  {
    cellRenderer: ReportedDateCell,
    minWidth: 252,
    resizable: true,
    lockPinned: false,
    headerName: 'Reported Date',
  },
  {
    cellRenderer: AttachmentsCell,
    minWidth: 93,
    resizable: true,
    lockPinned: false,
    headerName: 'Attachments',
  },
  {
    cellRenderer: AssigneeCell,
    minWidth: 76,
    resizable: true,
    lockPinned: false,
    headerName: 'Assignee',
  },
  {
    cellRenderer: StatusCell,
    minWidth: 105,
    resizable: true,
    headerName: 'Status',
    lockPinned: true,
    pinned: 'right',
  },

  {
    cellRenderer: ActionsCell,
    width: 65,
    resizable: false,
    lockPinned: true,
    pinned: 'right',
  },
];
