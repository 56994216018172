import React, { useState } from 'react';
import { Tooltip, Avatar, Badge, Grid, Typography } from '@mui/material';
import If from 'app/components/If';
import { themes } from 'styles/theme/themes';
import { X } from '@phosphor-icons/react';

export function AssigneesAvatar({ assignees, handleClearClaim }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <If
      condition={assignees?.length > 0}
      otherwise={
        <div style={{ marginLeft: '10px' }}>
          <span>_</span>
        </div>
      }
    >
      <div
        style={{
          display: 'flex',
          gap: '0px',
          marginLeft: '10px',
          position: 'relative',
        }}
      >
        {assignees.map((assignee, index) => (
          <div
            key={assignee?._id || index}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              marginLeft: index > 0 ? '-8px' : '0px',
              zIndex: assignees.length - index,
            }}
          >
            <If
              condition={hoveredIndex === index}
              otherwise={
                <Tooltip
                  title={`${assignee?.firstName || 'a'} ${assignee?.lastName || 'a'}`}
                >
                  <Avatar
                    sx={{
                      width: '24px',
                      height: '24px',
                      cursor: 'pointer',
                      border: '2px solid white',
                    }}
                  >
                    <Typography fontSize="0.75rem">
                      {(assignee?.firstName?.[0] || 'a') +
                        (assignee?.lastName?.[0] || 'a')}
                    </Typography>
                  </Avatar>
                </Tooltip>
              }
            >
              <Tooltip
                title={`${assignee?.firstName || 'a'} ${assignee?.lastName || 'a'}`}
              >
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClearClaim(assignee?._id);
                  }}
                  badgeContent={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        background: themes.default?.ordoriaPrimary,
                        cursor: 'pointer',
                      }}
                    >
                      <X
                        color="white"
                        weight="bold"
                        style={{
                          width: '6px',
                          height: '6px',
                        }}
                      />
                    </Grid>
                  }
                >
                  <Avatar
                    sx={{
                      width: '24px',
                      height: '24px',
                      cursor: 'pointer',
                      border: '2px solid white',
                    }}
                  >
                    <Typography fontSize="0.75rem">
                      {(assignee?.firstName?.[0] || 'a') +
                        (assignee?.lastName?.[0] || 'a')}
                    </Typography>
                  </Avatar>
                </Badge>
              </Tooltip>
            </If>
          </div>
        ))}
      </div>
    </If>
  );
}
