import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Grid, Typography } from '@mui/material';
import { themes } from 'styles/theme/themes';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';

function ClientCell(params: CustomCellRendererProps) {
  return (
    <Grid container flexDirection="column">
      <Grid item>
        <Typography fontSize="0.75rem">
          {params.value.fullNameData ?? '__'}
        </Typography>
      </Grid>
      <Grid container alignItems="center">
        <Grid3x3Icon
          sx={{
            width: '10.5px',
            height: '10.5px',
            color: themes?.default?.grey2,
          }}
        />
        <Typography fontSize="0.75rem" color={themes?.default?.grey2}>
          {params.value.clientCodeData ?? '__'}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ClientCell;
