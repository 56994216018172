import {
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';
import { ChipRadio } from 'app/components/Form/ChipRadio';
import { SectionTitle } from 'app/components/Form/styles';
import { useCheckSiren } from 'hooks/Customers/useCheckSiren';
import { useErrorFormatter } from 'hooks/Forms/useErrorFormatter';
import { useContext, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomerFormcontext } from '../../context';
import If from 'app/components/If';
import { FieldComponent } from 'app/components/FieldComponent';
import { CustomerStatus, CustomerType } from 'common/types/Customer';
import { StatusCustomer } from 'app/components/StatusCustomer';

export const MainDetails = ({ control, errors }) => {
  const { t } = useTranslation();
  const { customer, canEdit, isCustomer } = useContext(CustomerFormcontext);
  const { formatError } = useErrorFormatter();
  const { validate } = useCheckSiren();

  const statuses = useMemo(() => {
    return Object.values(CustomerStatus).map((status) => {
      return {
        label: t(`customer.status.${status}`),
        value: status,
      };
    });
  }, [t]);

  return (
    <Stack gap="16px">
      <If
        condition={!isCustomer}
        otherwise={<SectionTitle>{t('identification')}</SectionTitle>}
      >
        <FormControl sx={{ marginBottom: '0 !important' }}>
          <FormLabel component="label">
            <SectionTitle>{t('info-main-details')}</SectionTitle>
          </FormLabel>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row" gap="10px">
                  <ChipRadio
                    {...field}
                    value={CustomerType.Client}
                    disabled={!canEdit}
                    label="common.customer"
                  />
                  <ChipRadio
                    {...field}
                    value={CustomerType.PROSPECT}
                    disabled={!canEdit}
                    label="potential_client"
                  />
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>
      </If>

      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <Controller
            name="legalIdAlt"
            control={control}
            rules={{
              required: {
                value: true,
                message: `${t('Add_Client_SIREN.number')} ${t('is_required')}`,
              },
              validate(value) {
                console.log('legalIdAltlegalIdAlt', value);
                return validate({
                  siren: value?.toString() || '',
                  id: customer?._id,
                });
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                type="text"
                label={`${t('Add_Client_SIREN.number')}`}
                variant="outlined"
                error={!!errors?.legalIdAlt}
                helperText={formatError(
                  errors?.legalIdAlt,
                  t('Add_Client_SIREN.number'),
                )}
                fullWidth
                disabled={!canEdit || isCustomer}
                onChange={(value) => field.onChange(value)}
                required
              />
            )}
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <FieldComponent
            control={control}
            name="status"
            label={t('client.statusTitle')}
            select
            InputProps={{
              disabled: !canEdit || isCustomer,
              size: 'small',
            }}
          >
            {statuses?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <StatusCustomer status={option.value} />
              </MenuItem>
            ))}
          </FieldComponent>
        </Grid>
      </Grid>
    </Stack>
  );
};
