import React, { useEffect, useState } from 'react';

import { useStopwatch } from 'react-timer-hook';
import { useVonageHook } from 'hooks/VoiceCall/useVonageHook';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { Check, X as CloseIcon, Phone, PhoneX } from '@phosphor-icons/react';
import { themes } from '../../../styles/theme/themes';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

interface Call {
  callId: string;
  conversationId?: string;
  callUser: any;
  direction: string;
}

export default function VoiceCall({ authUser }) {
  const [call, setCall] = useState<Call | null>(null);
  const [startAt, setStartAt] = useState<number | null>(null);

  const { seconds, minutes, start, reset, isRunning } = useStopwatch();

  function onCallInvite(callId, callUser) {
    setCall({
      callId,
      callUser,
      direction: 'inbound',
    });
  }

  function onCallUpdate(callId, legId, status) {
    console.log('Call Update', callId, legId, status);
    if (status === 'ANSWERED') {
      setStartAt(Date.now());
      start();
    } else if (status === 'COMPLETED') {
      setStartAt(null);
      reset(undefined, false);
    }
  }

  function onCallCreate(callId, callUser, conversationId) {
    console.log('Call Create', callId, callUser, conversationId);
    setCall({
      callId,
      callUser,
      conversationId,
      direction: 'outbound',
    });
  }

  function onCallDelete(callId, callUser) {
    console.log('Call Deleted', call, callUser);
    if (call?.direction === 'inbound' && call?.callId === callId) {
      client.hangup(call.callId);
    }

    setCall(null);
  }

  const { client } = useVonageHook(
    authUser,
    onCallInvite,
    onCallUpdate,
    onCallCreate,
    onCallDelete,
  );

  const isOpen = !!call;

  useEffect(() => {
    if (call?.callId && call.direction === 'inbound') {
      const notificationTitle = `Appel entrant de ${
        call?.callUser?.displayName || 'Unknown'
      }`;

      if (Notification.permission === 'granted') {
        new Notification(notificationTitle);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            new Notification(notificationTitle);
          }
        });
      }
    }
  }, [call?.callId]);
  function acceptCall() {
    if (call) {
      client.answer(call.callId);

      start();
      setStartAt(Date.now());
    }
  }

  function rejectCall() {
    if (call) {
      client.reject(call.callId);
    }

    setCall(null);
    setStartAt(null);
    reset(undefined, false);
  }

  function handleClose(event, reason) {
    if (reason !== 'backdropClick') {
      hangupCall();
    }
  }

  function hangupCall() {
    setCall(null);
    setStartAt(null);
    reset(undefined, false);

    if (call) {
      if (call?.direction === 'inbound') {
        client.hangup(call.callId);
      } else if (call?.conversationId) {
        console.log('Leave Conversation', call.conversationId);
        client.leaveConversation(call.conversationId);
      }
    }
  }

  return (
    <Dialog
      open={isOpen}
      PaperComponent={PaperComponent}
      keepMounted
      hideBackdrop
      onClose={handleClose}
      aria-describedby="incoming-call-alert-dialog"
      maxWidth="xs"
      fullWidth
      sx={{ zIndex: 9999 }}
    >
      <DialogTitle
        id="draggable-dialog-title"
        sx={{
          cursor: 'move',
          display: 'flex',
        }}
      >
        <Phone
          style={{
            width: '16px',
            height: '16px',
            marginTop: '2px',
            marginRight: '4px',
            color: themes.default.textColorDashboardNoOpacity,
          }}
        />
        <Typography
          color={themes.default.textColorDashboardNoOpacity}
          fontWeight="400"
          fontSize="13px"
        >
          {startAt
            ? 'Appel en cours...'
            : call?.direction === 'incoming'
              ? 'Appel entrant'
              : 'Appel sortant'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <Typography color="black" fontWeight="600" fontSize="18px">
            {call?.callUser?.displayName || ''}
          </Typography>
          <Typography color="black" fontWeight="600" fontSize="18px">
            {isRunning
              ? `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
              : 'Appel...'}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '16px',
        }}
      >
        {startAt || call?.direction === 'outbound' ? (
          <Button
            variant="contained"
            onClick={hangupCall}
            sx={{
              flex: 0.3,
              background: themes.default.redA700,
              ':hover': {
                background: themes.default.redA700,
              },
            }}
          >
            <PhoneX
              color="white"
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
            />

            <Typography color="white" fontWeight="500" fontSize="13px">
              Raccrocher
            </Typography>
          </Button>
        ) : null}
        {!startAt && call?.direction === 'inbound' && (
          <>
            <Button
              variant="contained"
              onClick={acceptCall}
              sx={{
                flex: 1,
                background: '#009688',
                ':hover': {
                  background: '#00786c',
                },
              }}
            >
              <Check
                color="white"
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '4px',
                }}
              />
              <Typography color="white" fontWeight="500" fontSize="13px">
                Accepter
              </Typography>
            </Button>

            <Button
              variant="contained"
              onClick={rejectCall}
              sx={{
                flex: 1,
                background: themes.default.redA700,
                ':hover': {
                  background: themes.default.redA700,
                },
              }}
            >
              <CloseIcon
                color="white"
                style={{
                  width: '16px',
                  height: '16px',
                  marginRight: '4px',
                }}
              />

              <Typography color="white" fontWeight="500" fontSize="13px">
                Refuser
              </Typography>
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
