import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { Flag as FlagIcon } from '@phosphor-icons/react';
import { Stack, Typography, Tooltip } from '@mui/material';
import { themes } from 'styles/theme/themes';
import { useMemo } from 'react';
import { OrderFrequency } from 'app/pages/Ordoria/ViewEditOrder/types';

export const FlagCell = (params) => {
  const { t } = useTranslation();
  const { orderFrequency } = params?.value;

  const color = useMemo(() => {
    return {
      [OrderFrequency.HIGH]: themes?.default.greenStatus,
      [OrderFrequency.MEDIUM]: themes?.default.greyFlag,
      [OrderFrequency.LOW]: themes?.default.redFlag,
      [OrderFrequency.NOT_ORDERED]: themes?.default.greyFlag,
    };
  }, []);

  const flagTooltip = useMemo(() => {
    return {
      [OrderFrequency.HIGH]: t('products.tooltip.order.high'),
      [OrderFrequency.MEDIUM]: t('products.tooltip.order.medium'),
      [OrderFrequency.LOW]: t('products.tooltip.order.low'),
      [OrderFrequency.NOT_ORDERED]: t('products.tooltip.order.not_ordered'),
    };
  }, [t]);

  if (!orderFrequency) return null;

  return (
    <Wrapper>
      <Tooltip title={flagTooltip[orderFrequency]}>
        <Stack direction="row" alignItems="center">
          <Typography
            fontSize="1.25rem"
            lineHeight="1.5rem"
            color={themes.default.Cinnabar}
            sx={{
              opacity: orderFrequency === OrderFrequency.NOT_ORDERED ? 1 : 0,
            }}
          >
            !
          </Typography>
          <Icon
            icon={<FlagIcon weight="fill" />}
            color={color[orderFrequency]}
            size={20}
          />
        </Stack>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;
