import { UpdateOrganizationSettingsDto } from '../types/Organization';
import api from './api';
import { processWarehouses } from '../Dtos/Warehouses';
import { IWarehouseResponseDto } from '../types/Warehouses';

export const organizationApis = api.injectEndpoints({
  endpoints: builder => ({
    getOrganization: builder.query<any, void>({
      query: () => ({
        url: '/organization',
      }),
      providesTags: ['Organization'],
    }),
    getConfiguration: builder.query<any, void>({
      query: () => ({
        url: '/organization/configuration',
      }),
      extraOptions: {
        refetchOnFocus: true,
      },
      providesTags: ['OrganizationConfiguration'],
    }),
    getWarehouses: builder.query<any, string>({
      query: urlQuery => ({
        url: `/organization/decks${urlQuery}`,
        method: 'GET',
      }),
      providesTags: ['Stats', 'Organization'],
      transformResponse: (response: any, meta, arg) => {
        const urlParams = new URLSearchParams(arg);
        const dto = urlParams.get('dto');
        if (dto === 'mobile') {
          return response;
        }
        return processWarehouses(response) as IWarehouseResponseDto;
      },
    }),
    patchWarehouse: builder.mutation<void, any>({
      query: ({ deckId, ...body }) => ({
        url: `/organization/decks/${deckId}`,
        method: 'PATCH',
        body: body,
      }),
      extraOptions: {
        maxRetries: 0,
      },
      invalidatesTags: ['Stats', 'Organization'],
    }),
    postWarehouse: builder.mutation<void, any>({
      query: body => ({
        url: `/organization/decks`,
        method: 'POST',
        body: body,
      }),
      extraOptions: {
        maxRetries: 0,
      },
      invalidatesTags: ['Organization'],
    }),
    getOrganizationActivities: builder.query<any, void>({
      query: () => ({
        url: '/organization/activities',
      }),
      extraOptions: {
        refetchOnFocus: true,
      },
      providesTags: ['OrganizationActivities'],
    }),
    patchOrganizationSettings: builder.mutation<
      any,
      { id: string } & UpdateOrganizationSettingsDto
    >({
      query: ({ id, ...body }) => ({
        url: `/organization/${id}/settings`,
        method: 'PATCH',
        body: body,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
  }),
});

export const {
  useGetConfigurationQuery,
  useGetOrganizationQuery,
  useLazyGetConfigurationQuery,
  useGetWarehousesQuery,
  usePatchWarehouseMutation,
  usePostWarehouseMutation,
  useGetOrganizationActivitiesQuery,
  useLazyGetOrganizationActivitiesQuery,
  usePatchOrganizationSettingsMutation,
} = organizationApis;

export default organizationApis;
