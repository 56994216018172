import { Stack, Tooltip } from '@mui/material';
import { themes } from 'styles/theme/themes';
import {
  CalendarBlank,
  User,
  PencilSimpleLine,
  Cube,
  ShoppingBagOpen,
} from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import { useTranslation } from 'react-i18next';
import { StatusCustomer } from 'app/components/StatusCustomer';
import OrdoriaLogo from '../../../../../../../assets/img/Menu/Logo-Ordoria-lightmode2.svg';
import {
  CustomerInvitationWrapper,
  CustomerInvitationWrapperActive,
  CustomerInvitationWrapperInactive,
  InvitationButton,
} from 'hooks/Customers/useFormatCustomersData';
import {
  MarkunreadMailboxOutlined,
  PersonAddAlt1Outlined,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { InfoCard } from 'app/components/InfoCard';
import { AgentStatuses } from 'app/pages/Users/data/types';
import { useMemo } from 'react';
import { Permission, usePermission } from 'hooks/Abilities/usePermission';
import If from 'app/components/If';

export const CustomerInfoCard = ({
  customerDetail,
  customerFinancials,
  setInviteEmails,
  isCustomer = false,
}) => {
  const can = usePermission('customers');
  const { t } = useTranslation();
  const oneMonthAgo = dayjs().subtract(1, 'month');

  const tooltipTitle = useMemo(() => {
    const emails = customerDetail?.agents?.map((agent) => {
      return {
        email: agent.email,
        invited: agent.status === AgentStatuses.Invited,
      };
    });
    const invitedEmails = emails?.filter((email) => email.invited);
    const count = invitedEmails?.length || 1;

    return count > 1
      ? t('count_emails_invited', { count })
      : invitedEmails?.[0]?.email || '';
  }, [customerDetail?.agents, t]);
  return (
    <Stack direction="row" gap="16px" width="100%">
      <InfoCard
        title={customerDetail?.code}
        value={customerDetail?.legalName}
        icon={
          <Icon icon={<User />} color={themes.default?.primaryActiveColor} />
        }
        iconBg={themes.default?.primaryLight1}
      />
      <InfoCard
        title={t('customer_Added_on')}
        value={
          <div>
            {customerDetail
              ? window.dayjs(customerDetail?.createdAt).format('D MMM YYYY')
              : null}
          </div>
        }
        icon={
          <Icon
            icon={<CalendarBlank />}
            color={themes.default?.fruitSaladGreen}
          />
        }
        iconBg={themes.default?.teal50}
      />
      <InfoCard
        title={t('customer_Last_Edited_on')}
        value={
          <div>
            {customerDetail
              ? window.dayjs(customerDetail?.updatedAt).format('D MMM YYYY')
              : null}
          </div>
        }
        icon={
          <Icon icon={<PencilSimpleLine />} color={themes.default?.yellow800} />
        }
        iconBg={themes.default?.yellow100}
      />

      <InfoCard
        title={t('customer_Status')}
        value={<StatusCustomer status={customerDetail?.status} />}
        icon={<Icon icon={<Cube />} color={themes.default?.AcidGreen} />}
        iconBg={themes.default?.Chiffon}
      />
      <InfoCard
        title={t('customer_Active_Orders')}
        value={customerFinancials?.countActiveOrders}
        icon={
          <Icon
            icon={<ShoppingBagOpen />}
            color={themes.default?.TurquoiseSurf}
          />
        }
        iconBg={themes.default?.cyan50}
      />

      <If condition={!isCustomer}>
        <InfoCard
          title={t('customer_Ordoria_Status')}
          value={
            <>
              {customerDetail?.status &&
                !customerDetail?._lastLoggedUser &&
                (!customerDetail?.invitedAt ||
                  (customerDetail?.invitedAt &&
                    dayjs(customerDetail?.invitedAt).isBefore(
                      oneMonthAgo,
                    ))) && (
                  <InvitationButton
                    disabled={!can(Permission.EDIT)}
                    variant="contained"
                    startIcon={
                      <PersonAddAlt1Outlined
                        sx={{ width: '12px', height: '12px' }}
                      />
                    }
                    onClick={() => {
                      setInviteEmails(true);
                    }}
                  >
                    {t('customer.invite')}
                  </InvitationButton>
                )}

              {customerDetail?.invitedAt &&
                dayjs(customerDetail?.invitedAt).isAfter(oneMonthAgo) &&
                !customerDetail?._lastLoggedUser && (
                  <Tooltip title={tooltipTitle}>
                    <CustomerInvitationWrapper>
                      <MarkunreadMailboxOutlined
                        sx={{ width: '12px', height: '12px' }}
                      />
                      <div>{t('customer.invite_sent')}</div>
                    </CustomerInvitationWrapper>
                  </Tooltip>
                )}

              {customerDetail?.invitedAt &&
                dayjs(customerDetail?._lastLoggedUser).isBefore(
                  dayjs().subtract(6, 'month'),
                ) && (
                  <CustomerInvitationWrapperInactive>
                    <CancelIcon sx={{ width: '12px', height: '12px' }} />
                    <div>{t('inactive')}</div>
                  </CustomerInvitationWrapperInactive>
                )}
              {customerDetail?.invitedAt && customerDetail?._lastLoggedUser && (
                <CustomerInvitationWrapperActive>
                  <CheckIcon sx={{ width: '12px', height: '12px' }} />
                  <div>{t('active')}</div>
                </CustomerInvitationWrapperActive>
              )}
            </>
          }
          icon={
            <Icon
              icon={
                <img
                  src={OrdoriaLogo}
                  aria-label="Ordoria"
                  style={{ width: '18px', height: '18px' }}
                />
              }
              color={themes.default?.cyan600}
            />
          }
          iconBg={themes.default?.lightgraymagenta}
        />
      </If>
    </Stack>
  );
};
