import { CustomCellRendererProps } from '@ag-grid-community/react';
import useFormatCurrency from 'common/hooks/useFormatCurrency';

function AmountCell(params: CustomCellRendererProps) {
  const formatCurrency = useFormatCurrency();

  return (
    <div>
      <span>{formatCurrency(params.value.amountData)}</span>
    </div>
  );
}

export default AmountCell;
