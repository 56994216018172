import { useToaster } from 'hooks/useToaster';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import {
  useAddNewProductToCartMutation,
  useChangeCartProductQuantityMutation,
  useGetCartQuery,
} from 'common/services/cartApi';
import { hashObject } from 'common/utils/hashObject';
import { useDispatch } from 'react-redux';
import { changeCartVisibilityState } from 'common/store/cart';
import { ValueInput } from '../../ValueInput';
import { useAsyncDataV2 } from 'hooks/useAsyncDataV2';

export const QuantityInputCell = (params) => {
  const { data: cart } = useGetCartQuery();
  const [changeCartProductQuantity] = useChangeCartProductQuantityMutation();
  const [addNewProductToCart] = useAddNewProductToCartMutation();
  const dispatch = useDispatch();
  const { callApi, loading } = useAsyncDataV2();

  const toaster = useToaster();
  const cartHash = hashObject(cart || {});

  // Get initial product quantity
  const quantity = useMemo(() => {
    const product = cart?.products.find(
      (item) => item?._id._id === params?.value?.cellData?.id,
    );
    return product ? product.quantity : 0;
  }, [cartHash]);

  const [localQuantity, setLocalQuantity] = useState(quantity);

  useEffect(() => {
    setLocalQuantity(quantity);
  }, [quantity]);

  const updateQuantity = async (newQuantity: number) => {
    await callApi(
      async () => {
        if (quantity === 0) {
          return await addNewProductToCart({
            productId: params?.value?.cellData?.id,
            quantity: newQuantity,
          });
        } else {
          return await changeCartProductQuantity({
            productId: params?.value?.cellData?.id,
            quantity: newQuantity,
          });
        }
      },
      {
        onSuccess: () =>
          toaster(2000, 'success', 'Product added to Cart Successfully'),
        onError: () => {
          toaster(2000, 'error', 'Failed to update quantity');
          setLocalQuantity(quantity); // Revert to previous quantity on error
        },
      },
    );
  };

  const debouncedUpdateQuantity = useCallback(
    debounce((qty: number) => updateQuantity(qty), 500),
    [quantity],
  );

  const handleQuantityChange = (newQuantity: number) => {
    setLocalQuantity(newQuantity);
    debouncedUpdateQuantity(newQuantity);
  };

  const handleOpenCart = () => {
    dispatch(changeCartVisibilityState(true));
  };

  return (
    <ValueInput
      initialValue={localQuantity}
      onChange={(value) => handleQuantityChange(value)}
      onAdd={() => handleOpenCart()}
      loading={loading}
    />
  );
};
