import { IColDef } from 'app/components/ManagedGrid/types';
import TypeCell from './cells/TypeCell';
import InvoiceCodeCell from './cells/InvoiceCodeCell';
import NameCell from './cells/NameCell';
import CreatedAtCell from './cells/CreatedAtCell';
import AmountCell from './cells/AmountCell';
import DueDateCell from './cells/DueDateCell';
import PayedAmountCell from './cells/PayedAmountCell';
import StatusCell from './cells/StatusCell';
import ActionCell from './cells/ActionCell';
export const getCustomMultiplePaymentsColumnConfig = (
  t: (key: string) => string,
): IColDef[] => [
  {
    cellRenderer: TypeCell,
    width: 20,
    resizable: false,
    lockPinned: true,
    pinned: 'left',
    headerName: t('field.type'),
    cellStyle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  {
    cellRenderer: InvoiceCodeCell,
    width: 100,
    resizable: true,
    lockPinned: false,
    pinned: 'left',
    headerName: t('transaction.invoice.code'),
  },
  {
    cellRenderer: NameCell,
    minWidth: 350,
    resizable: true,
    headerName: t('warehouse.heads.name'),
  },
  {
    cellRenderer: CreatedAtCell,
    minWidth: 80,
    resizable: true,
    headerName: t('created_on'),
  },
  {
    cellRenderer: AmountCell,
    width: 80,
    resizable: true,
    headerName: t('roundtrips.roundtripDetails.amount'),
  },
  {
    cellRenderer: DueDateCell,
    width: 80,
    resizable: true,
    headerName: t('common.due_date'),
  },
  {
    cellRenderer: PayedAmountCell,
    width: 80,
    resizable: true,
    headerName: t('transaction.payed.amount'),
  },
  {
    cellRenderer: StatusCell,
    minWidth: 120,
    resizable: true,
    headerName: t('payments.status'),
  },
  {
    cellRenderer: ActionCell,
    minWidth: 120,
    resizable: false,
    headerName: t('checkDocument.action'),
    pinned: 'right',
    lockPinned: true,
    flex: 1,
  },
];
