import {
  Alert,
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import {
  useGetConfigurationQuery,
  useGetWarehousesQuery,
} from 'common/services/organizationApi';
import { ROUNDTRIP_ADD } from 'utils/routes';
import {
  checkExistingRoundtrips,
  triggerClusterByMode,
} from 'app/components/RoundTrips/function';
import { AlertContainer, LoadingContainer } from '../Existing';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { useCheckWarehouses } from 'hooks/RoundTrips/useCheckWarehouses';
import { useModel } from '../useModel';
import { useCountRoundtrip } from '../../../hooks/useCountRoundtrip';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { BlankTemplate } from 'app/components/BlankTemplate';
import { TemplatesCards } from 'app/components/TemplatesCards';
import {
  useCreateRoundtripFromTemplateMutation,
  useLazyGetRoundTripsClusterQuery,
  useLazyGetTemplatesRoundtripsQuery,
} from 'common/services/roundtripApi';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useSelector } from 'react-redux';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import { TabsContext } from 'app/pages/RoundtripsTabs';
import { TwoViewModal } from 'app/components/TwoViewModal';
import dayjs from 'dayjs';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSubscribeObject } from 'common/hooks/ws';

export const TemplateIntegration = ({
  dateValue,
  setWarehouse,
  warehouse,
  begin,
  end,
  warehouseId,
  warehouseMainId,
  roundTripCopy,
  allRoundtrip,
  open,
  setOpen,
}) => {
  const [countRoundtrip, setCountRoundtrip] = useState(0);

  const tabsContext = useContext(TabsContext);
  const userContext = tabsContext?.userContext;

  const [agentCategory, setAgentCategory] = useState<AgentCategories>(
    userContext || AgentCategories.Logistic,
  );

  const { t } = useTranslation();
  const { data: warehouses } = useGetConfigurationQuery();
  const { data: warehouseDataFetched } = useGetWarehousesQuery('');
  const organization = useSelector(selectOrganization);

  const subscribedOrganization = organization?.id;

  const rawMessage = useSubscribeObject(
    'organization-decks',
    subscribedOrganization,
  );
  const wsDecksMessage = rawMessage ? JSON.parse(rawMessage) : null;

  const [warehouseData, setWarehouseData] = useState({
    warehouses: Array.isArray(warehouses) ? warehouses : [],
  });

  useEffect(() => {
    if (
      warehouseDataFetched &&
      Array.isArray(warehouseDataFetched.warehouses)
    ) {
      setWarehouseData(warehouseDataFetched);
    } else {
      console.error(
        'Invalid warehouseDataFetched structure:',
        warehouseDataFetched,
      );
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (warehouseDataFetched) {
      setWarehouseData(warehouseDataFetched);
    }
  }, [warehouseDataFetched]);

  useEffect(() => {
    if (
      wsDecksMessage?.objectType === 'organization-decks' &&
      wsDecksMessage?.data?.deckId
    ) {
      const { deckId, status } = wsDecksMessage.data;

      setWarehouseData((prev) => {
        const updatedWarehouses = prev.warehouses.map((item) =>
          item.id === deckId ? { ...item, status } : item,
        );
        return { ...prev, warehouses: updatedWarehouses };
      });
    }
  }, [rawMessage]);

  const [trigger, { data: templateRoundtrips, isFetching }] =
    useLazyGetRoundTripsClusterQuery();
  const {
    disabledProceed,
    setDisabledProceed,
    alreadyExists,
    setAlreadyExists,
    isLoading,
    setIsLoading,
    defaultWarehouse,
    setDefaultWarehouse,
    handleCloseActivate,
    setSelectedIndex,
  } = useModel(setOpen, warehouses);
  const wareHousesProps = {
    options: Array.isArray(warehouseData?.warehouses)
      ? warehouseData.warehouses.filter((item) => item.status !== 'inactive')
      : [],
    getOptionLabel: (option) => option.name,
  };
  const defaultWarehouseExists = wareHousesProps.options.some(
    (warehouse) => warehouse._id === defaultWarehouse?._id,
  );
  const updatedDefaultWarehouse = defaultWarehouseExists
    ? defaultWarehouse
    : null;
  const result = warehouseMainId
    ? wareHousesProps?.options?.find((item) => item._id === warehouseMainId)
    : updatedDefaultWarehouse;
  const [warehouseValue, setWarehouseValue] = useState(result || null);

  useEffect(() => {
    setWarehouseValue(result || null);
  }, [result]);
  const typeProps = {
    options: [
      AgentCategories.Sales,
      AgentCategories.Logistic,
      AgentCategories.Carrier,
    ],
  };

  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);

  const deckId = warehouse?._id;

  const [triggerGetTemp, { data: templates }] =
    useLazyGetTemplatesRoundtripsQuery();

  useEffect(() => {
    if (deckId && agentCategory) {
      triggerGetTemp({ _deck: deckId, agentCategory });
    }
  }, [deckId, agentCategory]);

  useEffect(() => {
    if (userContext) setAgentCategory(userContext);
  }, [userContext]);

  const authUser = useSelector(selectAuthUser);

  const [createRoundtrip] = useCreateRoundtripFromTemplateMutation();

  const handleProceedClick = () => {
    createRoundtrip({
      templateId: selectedTemplate,
      mode: 'template',
      _deck: deckId,
      begin: begin,
      end: end,
      name: warehouse?.name + ' - Template Integration',
      agentCategory: agentCategory || AgentCategories.Logistic,
    })
      .unwrap()
      .then((resp) => {
        if (resp?.cluster) {
          const url = new URL(ROUNDTRIP_ADD, window.location.origin);
          url.searchParams.append('warehouse', JSON.stringify(warehouse));
          url.searchParams.append('dateValue', JSON.stringify(dateValue));
          url.searchParams.append('mode', 'template');
          url.searchParams.append('resultNbr', countRoundtrip + '');
          url.searchParams.append('cluster', JSON.stringify(resp.cluster));

          window.open(url.toString(), '_blank');
        }
      })
      .catch((err) => {
        console.log(err);
      });

    handleCloseActivate();
  };

  useCheckWarehouses({
    triggerByMode: trigger,
    begin,
    end,
    roundTripsByMode: templateRoundtrips,
    openActivate: open,
    warehouse,
    setDisabledProceed,
    setAlreadyExists,
    mode: 'template',
    setIsLoading,
    warehouses,
    setDefaultWarehouse,
    setWarehouse,
    warehouseId,
    isFetching,
    userContext: agentCategory,
  });
  useCountRoundtrip({
    roundTripCopy,
    warehouse,
    allRoundtrip,
    setCountRoundtrip,
  });

  return (
    <TwoViewModal
      onOpen={!!open}
      onClose={handleCloseActivate}
      title={
        t('drawer.modal.Create_roundtrips_by_templates') +
        ' ' +
        `( ${dayjs(dateValue).format('DD/MM/YYYY')} )`
      }
      width="780px"
    >
      <DialogContent>
        <AlertContainer>
          {alreadyExists && (
            <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
              {t('drawer.modal.roundtrips_already_exist')}
            </Alert>
          )}
          {!warehouses?.decks?.length && (
            <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
              {t('drawer.modal.roundtrip_no_warehouses')}
            </Alert>
          )}
        </AlertContainer>
        <DialogContentText id="alert-dialog-description">
          <Grid container alignItems="center">
            <Autocomplete
              disabled={!warehouses?.decks?.length}
              defaultValue={warehouseValue}
              style={{ width: '360px', marginTop: '12px' }}
              {...wareHousesProps}
              autoComplete={false}
              onChange={async (e, value) => {
                setAlreadyExists(false);
                setDisabledProceed(true);
                setCountRoundtrip(0);
                setWarehouse(value || '');

                if (value && value.name) {
                  const selectedIndex = warehouses?.decks.findIndex(
                    (deck) => deck._id === value._id,
                  );
                  setSelectedIndex(selectedIndex);
                  setDisabledProceed(true);
                  setIsLoading(true);
                  await triggerClusterByMode(
                    trigger,
                    'template',
                    value?._id,
                    begin,
                    end,
                  );
                  if (
                    templateRoundtrips &&
                    templateRoundtrips?.docs?.[0]?._deck?._id === value?._id &&
                    !warehouse?._id
                  ) {
                    checkExistingRoundtrips({
                      value,
                      setDisabledProceed,
                      setAlreadyExists,
                      mode: 'template',
                      data: templateRoundtrips,
                      setIsLoading,
                    });
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    width: '355px',
                  }}
                  name="Warehouse"
                  size="small"
                  label={t('select_warehouse')}
                  variant="outlined"
                  aria-label="autocomplete"
                />
              )}
            />
            <Autocomplete
              style={{
                width: '355px',
                marginLeft: '4px',
                marginTop: '12px',
              }}
              {...typeProps}
              autoComplete={false}
              disableClearable
              defaultValue={agentCategory}
              onChange={async (e, value) => {
                setAlreadyExists(false);
                setDisabledProceed(true);
                setAgentCategory(value);

                if (value && warehouse?._id) {
                  setDisabledProceed(true);
                  setIsLoading(true);
                  await triggerClusterByMode(
                    trigger,
                    'template',
                    warehouse?._id,
                    begin,
                    end,
                    value,
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{
                    width: '355px',
                  }}
                  name="Type"
                  size="small"
                  label="Type"
                  variant="outlined"
                  aria-label="autocomplete"
                />
              )}
            />
          </Grid>
        </DialogContentText>
        <div className="template-section">
          <Grid container alignItems="center">
            <Typography fontWeight="700" fontSize="1rem" sx={{ mr: 'auto' }}>
              {t('orders.title.chooseTemplate')}
            </Typography>
            <Button startIcon={<SettingsOutlinedIcon />}>
              {t('roundtrip.add_roundtrips.manage_templates')}
            </Button>
          </Grid>
          <div
            className="templates"
            style={{
              marginTop: '24px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '13px',
              height: '100%',
            }}
          >
            <div
              className="blankTemplates"
              style={{
                width: '170px',
              }}
            >
              <BlankTemplate
                selectTemplate={selectedTemplate}
                handleTemplateClick={() => setSelectedTemplate(null)}
                type="roundtrips"
              />
            </div>

            {templates?.templates?.map((template) => (
              <div
                className="templateCard"
                style={{
                  width: '170px',
                }}
              >
                <TemplatesCards
                  name={template?.name}
                  description={template?.description}
                  weekDays={template?.weekDays}
                  productsNumber={template?.totalCount}
                  isSelected={selectedTemplate === template?._id}
                  select={() => setSelectedTemplate(template?._id)}
                  createdBy={authUser?.fullName}
                  type="roundtrips"
                />
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'right',
          width: '100%',
        }}
        sx={{ padding: 0 }}
      >
        <Button onClick={handleCloseActivate} color="inherit">
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleProceedClick}
          disabled={disabledProceed || !warehouses?.decks?.length}
          variant="contained"
          autoFocus
          sx={{ height: '33px', width: '88px' }}
        >
          {isLoading ? (
            <LoadingContainer>
              <LoadingPulse />
            </LoadingContainer>
          ) : (
            t('common.buttons.proceed')
          )}
        </Button>
      </DialogActions>
    </TwoViewModal>
  );
};
