import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useLocation } from 'react-router-dom';
import { Badge } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { MainNavItem, ScrollableSection, OptionsWrapper } from '../helpers';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  ChartPieSlice,
  RoadHorizon,
  Package,
  IdentificationBadge,
  Car,
  Shapes,
  Devices,
  Cardholder,
  PresentationChart,
  Money,
} from '@phosphor-icons/react';
import PalletTruck from 'assets/img/navigtion_left/PalletTruck.svg';

import If from '../../If';
import {
  ACCESSORIES,
  ALL_ROUNDTRIPS,
  CHECK_DOCUMENTS,
  CUSTOMER,
  DASHBOARD,
  DASHBOARD_ALL,
  INBOX,
  ORDERS,
  PAYMENTCARDS,
  // PAYMENTS,
  PAYMENTS_ROOT,
  REPORTS,
  REPORTS_ALL,
  ROUNDTRIP,
  SCHEDULED_ROUNDTRIPS,
  SUPPORTUNIT,
  TOOLS,
  UPLOAD_FILES,
  USERS,
  VEHICLES,
  VEHICLES_LOGISTIC,
  VEHICLES_SALES,
} from '../../../../utils/routes';
import { AccessoryType } from 'app/pages/Accessories/Types';
import { isActiveNestedRoute, isActiveRoute } from '../index';
import { selectConfiguration } from 'common/store/organization/selectors';
import { useApplication } from 'hooks/useApplication';
import { useDispatch, useSelector } from 'react-redux';
import { Can } from 'hooks/Abilities/context';
import { useAbility } from 'hooks/Abilities';
import { selectNavMenuPreferences } from 'common/store/app/selectors';
import { appActions, defaultMenuNavItems } from 'common/store/app';
import { MenuTitleWrapper } from '../Components';
import { MenuNavItem } from '../types';
import { sortNavMenuItems } from 'app/helpers/helpers';

export default function BiantaMenu({ theme, mode, organizationActivities }) {
  const ability = useAbility();
  const { t } = useTranslation();
  const location = useLocation();

  const displayResourcesMenu = useMemo(() => {
    return (
      ability.can('canview', 'users') ||
      ability.can('canview', 'vehicles') ||
      ability.can('canview', 'accessories') ||
      ability.can('canview', 'tools') ||
      ability.can('canview', 'paymentcards') ||
      ability.can('canview', 'supportunits')
    );
  }, [ability]);

  const displayMainMenu = useMemo(() => {
    return (
      ability.can('canview', 'dashboard') ||
      ability.can('canview', 'roundtrips')
    );
  }, [ability]);

  // const displayMenuAll = useMemo(() => {
  //   return (
  //     ability.can('canview', 'dashboard-all') ||
  //     ability.can('canview', 'reports-all')
  //   );
  // }, [ability]);

  const displayGeneralMenu = useMemo(() => {
    return (
      ability.can('canview', 'orders') ||
      ability.can('canview', 'customers') ||
      ability.can('canview', 'reports') ||
      ability.can('canview', 'accounting') ||
      ability.can('managepersonal', 'accounting')
    );
  }, [ability]);

  const configuration = useSelector(selectConfiguration);
  const roles = configuration.roles;
  const currentApplication = useApplication();
  const dispatch = useDispatch();
  const navMenuPreferences = useSelector(selectNavMenuPreferences);

  const [openSectionIndex, setOpenSectionIndex] = useState<number[]>(
    displayMainMenu ? [0] : [1],
  );

  const isMinify = mode === 'minify';
  const isDashboardAll = location.pathname === DASHBOARD_ALL;
  const isReportAll = location.pathname === REPORTS_ALL;

  const handleRowToggle = (index: number) => {
    setOpenSectionIndex((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [index];
      }
    });
  };

  const displayEmployees = useMemo(() => {
    return (
      roles.filter((r) =>
        r.privileges?.roleproperties?.includes(
          `${currentApplication?.id}.showinemployee`,
        ),
      )?.length && ability.can('canview', 'users')
    );
  }, [currentApplication?.id, roles, ability]);

  useEffect(() => {
    if (!navMenuPreferences?.bianta) {
      dispatch(
        appActions.setNavMenuPreferences({
          ...navMenuPreferences,
          bianta: defaultMenuNavItems.bianta,
        }),
      );
    }
  }, [dispatch, navMenuPreferences]);

  const menuMapping = useMemo(() => {
    const biantaPreferences =
      navMenuPreferences?.bianta || defaultMenuNavItems.bianta;

    const updateStore = (key, value) => {
      dispatch(
        appActions.setNavMenuPreferences({
          ...navMenuPreferences,
          bianta: {
            ...navMenuPreferences.bianta,
            [key]: value,
          },
        }),
      );
    };

    const sortedMenuItems = sortNavMenuItems(
      defaultMenuNavItems.bianta.menuItems,
      biantaPreferences.menuItems || [],
      (sorted) => updateStore('menuItems', sorted),
    );

    const sortedMenuAllItems = sortNavMenuItems(
      defaultMenuNavItems.bianta.menuAllItems,
      biantaPreferences.menuAllItems || [],
      (sorted) => updateStore('menuAllItems', sorted),
    );

    return {
      main:
        isDashboardAll || isReportAll ? sortedMenuAllItems : sortedMenuItems,
      general: sortNavMenuItems(
        defaultMenuNavItems.bianta.generalItems,
        biantaPreferences.generalItems || [],
        (sorted) => updateStore('generalItems', sorted),
      ),
      resources: sortNavMenuItems(
        defaultMenuNavItems.bianta.resourcesItems,
        biantaPreferences.resourcesItems || [],
        (sorted) => updateStore('resourcesItems', sorted),
      ),
    };
  }, [dispatch, navMenuPreferences, isDashboardAll, isReportAll]);

  const MenuNavItems = useMemo(() => {
    const items: MenuNavItem[] = [];

    if (displayMainMenu) {
      items.push({
        id: 'biantaMenu1',
        title: t('nav.mainNav.title1'),
        onClick: () => handleRowToggle(0),
        items: menuMapping.main,
      });
    }

    if (!(isDashboardAll || isReportAll) && displayGeneralMenu) {
      items.push({
        id: 'biantaMenu2',
        title: t('nav.mainNav.title2'),
        onClick: () => handleRowToggle(1),
        items: menuMapping.general,
      });
    }

    if (!(isDashboardAll || isReportAll) && displayResourcesMenu) {
      items.push({
        id: 'biantaMenu3',
        title: t('nav.mainNav.resources'),
        onClick: () => handleRowToggle(2),
        items: menuMapping.resources,
      });
    }

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    menuMapping,
    isDashboardAll,
    isReportAll,
    displayMainMenu,
    displayGeneralMenu,
    displayResourcesMenu,
  ]);

  const getToById = (id: string): string => {
    switch (id) {
      case 'dashboard-all':
        return DASHBOARD_ALL;

      case 'reports-all':
        return REPORTS_ALL;

      case 'dashboard':
        return DASHBOARD;

      case 'roundtrips':
        return `${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`;

      case 'orders':
        return ORDERS;

      case 'customers':
        return CUSTOMER;

      case 'inbox':
        return INBOX;

      case 'checkDocuments':
        return CHECK_DOCUMENTS;

      case 'payments-uploadFiles':
        return `${PAYMENTS_ROOT}/unprocessed`;

      case 'uploads-uploadFiles':
        return UPLOAD_FILES;

      case 'reports':
        return REPORTS;

      case 'employees':
        return USERS;

      case 'vehicles':
        return `${VEHICLES}/logistic`;

      case 'accessories':
        return ACCESSORIES;

      case 'tools':
        return TOOLS;

      case 'paymentcards':
        return PAYMENTCARDS;

      case 'supportunits':
        return SUPPORTUNIT;

      default:
        return DASHBOARD;
    }
  };

  const getTitleById = (id: string): string => {
    switch (id) {
      case 'dashboard-all':
        return t('nav.mainNav.dashboard');

      case 'reports-all':
        return t('nav.mainNav.reports');

      case 'dashboard':
        return t('nav.mainNav.dashboard');

      case 'roundtrips':
        return t('nav.mainNav.roundtrips');

      case 'orders':
        return t('nav.mainNav.orders');

      case 'customers':
        return t('nav.mainNav.customers');

      case 'inbox':
        return t('nav.mainNav.inbox');

      case 'checkDocuments':
        return t('nav.mainNav.checkDocuments');

      case 'payments-uploadFiles':
        return t('nav.mainNav.payments');

      case 'uploads-uploadFiles':
        return t('nav.mainNav.uploads');

      case 'reports':
        return t('nav.mainNav.reports');

      case 'employees':
        return t('nav.mainNav.employees');

      case 'vehicles':
        return t('nav.mainNav.vehicles');

      case 'accessories':
        return t('nav.mainNav.accessories');

      case 'tools':
        return t('nav.mainNav.tools');

      case 'paymentcards':
        return t('nav.mainNav.paymentcards');

      case 'supportunits':
        return t('nav.mainNav.supportUnit');

      default:
        return t('nav.mainNav.dashboard');
    }
  };

  const getIconById = (id: string): JSX.Element => {
    switch (id) {
      case 'dashboard-all':
        return (
          <IconWrapper isActive={isActiveRoute(location, DASHBOARD_ALL)}>
            <ChartPieSlice />
          </IconWrapper>
        );

      case 'reports-all':
        return (
          <IconWrapper isActive={isActiveRoute(location, REPORTS_ALL)}>
            <PresentationChart />
          </IconWrapper>
        );

      case 'dashboard':
        return (
          <IconWrapper isActive={isActiveRoute(location, DASHBOARD)}>
            <ChartPieSlice />
          </IconWrapper>
        );

      case 'roundtrips':
        return (
          <IconWrapper
            isActive={isActiveNestedRoute(location, [
              `${ROUNDTRIP}/${SCHEDULED_ROUNDTRIPS}`,
              `${ROUNDTRIP}/${ALL_ROUNDTRIPS}`,
            ])}
          >
            <RoadHorizon />
          </IconWrapper>
        );

      case 'orders':
        return (
          <IconWrapper isActive={isActiveRoute(location, ORDERS)}>
            <Package />
          </IconWrapper>
        );

      case 'customers':
        return (
          <IconWrapper isActive={isActiveRoute(location, CUSTOMER)}>
            <StarOutlineIcon />
          </IconWrapper>
        );

      case 'inbox':
        return (
          <IconWrapper isActive={isActiveRoute(location, INBOX)}>
            <AllInboxIcon />
          </IconWrapper>
        );

      case 'checkDocuments':
        return (
          <Badge
            color="primary"
            badgeContent={organizationActivities?.countCheckDocuments || 0}
            sx={{
              '& .MuiBadge-badge': {
                right: '2px',
              },
            }}
          >
            <IconWrapper isActive={isActiveRoute(location, CHECK_DOCUMENTS)}>
              <AssignmentTurnedInIcon />
            </IconWrapper>
          </Badge>
        );

      case 'payments-uploadFiles':
        return (
          <IconWrapper isActive={location.pathname?.includes(PAYMENTS_ROOT)}>
            <Money />
          </IconWrapper>
        );

      case 'uploads-uploadFiles':
        return (
          <IconWrapper isActive={isActiveRoute(location, UPLOAD_FILES)}>
            <CloudUploadIcon />
          </IconWrapper>
        );

      case 'reports':
        return (
          <IconWrapper isActive={isActiveRoute(location, REPORTS)}>
            <PresentationChart />
          </IconWrapper>
        );

      case 'employees':
        return (
          <IconWrapper isActive={location?.pathname?.includes(USERS)}>
            <IdentificationBadge />
          </IconWrapper>
        );

      case 'vehicles':
        return (
          <IconWrapper
            isActive={isActiveNestedRoute(location, [
              VEHICLES_LOGISTIC,
              VEHICLES_SALES,
            ])}
          >
            <Car />
          </IconWrapper>
        );

      case 'accessories':
        return (
          <IconWrapper isActive={location.pathname?.includes(ACCESSORIES)}>
            <Devices />
          </IconWrapper>
        );

      case 'tools':
        return (
          <IconWrapper isActive={location.pathname?.includes(TOOLS)}>
            <img src={PalletTruck} alt="" />
          </IconWrapper>
        );

      case 'paymentcards':
        return (
          <IconWrapper
            isActive={
              location.pathname?.includes(PAYMENTCARDS) ||
              location.pathname?.includes(AccessoryType.paymentCards)
            }
          >
            <Cardholder />
          </IconWrapper>
        );

      case 'supportunits':
        return (
          <IconWrapper isActive={isActiveRoute(location, SUPPORTUNIT)}>
            <Shapes />
          </IconWrapper>
        );

      default:
        return (
          <IconWrapper isActive={isActiveRoute(location, DASHBOARD_ALL)}>
            <ChartPieSlice />
          </IconWrapper>
        );
    }
  };

  const handleDragEnd = (result) => {
    if (
      !result?.destination ||
      result.source.index === result.destination.index ||
      result.source.droppableId !== result.destination.droppableId
    )
      return;

    const menuMappingById = {
      'droppable-nav-menu-0':
        isDashboardAll || isReportAll ? 'menuAllItems' : 'menuItems',
      'droppable-nav-menu-1': 'generalItems',
      'droppable-nav-menu-2': 'resourcesItems',
    };

    const menuType = menuMappingById[result.destination.droppableId];

    const currentArrangement = [...navMenuPreferences.bianta[menuType]];
    const [movedItem] = currentArrangement.splice(result.source.index, 1);
    currentArrangement.splice(result.destination.index, 0, movedItem);

    dispatch(
      appActions.setNavMenuPreferences({
        ...navMenuPreferences,
        bianta: {
          ...navMenuPreferences.bianta,
          [menuType]: currentArrangement,
        },
      }),
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <ScrollableSection>
        {MenuNavItems.map((menu, menuIndex) => (
          <Droppable
            droppableId={`droppable-nav-menu-${menuIndex}`}
            key={`menu-${menuIndex}`}
          >
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <OptionsWrapper
                  withoutBorder={menuIndex >= MenuNavItems.length - 1}
                >
                  <MenuTitleWrapper
                    isMinify={isMinify}
                    onClick={menu.onClick}
                    openSectionIndex={openSectionIndex}
                    theme={theme}
                    title={menu.title}
                    index={menuIndex}
                  >
                    {menu?.items?.map((item: string, itemIndex) => (
                      <If
                        condition={item !== 'employee'}
                        otherwise={
                          <If condition={displayEmployees}>
                            <Draggable
                              draggableId={`${item}-${menuIndex}-${itemIndex}`}
                              index={itemIndex}
                              key={`${item}-${menuIndex}-${itemIndex}`}
                            >
                              {(draggableProvided) => (
                                <div
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  style={{
                                    ...draggableProvided.draggableProps.style,
                                    top: 'auto !important',
                                    left: 'auto !important',
                                  }}
                                >
                                  <MainNavItem
                                    to={getToById(item)}
                                    text={getTitleById(item)}
                                    mode={mode}
                                    icon={getIconById(item)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          </If>
                        }
                      >
                        <Can
                          I="canview"
                          a={
                            item.indexOf('-uploadFiles') !== -1
                              ? 'uploadFiles'
                              : item
                          }
                        >
                          <Draggable
                            draggableId={`${item}-${menuIndex}-${itemIndex}`}
                            index={itemIndex}
                            key={`${item}-${menuIndex}-${itemIndex}`}
                          >
                            {(draggableProvided) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                style={{
                                  ...draggableProvided.draggableProps.style,
                                  top: 'auto !important',
                                  left: 'auto !important',
                                }}
                              >
                                <MainNavItem
                                  to={getToById(item)}
                                  text={getTitleById(item)}
                                  mode={mode}
                                  icon={getIconById(item)}
                                />
                              </div>
                            )}
                          </Draggable>
                        </Can>
                      </If>
                    ))}
                    {provided.placeholder}
                  </MenuTitleWrapper>
                </OptionsWrapper>
              </div>
            )}
          </Droppable>
        ))}
      </ScrollableSection>
    </DragDropContext>
  );
}

interface IconWrapperProps {
  isActive: boolean;
}

const IconWrapper = styled.div<IconWrapperProps>`
  img,
  svg {
    border-color: ${(props) =>
      props.isActive
        ? props.theme.primaryActiveColor
        : props.theme.gainsboro2} !important;
    color: ${(props) =>
      props.isActive
        ? props.theme.primaryActiveColor
        : props.theme.iconColor} !important;
  }
`;
