import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { ActionsProps, NestedMenuComponent } from 'app/components/NestedMenu';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
import { PopoverText } from 'hooks/Orders/useFormatOrderData';
import { Grid, Typography } from '@mui/material';
import { Icon } from 'app/components/Icon';
import {
  Package,
  Heart,
  CurrencyCircleDollar,
  FilePdf,
  Plus,
} from '@phosphor-icons/react';
import {
  ActionButtonWrapper,
  EmptyCenter,
} from '../../BulkActionsToolbar/components/CustomerOrdersPopover';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import NoOrdersIcon from 'assets/img/ORDORIA/Catalog/NoOrdersIcon.svg';
import { useCallback, useContext, useMemo } from 'react';
import { CatalogContext } from '../../..';
import { useToggleFavoriteItemsMutation } from 'common/services/customerApi';
import { useToaster } from 'hooks/useToaster';

export function ActionsMenuCell(params) {
  const { t } = useTranslation();
  const toast = useToaster();

  const {
    setFormatedSelectedProducts,
    setOpenCreateOrderDrawer,
    setFavoriteItems,
    isCustomer,
  } = useContext(CatalogContext);

  const handleCreateOrder = useCallback(() => {
    setFormatedSelectedProducts([
      {
        _product: params?.value?.cellData?.product,
        quantity: 1,
        discount: 0,
      },
    ]);
    setOpenCreateOrderDrawer(true);
  }, [
    params?.value?.cellData?.product,
    setFormatedSelectedProducts,
    setOpenCreateOrderDrawer,
  ]);

  const [toggleFavoriteItems] = useToggleFavoriteItemsMutation();

  const toggleFavorite = useCallback(async () => {
    try {
      const response = await toggleFavoriteItems({
        products: [params?.value?.cellData?.product?._id],
      }).unwrap();
      setFavoriteItems(response.result);
      toast(3000, 'success', t('toggle_fav.success'));
    } catch (error: any) {
      toast(3000, 'error', error?.data?.message);
    }
  }, [
    params?.value?.cellData?.product?._id,
    setFavoriteItems,
    t,
    toast,
    toggleFavoriteItems,
  ]);

  const menuActions = useMemo<ActionsProps[]>(() => {
    const actions: ActionsProps[] = [];

    actions.push({
      action: 'addToOrder',
      nested: true,
      onClick: () => {},
      actions: [
        {
          action: '',
          disableClick: true,
          element: (
            <div style={{ width: '100%' }}>
              <EmptyCenter>
                <NoDataComponents
                  viewMode={true}
                  Icon={NoOrdersIcon}
                  text={t('no_orders_yet_')}
                  IconButton={null}
                  textButton=""
                />
              </EmptyCenter>
            </div>
          ),
        },
        {
          action: 'createOrder',
          element: (
            <div style={{ padding: '0px 8px' }}>
              <ActionButtonWrapper onClick={() => handleCreateOrder()}>
                <Icon
                  icon={<Plus />}
                  color={themes?.ordoria?.primary}
                  size={12}
                />
                <Typography
                  fontSize="0.75rem"
                  fontWeight="500"
                  lineHeight="10px"
                  letterSpacing="0.14px"
                  color={themes?.ordoria?.primary}
                  sx={{
                    padding: '2px 0 0 0',
                  }}
                >
                  {t('orders.create_orders')}
                </Typography>
              </ActionButtonWrapper>
            </div>
          ),
        },
      ],
      element: (
        <PopoverText>
          <Grid
            sx={{
              margin: '0px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Icon icon={<Package />} size={18} />
            <Typography>{t('orders.title.addToOrder')}</Typography>
          </Grid>
        </PopoverText>
      ),
    });

    if (isCustomer) {
      actions.push({
        action: 'addToFavorites',
        nested: false,
        onClick: () => toggleFavorite(),
        element: (
          <PopoverText>
            <Grid
              sx={{
                margin: '0px 16px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <Icon icon={<Heart />} size={18} />
              <Typography>{t('add_to_favorites')}</Typography>
            </Grid>
          </PopoverText>
        ),
      });
    }

    actions.push({
      action: 'requestQuotation',
      nested: false,
      onClick: () => {},
      element: (
        <PopoverText>
          <Grid
            sx={{
              margin: '0px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Icon icon={<CurrencyCircleDollar />} size={18} />
            <Typography>{t('request_quotation')}</Typography>
          </Grid>
        </PopoverText>
      ),
    });

    actions.push({
      action: 'downloadPDF',
      nested: false,
      onClick: () => {},
      element: (
        <PopoverText>
          <Grid
            sx={{
              margin: '0px 16px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <Icon icon={<FilePdf />} size={18} />
            <Typography>{t('download_as_PDF')}</Typography>
          </Grid>
        </PopoverText>
      ),
    });

    return actions;
  }, [handleCreateOrder, isCustomer, t, toggleFavorite]);

  return (
    <NestedMenuComponent
      id={1}
      anchorOriginVertical="bottom"
      anchorOriginHorizontal="right"
      transformOriginVerical="top"
      transformOriginHorizontal="right"
      leftPosition={true}
      iconAction={
        <MoreHorizOutlinedIcon
          style={{
            width: '16px',
            height: '16px',
            color: themes?.default?.iconColor,
          }}
        />
      }
      iconOpen={
        <MoreHorizOutlinedIcon
          style={{
            width: '16px',
            height: '16px',
            color: themes?.ordoria?.primary,
          }}
        />
      }
      actions={menuActions}
    />
  );
}
