import { LeaveRequestType } from 'app/pages/Settings/UserInfo/components/UserTabs/LeaveRequestsTab/types';
import { JobRole } from 'common/types/JobRole';
import { Tag } from 'common/types/Tag';

export interface OrganizationState {
  organization: any | null | undefined;
  roles: any[];
  decks: any[];
  inactiveDecks: any[];
  deckCount: number;
  productFamilies: any[];
  contactAddresses: any[];
  supportUnits: any[];
  emergencyStopTypes: any[];
  personalStopReasons: any[];
  parkNumbers: any[];
  customerStatus: string[];
  customerTypes: any[];
  orderStatus: string[];
  creditScores: string[];
  deliveryRoles: string[];
  orderStatusLogistic: string[];
  fuelTypes: string[];
  vehicleCategories: any[];
  roundtripCodes: string[];
  activityCodes: string[];
  agentGroups: string[];
  toolsLicences: string[];
  agentContracts: string[];
  supportUnitsList: any[];
  activities: any;
  selectedWarehouse: string;
  leaveRequestTypes: LeaveRequestType[];
  connectors: any[];
  applicationScopePrerequisites: {
    [key: string]: string[];
  }[];
  applicationRestrictedFields: {
    application: string;
    module: string;
    fields: string[];
  }[];
  applicationSubModules: {
    application: string;
    module: string;
    subModules: string[];
  }[];
  companyDepartments: any[];
  claimReasons: any[];
  tags: Tag[];
  jobRoles: JobRole[];
}

export enum DECK_STATUS {
  active = 'active',
  inactive = 'inactive',
}
