import { useContext, useState } from 'react';
import styled from 'styled-components';
import { CreditCard } from '@phosphor-icons/react';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';
import ConfirmPaymentsModal from './ConfirmPaymentsModal';
import { ListPaymentContext } from '../ListComponent/ListPaymentProvider';
import { LIST } from 'app/components/TableComponent/constants';
import Button from '@mui/material/Button';

export const BulkActionsToolbar = () => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const gridContext = useManagedContext<IGridContext>('grid');

  const { selectedPaymentsIds, rowDataList } = useContext(ListPaymentContext);

  function handlePayment() {
    if (gridContext.viewMode === LIST) {
      setSelectedRows(
        rowDataList.filter(
          (row) =>
            selectedPaymentsIds?.includes(row?._id) &&
            row?.status?.paymentStatusData?.accountingPaymentStatus !== 'paid',
        ) || [],
      );
    } else {
      setSelectedRows(
        gridContext?.rowData
          ?.filter(
            (row) =>
              gridContext.selectedRows.includes(row.id) &&
              row.status.paymentStatusData?.accountingPaymentStatus !== 'paid',
          )
          ?.map((row) => row.status.paymentStatusData) || [],
      );
    }

    setDisplayConfirmationModal(true);
  }

  function handleClose() {
    setSelectedRows([]);
    setDisplayConfirmationModal(false);
  }

  return (
    <Wrapper>
      <ConfirmPaymentsModal
        open={displayConfirmationModal}
        onClose={handleClose}
        items={selectedRows}
      />

      <Button size="small" onClick={handlePayment}>
        <CreditCard style={{ marginRight: '1em' }} size={16} />
        <span> {t('customerPortal.documents.payOnlineMultiple')}</span>
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
