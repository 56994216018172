import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Box, Popover, Typography, Stack } from '@mui/material';
import { CustomIconButton } from 'app/components/Chat/components/CustomIconButton';
import { SidebarSimple, Browsers, X } from '@phosphor-icons/react';
import { StateSetter } from 'types';
import { ArrowLeft } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import If from 'app/components/If';
interface ModalHeaderProps {
  title?: string;
  onClose?: () => void;
  viewMode: 'sidebar' | 'floated';
  setViewMode: StateSetter<'sidebar' | 'floated'>;
  headerTitleProps?: any;
  hasArrowBack?: boolean;
  onBackClick?: (e?: any) => void;
  showBordersBottom?: boolean;
  content?: React.ReactNode;
  hasToggleView?: boolean;
}

export const ModalHeader = ({
  title,
  onClose,
  viewMode,
  setViewMode,
  headerTitleProps,
  hasArrowBack,
  onBackClick,
  content,
  hasToggleView = true,
}: ModalHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const popoverOpen = useMemo(() => {
    return !!anchorEl;
  }, [anchorEl]);

  function handleOpenPopover(e) {
    setAnchorEl(e.currentTarget || e.target);
  }

  function handleClosePopover() {
    setAnchorEl(null);
  }

  function handleChangeViewMode(mode: 'sidebar' | 'floated') {
    setViewMode(mode);
    handleClosePopover();
  }

  const props = headerTitleProps || {
    fontSize: '1rem',
    fontWeight: '700',
    lineHeight: 'normal',
  };

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      height="28px"
      sx={{ borderRadius: '0px !important' }}
    >
      <If condition={!!title}>
        <Stack direction="row" gap="20px" alignItems="center">
          <If condition={!!hasArrowBack}>
            {hasArrowBack && (
              <Icon onClick={onBackClick} icon={<ArrowLeft />} size="24px" />
            )}
          </If>
          <Typography {...props}>{title}</Typography>
        </Stack>
      </If>

      <HeaderToolBar noTitle={!!title}>
        {/* close and switch mode btn */}

        <If condition={!!content}>
          <Box marginRight="auto" paddingRight="12px" flex="1">
            {content}
          </Box>
        </If>

        <Box marginLeft="auto">
          {hasToggleView && (
            <CustomIconButton
              icon={
                viewMode === 'sidebar' ? (
                  <SidebarSimple weight="bold" />
                ) : (
                  <Browsers weight="bold" />
                )
              }
              iconStyles={
                viewMode === 'sidebar' ? { transform: 'rotate(180deg)' } : {}
              }
              isDropdown={true}
              size={16}
              baseSize={32}
              square={false}
              onClick={(e) => handleOpenPopover(e)}
            />
          )}
          {onClose && (
            <CustomIconButton
              icon={<X weight="bold" />}
              size={16}
              baseSize={32}
              square={false}
              onClick={onClose}
            />
          )}
        </Box>

        {/* view switch popover */}
        <Popover
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <ViewModesWrapper>
            <CustomIconButton
              icon={<Browsers weight="bold" />}
              size={16}
              baseSize={32}
              square={true}
              active={viewMode === 'floated'}
              onClick={() => handleChangeViewMode('floated')}
            />
            <CustomIconButton
              icon={<SidebarSimple weight="bold" />}
              iconStyles={{ transform: 'rotate(180deg)' }}
              size={16}
              baseSize={32}
              square={true}
              active={viewMode === 'sidebar'}
              onClick={() => handleChangeViewMode('sidebar')}
            />
          </ViewModesWrapper>
        </Popover>
      </HeaderToolBar>
    </Stack>
  );
};

const ViewModesWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 10%;
  border-radius: 0;
`;

const HeaderToolBar = styled.div<{ noTitle?: boolean }>`
  display: flex;
  align-items: center;
  flex: ${(props) => (!props.noTitle ? '1' : '')};
`;
