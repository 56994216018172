import { getLeaveRequestsDto } from '../Dtos/mobile/getLeaveRequestsDto';
import api from './api';

export const leaveRequestApi = api.injectEndpoints({
  endpoints: builder => ({
    getLeaveRequestTypes: builder.query<any, string>({
      query: urlQuery => ({
        url: `/leaverequesttype${urlQuery}`,
      }),
      providesTags: ['LeaveRequestTypes'],
    }),
    getLeaveRequests: builder.query<any, string>({
      query: urlQuery => ({
        url: `/leaverequest${urlQuery}`,
      }),
      providesTags: ['LeaveRequests'],
      transformResponse: (response: any, meta, arg) => {
        const urlParams = new URLSearchParams(arg);
        const dto = urlParams.get('dto');
        if (dto === 'mobile') return getLeaveRequestsDto(response);
        return response;
      },
    }),
    addLeaveRequest: builder.mutation<any, any>({
      query: body => ({
        url: `/leaverequest/`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LeaveRequests'],
    }),
    changeLeaveRequestStatus: builder.mutation<unknown, any>({
      query: body => ({
        url: `/leaverequest/${body.id}/changeStatus`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['LeaveRequests'],
    }),
    getLeaveRequestFacets: builder.query<any, string>({
      query: urlQuery => ({
        url: `/leaverequest/facets${urlQuery}`,
      }),
      providesTags: ['LeaveRequests'],
    }),
    getLeaveRequest: builder.query<any, string>({
      query: id => ({
        url: `/leaverequest/${id}`,
      }),
      providesTags: ['LeaveRequest'],
    }),
  }),
});

export const {
  useGetLeaveRequestTypesQuery,
  useGetLeaveRequestsQuery,
  useAddLeaveRequestMutation,
  useChangeLeaveRequestStatusMutation,
  useGetLeaveRequestFacetsQuery,
  useGetLeaveRequestQuery,
} = leaveRequestApi;

export default leaveRequestApi;
