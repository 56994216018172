import React, { useEffect, useRef, useState } from 'react';
import { IHeaderParams } from '@ag-grid-community/core';
import { Icon } from '../../Icon';
import { CaretDown, CaretUp, DotsThree, PushPin } from '@phosphor-icons/react';
import { themes } from '../../../../styles/theme/themes';
import { Menu, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../../../styles/theme/slice/selectors';
import MenuItem from '@mui/material/MenuItem';
import { IColDef, IGridContext } from '../types';
import { useManagedContext } from '../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';
import { StateSetter } from 'types';
import If from 'app/components/If';
import { useTheme } from 'common/hooks/useTheme';

// @ts-ignore
interface HeaderComponentProps extends IHeaderParams {
  displayName: string;
  enableSorting: boolean;
  column: {
    isSortAscending: () => boolean;
    colDef: IColDef;
    isSortDescending: () => boolean;
    addEventListener: (event: string, callback: () => void) => void;
    removeEventListener: (event: string, callback: () => void) => void;
  };
}

const HeaderComponent: React.FC<HeaderComponentProps> = (props) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const [sorted, setSorted] = useState<'asc' | 'desc' | null>(null);
  const [hovered, setHovered] = useState(false);

  const headerRef = useRef(null);

  useEffect(() => {
    const onSortChanged = () => {
      if (props.column.isSortAscending()) {
        setSorted('asc');
      } else if (props.column.isSortDescending()) {
        setSorted('desc');
      } else {
        setSorted(null);
      }
    };

    props.column.addEventListener('sortChanged', onSortChanged);
    return () => props.column.removeEventListener('sortChanged', onSortChanged);
  }, [props.column]);

  const onSortRequested = (event: React.MouseEvent) => {
    props.setSort(
      sorted === null ? 'asc' : sorted === 'asc' ? 'desc' : null,
      event.shiftKey,
    );
  };

  return (
    <Stack
      ref={headerRef}
      direction="row"
      alignItems="center"
      width="100%"
      height="100%"
      justifyContent="space-between"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      paddingX="16px"
    >
      <Stack direction="row" gap="2px" alignItems="center">
        {props.column.colDef.pinned && !props.column.colDef.lockPinned && (
          <Icon
            icon={<PushPin />}
            style={{
              paddingRight: '4px',
            }}
            color={theme.primaryActiveColor}
          />
        )}
        <span>
          {props.column.colDef.CustomHeaderComponent
            ? props.column.colDef.CustomHeaderComponent
            : t(props.displayName)}
        </span>
        {props.enableSorting && (
          <IconButton
            size="small"
            onClick={props.enableSorting ? onSortRequested : undefined}
            sx={{
              borderRadius: '4px',
              width: '16px',
              height: '16px',
              marginLeft: '8px',
            }}
          >
            <Stack>
              <Icon
                icon={<CaretUp />}
                weight="bold"
                size={9}
                color={
                  sorted === 'asc'
                    ? themes?.default?.black
                    : themes?.default?.iconColor
                }
                style={{ transform: 'translateY(2px)' }}
              />
              <Icon
                icon={<CaretDown />}
                weight="bold"
                size={9}
                color={
                  sorted === 'desc'
                    ? themes?.default?.black
                    : themes?.default?.iconColor
                }
              />
            </Stack>
          </IconButton>
        )}
      </Stack>
      {!props.column.colDef.lockPinned && (
        <PinningMenu
          headerRef={headerRef}
          hovered={hovered}
          setHovered={setHovered}
          currentColumnField={props.column.colDef.field!}
          currentPin={props.column.colDef.pinned}
        />
      )}
    </Stack>
  );
};

function PinningMenu({
  headerRef,
  hovered,
  setHovered,
  currentColumnField,
  currentPin,
}: {
  headerRef: any;
  hovered: boolean;
  setHovered: StateSetter<boolean>;
  currentColumnField: string;
  currentPin: 'left' | 'right' | boolean | null | undefined;
}) {
  const theme = useSelector(selectTheme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const grid = useManagedContext<IGridContext>('grid');
  const { t } = useTranslation();
  return (
    <>
      <IconButton
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          setHovered(false);
          setAnchorEl(headerRef.current);
        }}
        sx={{
          borderRadius: '4px',
          width: '16px',
          height: '16px',
          padding: '0 !important',
          opacity: !!hovered ? 1 : 0,
          marginLeft: '8px',
        }}
      >
        <DotsThree size={14} color={themes.default.grey2} weight="bold" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={(e) => {
          // @ts-ignore
          e.stopPropagation();
          handleClose();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: headerRef.current?.getBoundingClientRect().width,
            minWidth: '120px',
            marginTop: '2px',
          },
        }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
        sx={{
          borderRadius: '4px',
          boxShadow: themes.default.menuBoxShadow,
        }}
        elevation={1}
      >
        <If
          condition={!!currentPin}
          otherwise={
            <>
              <MenuItem
                onKeyDown={(e) => e.stopPropagation()}
                sx={{
                  gap: '4px',
                  '&:hover': {
                    background: `${theme.lightBackground}`,
                  },
                }}
                onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                  e.stopPropagation();
                  handleClose();
                  grid.api.pinColumn(currentColumnField, 'left');
                }}
              >
                {t('pin.left')}
              </MenuItem>
              <MenuItem
                onKeyDown={(e) => e.stopPropagation()}
                sx={{
                  gap: '4px',
                  '&:hover': {
                    background: `${theme.lightBackground}`,
                  },
                }}
                onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                  e.stopPropagation();
                  handleClose();
                  grid.api.pinColumn(currentColumnField, 'right');
                }}
              >
                {t('pin.right')}
              </MenuItem>
            </>
          }
        >
          <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            sx={{
              gap: '4px',
              '&:hover': {
                background: `${theme.lightBackground}`,
              },
            }}
            onClick={(e: React.MouseEvent<HTMLLIElement>) => {
              e.stopPropagation();
              handleClose();
              grid.api.pinColumn(currentColumnField, undefined);
            }}
          >
            {t('common.unpin')}
          </MenuItem>
          <MenuItem
            onKeyDown={(e) => e.stopPropagation()}
            sx={{
              gap: '4px',
              '&:hover': {
                background: `${theme.lightBackground}`,
              },
            }}
            onClick={(e: React.MouseEvent<HTMLLIElement>) => {
              e.stopPropagation();
              handleClose();
              if (currentPin === 'right')
                grid.api.pinColumn(currentColumnField, 'left');
              else grid.api.pinColumn(currentColumnField, 'right');
            }}
          >
            {currentPin === 'right' ? t('pin.left') : t('pin.right')}
          </MenuItem>
        </If>
      </Menu>
    </>
  );
}

export default HeaderComponent;
