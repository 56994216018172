import { Avatar, CircularProgress } from '@mui/material';
import { stringAvatar } from 'app/helpers/helpers';
import { UserStatusDot } from '../AppHeader/components/UserStatusDot';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';

interface Props {
  showStatus?: boolean;
  size?: number;
  name?: string;
  loading?: boolean;
  border?: string;
  fontSize?: string;
}

const UserAvatar = ({
  showStatus = false,
  size = 22,
  name = '',
  loading = false,
  border = '',
  fontSize = '0.625rem',
}: Props) => {
  const theme = useSelector(selectTheme);
  const authUser: any = useSelector(selectAuthUser);

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        background: theme.primary,
        fontFamily: 'Helvetica !important',
        fontSize: fontSize,
        color: theme.accordionWhiteBg,
        position: 'relative',
        overflow: 'visible',
        border: border,
      }}
    >
      {loading && <CircularProgress color="inherit" size="10px" />}
      {!loading && <>{stringAvatar(`${!name ? authUser.fullName : name}`)}</>}

      {showStatus && <UserStatusDot size={4} status="online" />}
    </Avatar>
  );
};

export default UserAvatar;
