import { DrawerComponent } from 'app/components/DrawerComponent';
import { ClaimForm } from './components/ClaimForm';
import { useTranslation } from 'react-i18next';

interface ClaimDrawerProps {
  open: boolean;
  handleClose: () => void;
  customer?: any;
  order?: any;
}

export function ClaimDrawer({
  open,
  handleClose,
  customer,
  order,
}: ClaimDrawerProps) {
  const { t } = useTranslation();

  return (
    <DrawerComponent
      title={t('claims.emptyState.button')}
      open={open}
      handleClose={handleClose}
    >
      <ClaimForm handleClose={handleClose} customer={customer} order={order} />
    </DrawerComponent>
  );
}
