import styled from 'styled-components';
import NoRoundtrip from 'assets/img/roundTrips/no-data-roundtrip.svg';
import { useTranslation } from 'react-i18next';
import React, {
  Dispatch,
  FC,
  ReactElement,
  SetStateAction,
  useState,
} from 'react';
import { EnhancedTableToolbarProps } from '../../data/types';
import { Modals } from './Components/Modals';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import { roundtripActions } from 'common/store/roundtrips';
import { useDispatch } from 'react-redux';
interface Props {
  roundTripCopy?: any;
  EnhancedTableToolbar?: ReactElement<
    EnhancedTableToolbarProps,
    FC<EnhancedTableToolbarProps>
  >;
  handleClickUpload?: (event: React.MouseEvent<HTMLElement>) => void;
  setAnchorElUpload?: Dispatch<SetStateAction<HTMLElement | null>>;
  anchorElUpload?: HTMLElement | null;
  setAnchorElManual?: any;
  anchorElManual?: HTMLElement | null;
  Icon?: string;
  setAnchorElActivate?: any;
  triggerByMode?: any;
  roundTripsByMode?: any;
  isLoading?: boolean;
  viewMode?: boolean;
  allRoundtrip?: boolean;
  height?: string;
}
export const NoData: React.FC<Props> = ({
  EnhancedTableToolbar,
  Icon,
  setAnchorElActivate,
  viewMode,
  roundTripCopy,
  allRoundtrip,
  height,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(roundtripActions.setClusterId(''));
    if (setAnchorElActivate) {
      setAnchorElActivate(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Background height={height}>
      {EnhancedTableToolbar && EnhancedTableToolbar}
      <CenterDiv>
        <NoDataComponents
          viewMode={viewMode}
          Icon={Icon || NoRoundtrip}
          open={open}
          handleClick={handleClick}
          text={
            viewMode
              ? t('roundtrips.roundtrips.empty_state.view_mode')
              : t('roundtrips.roundtrips.empty_state.scheduled')
          }
          IconButton={
            viewMode ? (
              <KeyboardArrowUpOutlinedIcon
                sx={{
                  marginLeft: '5px',
                  transition: 'color 0.3s ease-in-out',
                  rotate: anchorEl ? '0deg' : '180deg',
                }}
              />
            ) : null
          }
          textButton={
            !viewMode
              ? t('cluster.add_roundtrips')
              : t('cluster.create_roundtrips')
          }
        />
        <Modals
          anchorEl={anchorEl}
          handleClose={handleClose}
          roundTripCopy={roundTripCopy}
          allRoundtrip={allRoundtrip}
        />
      </CenterDiv>
    </Background>
  );
};

interface BackgroundProps {
  height?: string;
}

const Background = styled.div<BackgroundProps>`
  background: ${(props) => props.theme.roundtripsGrayBackGround};
  height: ${(props) =>
    props?.height
      ? props.height
      : `calc(100vh - ${props.theme.topBarHeight} - ${props.theme.toolBarHeight} - ${props.theme.barWidth}px)`};
  flex-grow: 1;
  position: relative;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex !important;
  flex-direction: column !important;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* text-align: center; */

  img {
    margin: 0 auto;
  }
`;
