import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Avatar, Tooltip, Typography } from '@mui/material';
import If from 'app/components/If';
import { stringAvatar } from 'app/helpers/helpers';
import { isEmpty } from 'lodash';

function ReportedByCell(params: CustomCellRendererProps) {
  const reportedBy = params.value.reportedByData;
  const pictureUrl = params.value.pictureUrlData;

  return (
    <If
      condition={!isEmpty(pictureUrl)}
      otherwise={
        <Tooltip title={reportedBy}>
          <Avatar
            sx={{
              width: '24px',
              height: '24px',
              cursor: 'pointer',
            }}
          >
            <Typography fontSize="0.75rem">
              {stringAvatar(reportedBy)}
            </Typography>
          </Avatar>
        </Tooltip>
      }
    >
      <Tooltip title={reportedBy}>
        <Avatar
          src={pictureUrl}
          sx={{
            width: '24px',
            height: '24px',
            cursor: 'pointer',
          }}
        />
      </Tooltip>
    </If>
  );
}

export default ReportedByCell;
