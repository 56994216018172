import {
  Box,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
  styled,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useGrid } from '../../hooks';
import { visuallyHidden } from '@mui/utils';
import React, { useMemo, useEffect, useRef, useCallback } from 'react';
import { GridRow } from '../styles';
import If from 'app/components/If';
import { Checkbox } from 'app/components/CustomCheckbox';
import { useTranslation } from 'react-i18next';
import { Icon } from 'app/components/Icon';
import { CaretDown, CaretUp, Info } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { Column } from '../../types';

export const GridHead = () => {
  const {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    columns,
    selected,
    setSelected,
    totalRows,
    rows,
    withCheckbox,
    columnWidths,
    setColumnWidths,
    withWarningIcon,
  } = useGrid();
  const { t } = useTranslation();

  const columnRefs = useRef<React.RefObject<HTMLTableCellElement>[]>([]);

  useEffect(() => {
    columnRefs.current = columns.map(
      (_, i) => columnRefs.current[i] ?? React.createRef(),
    );
  }, [columns]);

  useEffect(() => {
    const newWidths = columns.reduce((acc, col, index) => {
      if (col.disableRow) return acc;

      const headerWidth = columnRefs.current[index]?.current?.offsetWidth || 0;
      acc[col.id] = headerWidth;
      return acc;
    }, {});

    setColumnWidths(newWidths);
  }, [columns, setColumnWidths, columnRefs.current]);

  const getStickyLeft = (index: number) => {
    let offset = 0;
    for (let i = 0; i < index; i++) {
      const col = columns[i];
      console.log('columnWidthscolumnWidths', col?.id, columnWidths[col?.id]);
      offset += columnWidths[col?.id] || 0;
    }
    if (withCheckbox) {
      offset += themes.default.gridCheckboxWidth;
    }
    return offset;
  };
  const getStickyRight = (index: number) => {
    let offset = 0;
    for (let i = index + 1; i < columns.length; i++) {
      const col = columns[i];
      offset += columnWidths[col?.id] || 0;
    }

    // if (withCheckbox) {
    //   offset += 50; //actions
    // }

    return offset;
  };

  const handleSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === '';
      setOrder(isAsc ? '-' : '');
      setOrderBy(property);
    },
    [order, orderBy, setOrder, setOrderBy],
  );

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id ?? n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const direction = useMemo(() => {
    return order === '' ? 'asc' : 'desc';
  }, [order]);

  const getCellContent = useCallback(
    (column: Column) => {
      const sortField = column.sortField || column.id;

      return (
        <If
          condition={!column.disableSort}
          otherwise={
            <Typography
              fontSize="0.875rem"
              fontWeight={500}
              textTransform="uppercase"
            >
              {t(column.label)}
              {column.hasInfoIcon && (
                <Tooltip
                  title={column.tooltipText ? t(column.tooltipText) : ''}
                  arrow
                >
                  <IconButton>
                    <Icon
                      icon={<Info />}
                      weight="bold"
                      size={16}
                      color={`${themes?.default?.iconColor}99`}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
          }
        >
          <TableSortLabel
            active={true}
            direction={orderBy === sortField ? direction : 'asc'}
            onClick={() => handleSort(sortField)}
            IconComponent={() => (
              <CustomSortIconWrapper>
                <Icon
                  icon={<CaretUp />}
                  weight="bold"
                  size={12}
                  color={
                    orderBy === sortField && order === ''
                      ? themes?.default?.black
                      : `${themes?.default?.iconColor}99`
                  }
                />
                <Icon
                  icon={<CaretDown />}
                  weight="bold"
                  size={12}
                  color={
                    orderBy === sortField && order === '-'
                      ? themes?.default?.black
                      : `${themes?.default?.iconColor}99`
                  }
                />
              </CustomSortIconWrapper>
            )}
          >
            <Typography
              fontSize="0.875rem"
              fontWeight={500}
              textTransform="uppercase"
            >
              {t(column.label)}
              {column.hasInfoIcon && (
                <Tooltip
                  title={column.tooltipText ? t(column.tooltipText) : ''}
                  placement="top"
                  arrow
                >
                  <IconButton>
                    <Icon
                      icon={<Info />}
                      weight="bold"
                      size={16}
                      color={`${themes?.default?.iconColor}99`}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
            {orderBy === sortField ? (
              <Box component="span" sx={visuallyHidden}>
                {order === '-' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </If>
      );
    },
    [direction, handleSort, order, orderBy, t],
  );

  if (!totalRows) {
    return null;
  }

  return (
    <Head>
      <GridRow>
        <If condition={!!withCheckbox}>
          <TableCell
            padding="none"
            className="checkbox-cell"
            sx={{
              paddingLeft: withWarningIcon ? '24px !important' : 'auto',
            }}
          >
            <Checkbox
              color="primary"
              indeterminate={
                selected?.length > 0 && selected?.length < rows?.length
              }
              checked={totalRows > 0 && selected?.length === rows?.length}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all',
              }}
              sx={{
                padding: 0,
              }}
            />
          </TableCell>
        </If>
        {columns?.map((column, index) => {
          const sortField = column.sortField || column.id;

          if (column.disableRow) {
            return null;
          }
          return (
            <TableCell
              ref={columnRefs.current[index]}
              key={column.id}
              sortDirection={orderBy === sortField ? direction : false}
              className={column.className}
              sx={{
                ...column.cellStyle,
                left:
                  column.sticky === 'left'
                    ? `${getStickyLeft(index)}px`
                    : undefined,
                right:
                  column.sticky === 'right'
                    ? `${getStickyRight(index)}px`
                    : undefined,
                zIndex: column.sticky ? '3 !important' : 'auto',
              }}
            >
              {getCellContent(column)}
            </TableCell>
          );
        })}
      </GridRow>
    </Head>
  );
};

const Head = styled(TableHead)`
  position: relative;
  z-index: 4;
`;

const CustomSortIconWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 16px;
  margin: 0 0 0 8px;
  position: relative;

  div {
    height: 6px;
    position: absolute;
    left: 0;
  }

  div:first-child {
    top: -7px;
  }

  div:last-child {
    bottom: 11px;
  }
`;
