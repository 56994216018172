import { Collapse } from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { themes } from 'styles/theme/themes';
import { Category } from '../..';
import { CategoryTitle } from '../../../Category/Title';
import styled from 'styled-components';
// import { FunctionalGridListItem } from '../FunctionalGridListItem';
import { AgGridReact } from '@ag-grid-community/react';
import { CatalogContext } from 'app/pages/Ordoria/Catalog';
import { useLazyGetProductsByCategoryQuery } from 'common/services/productApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { getProcessedProductFunction } from 'app/pages/Ordoria/Catalog/helpers';
import { LIST } from 'app/components/TableComponent/constants';
import ManagedGridList from 'app/components/ManagedGridList';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { IPricingGroupRowData } from 'app/pages/PricingGroups/Components';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';

export const ListCollapsableCardCategory = ({
  category,
  expandedByDefault = false,
}: {
  category: Category;
  expandedByDefault?: boolean;
}) => {
  /* ------------------------------ Use States ------------------------------ */
  const [expanded, setExpanded] = useState<boolean>(expandedByDefault || false);
  const [sort, setSort] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [processedProducts, setProcessedProducts] = useState<any[]>([]);
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState<
    any[]
  >([]);

  /* ------------------------------- Use Refs ------------------------------- */
  const gridRef = useRef<AgGridReact>(null);

  /* -------------------------------- Contexts ------------------------------ */
  const gridContext =
    useManagedContext<IGridContext<IPricingGroupRowData>>('grid');

  const { selectedProducts, setSelectedProducts, viewMode } =
    useContext(CatalogContext);

  const {
    columnDefs,
    queryParams: { order, orderBy },
  } = gridContext;

  /* --------------------------------- Hooks -------------------------------- */
  const urlQuery = useQueryParams({
    sort: `${order}${orderBy}` || 'code',
    page: page,
    limit: limit,
    order: order,
  });

  /* ------------------------------ API Calls ------------------------------- */
  const [triggerGetProducts, { data: products, isLoading, isFetching }] =
    useLazyGetProductsByCategoryQuery();

  /* ------------------------------ Use Effects ----------------------------- */
  useEffect(() => {
    if (isLoading || isFetching) {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = isFetching;
      });
      return;
    }
    if (!products || !products.docs) return;
    const processedProductsData = processedProducts;
    gridContext.updateDataWithFunction((prev) => {
      prev.responseData = products.docs;
      prev.loading = false;
      prev.rowData = processedProductsData!;
    });
  }, [isFetching, products?.docs, processedProducts]);

  useEffect(() => {
    if (!expanded || !category?._id || !!sort) return;
    triggerGetProducts({
      categoryId: category?._id || '',
      urlQuery: urlQuery,
    });
  }, [expanded, category, urlQuery]);

  useEffect(() => {
    if (!expanded || !sort) return;
    triggerGetProducts({
      categoryId: category?._id || '',
      urlQuery: `?sort=${sort}&page=1&limit=${processedProducts?.length}`,
    });
  }, [sort]);

  useEffect(() => {
    if (!products?.docs || !category) return;
    if (!sort) {
      setProcessedProducts((prevState) => [
        ...prevState,
        ...(getProcessedProductFunction(products?.docs, category) || []),
      ]);
    } else {
      setProcessedProducts(
        getProcessedProductFunction(products?.docs, category) || [],
      );
    }
  }, [products, category, sort]);

  /**
   * Add selected products of current category to
   * selectedProducts.categories object
   *
   * Each category has an object in selectedProducts.categories
   * with key equal to its id and the value is an array of product ids.
   */
  useEffect(() => {
    if (!category?._id) return;
    setSelectedProducts((prevState) => ({
      ...prevState,
      categories: {
        ...(prevState?.categories || {}),
        [category._id]: selectedCategoryProducts,
      },
    }));
  }, [selectedCategoryProducts]);

  /**
   * Unselect selected products after clicking
   * on dismiss button in BulkActionsToolbar component.
   */
  useEffect(() => {
    if (!gridRef?.current || viewMode !== LIST) return;
    const gridSelected = gridRef.current.api.getSelectedRows().map((r) => r.id);

    if (
      gridSelected.length !==
      selectedProducts?.categories?.[category?._id || '']?.length
    ) {
      gridRef.current.api.deselectAll();
    }
  }, [selectedProducts]);

  /* ------------------------------- Functions ------------------------------ */
  const handleLoadMoreRows = useCallback(() => {
    if (processedProducts?.length < (category?.productsCount || 0)) {
      setPage((prev) => prev + 1);
    }
  }, [processedProducts, category]);

  const handleCategoryExpand = () => {
    setExpanded((prevState) => {
      return !prevState;
    });
  };

  const handleSortChange = useCallback(
    (value) => {
      setSort(value);
    },
    [setSort],
  );

  const handleSelectionChange = useCallback(
    (value) => {
      setSelectedCategoryProducts(value);
    },
    [setSelectedCategoryProducts],
  );

  /* ------------------------------------------------------------------------ */
  return (
    <GridWrapper>
      <TitleContainer headerBackground={themes?.default?.roundtripGreyBg}>
        <CategoryTitle
          onClick={handleCategoryExpand}
          category={category}
          itemsCount={category?.productsCount || 0}
          expanded={expanded}
        />
      </TitleContainer>

      <Collapse in={expanded}>
        <BodyContainer spacingTop={8}>
          <ManagedGridList
            gridRef={gridRef}
            rowData={processedProducts}
            columnDefs={columnDefs}
            loading={isLoading || isFetching}
            filtered={false}
            setSort={handleSortChange}
            onScrollEndReached={handleLoadMoreRows}
            onSelectionChanged={handleSelectionChange}
          />
        </BodyContainer>
      </Collapse>
    </GridWrapper>
  );
};

export const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const TitleContainer = styled.div<{ headerBackground }>`
  background: ${(props) => props.headerBackground};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const BodyContainer = styled.div<{ spacingTop: number }>`
  padding-top: ${(props) => props.spacingTop}px;

  .ag-header-cell-comp-wrapper > div {
    padding: 0px 16px !important;
  }
`;
