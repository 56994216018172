import React from 'react';
import { createData } from 'utils/pages/ordoria/orders';
import { Tooltip } from '@mui/material';
import { StatusOrder } from '../../app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';
import StopIcon from 'app/components/StopIcon';
import OrderPriority from 'app/components/OrderPriority';
import { themes } from 'styles/theme/themes';
import { useTranslation } from 'react-i18next';
import { IconSupportUnit } from 'app/pages/AddRoundTrips/data/RoundTripData/components/SupportUnit';
import moment from 'moment';
import { NestedMenuComponent } from 'app/components/NestedMenu';
import { PopoverEl } from 'app/pages/AddRoundTrips/data/RoundTripData/components/Popover';
import { useUpdateOrderMutation } from 'common/services/orderApi';
import { updateOrderFunction } from 'app/components/RoundTrips/function/ApiFunctions';
import populateOrderStatusActions from '../Orders/populateOrderStatusActions';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import styled from 'styled-components';
import { useToaster } from 'hooks/useToaster';
import If from 'app/components/If';
import CheckIcon from '@mui/icons-material/Check';
import { selectConfiguration } from 'common/store/organization/selectors';
import { checkUserAcl } from 'common/helpers/acl';
import { getAddressLabel } from 'app/helpers/helpers';
import { AddressIcon } from 'app/components/AddressIcon';
import { useNavigate } from 'react-router-dom';
import { AccountingBalanceValue } from '../../app/pages/Customers/components/AccountingBalance';
import { useGetUserAcl } from 'common/hooks/useGetUserAcl';

interface Props {
  orders: any;
  setRows: any;
  setSelectedOrder: any;
  setOpenDrawer: any;
  handleOpenModal: any;
  onDisplayMap: any;
  queryParams: any;
  OrdoriaOptionsMenu?: any;
}

export const useFormatOrderData = ({
  orders,
  setRows,
  setSelectedOrder,
  setOpenDrawer,
  handleOpenModal,
  onDisplayMap,
  queryParams,
  OrdoriaOptionsMenu,
}: Props) => {
  const toast = useToaster();
  const authUser = useSelector(selectAuthUser);
  const configuration = useSelector(selectConfiguration);
  const { isCustomer } = useGetUserAcl();
  const { t } = useTranslation();
  const handleSelectOrder = (orderObj) => {
    setSelectedOrder(orderObj);
    setOpenDrawer();
  };
  const handleDisplayMap = (stop) => {
    if (onDisplayMap) {
      onDisplayMap(stop);
    }
  };

  const navigate = useNavigate();
  function openViewOrder(orderId) {
    navigate(`${orderId}`);
  }

  const [UpdateOrders] = useUpdateOrderMutation();
  const DATE_FORMAT = 'D MMM YYYY';
  React.useEffect(() => {
    const rowsDatas: any = [];

    for (const orderObj of orders) {
      const changeOrderStatus = async (status: string) => {
        await updateOrderFunction(
          orderObj.id,
          {
            status: status,
          },
          UpdateOrders,
          queryParams,
        );
        toast(3000, 'success', 'order_status_success');
      };
      const formattedUpdatedAt = window
        .dayjs(orderObj.updatedAt)
        .format(DATE_FORMAT);

      rowsDatas.push({
        data: createData(
          { value: orderObj.id },
          {
            value: (
              <If condition={orderObj.status === 'delivered'} otherwise={null}>
                <Tooltip title={t('orders.status.delivered')}>
                  <CheckIcon sx={{ color: themes?.default?.fruitSaladGreen }} />
                </Tooltip>
              </If>
            ),
            display: orderObj.status === 'delivered',
          },
          {
            value: (
              // <Button
              //   size="small"
              //   variant="outlined"
              //   sx={{ width: '110px' }}
              //   onClick={() => {
              //     handleSelectOrder(orderObj);
              //   }}
              // >
              //   {orderObj.code}
              // </Button>
              <StopIcon
                type={orderObj.type}
                tooltipMessage={t(`common.${orderObj?.type}`)}
              />
            ),
            sort: orderObj.code,
          },
          {
            value: React.createElement('span', null, orderObj.roundtripCode),
          },
          {
            value: React.createElement(
              'span',
              null,
              orderObj.roundtripPosition,
            ),
          },
          {
            value: (
              <span
                onClick={(e) => {
                  e?.stopPropagation();
                  openViewOrder(orderObj.id);
                }}
              >
                {orderObj.code}
              </span>
            ),
          },
          {
            value: <OrderPriority priority={orderObj?.priority} />,
            sort: orderObj?.priorit,
          },
          {
            value: (
              <Tooltip title={orderObj._deck?.code} placement="top" arrow>
                <span>{orderObj._customer?.code}</span>
              </Tooltip>
            ),
            sort: orderObj._customer?.code,
          },

          {
            value: (
              <Tooltip title={orderObj?._customer?.publicName}>
                <ElipsisText>{orderObj?._customer?.publicName}</ElipsisText>
              </Tooltip>
            ),
            sort: orderObj?._customer?.publicName,
          },
          {
            value: (
              <Tooltip title={orderObj?._deck?.location?.city}>
                <ElipsisText>{orderObj?._deck?.location?.city}</ElipsisText>
              </Tooltip>
            ),
          },
          {
            value: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Tooltip title={getAddressLabel(orderObj?._deck?.location)}>
                  <ElipsisText>
                    <AddressIcon
                      onClick={() => {
                        handleDisplayMap(orderObj);
                      }}
                    />
                    {getAddressLabel(orderObj?._deck?.location)}
                  </ElipsisText>
                </Tooltip>
              </div>
            ),
          },
          {
            value: <div>{orderObj.countLines}</div>,
            sort: orderObj.countLines,
          },
          {
            value: React.createElement(
              'span',
              null,
              orderObj._customer?._salesman
                ? `${orderObj._customer?._salesman?.firstName} ${orderObj._customer?._salesman?.lastName}`
                : 'N/A',
            ),
          },
          {
            value: <div>{orderObj?.countPackages} </div>,
          },
          {
            value: <div>{orderObj?.totalWeight?.toFixed(2)}</div>,
          },
          {
            value: <>{orderObj?.discount}%</>,
          },
          {
            value: <div>{orderObj?._createdBy?.fullName}</div>,
          },
          {
            value: (
              <div>{moment(orderObj?.createdAt).format('MMM DD, YYYY')}</div>
            ),
          },
          {
            value: (
              <div>{moment(orderObj.deliveryDate).format('DD/MM/YYYY')}</div>
            ),
          },
          {
            value: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {orderObj?.supportUnitsRequestedCapacity}
                <IconSupportUnit
                  id={orderObj?.['_id']}
                  widthButton={37}
                  supportUnits={orderObj?.supportUnits}
                  edit={false}
                />
              </div>
            ),
          },
          {
            value: React.createElement(
              'span',
              null,
              orderObj.supportUnitsRequestedCapacity,
            ),
            sort: orderObj.supportUnitsRequestedCapacity,
          },
          {
            value: React.createElement(
              'span',
              null,
              Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(orderObj.totalAmount),
            ),
            sort: orderObj.totalAmount,
          },
          {
            value: React.createElement(
              'span',
              null,
              Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(orderObj.totalAmountTax),
            ),
            sort: orderObj.totalAmountTax,
          },
          {
            value: (
              <div>{orderObj.cache?.stats?.amountPerWeight?.toFixed(2)}</div>
            ),
          },
          {
            value: React.createElement(
              'span',
              null,
              Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
              }).format(orderObj.amountRequested),
            ),
          },
          {
            value: (
              <AccountingBalanceValue
                forceColor={
                  orderObj?._customer?.accountingBalance?.total > 0 &&
                  orderObj?._customer?.accountingBalance?.overdue === 0
                    ? 'warning'
                    : null
                }
                value={orderObj?._customer?.accountingBalance?.total}
              />
            ),
          },
          {
            value: (
              <AccountingBalanceValue
                isOverdue
                value={orderObj?._customer?.accountingBalance?.overdue}
              />
            ),
          },
          {
            value: (
              <AccountingBalanceValue
                value={orderObj?._customer?.accountingBalance?.due}
                forceColor="default"
              />
            ),
          },
          {
            value: React.createElement('span', null, formattedUpdatedAt),
            sort: orderObj.updatedAt,
          },
          {
            value: (
              <StatusOrder
                status={orderObj.status}
                deliveryDistance={orderObj?.deliveryInfo?.distance}
              />
            ),
            sort: orderObj.status,
          },
          {
            value: (
              // TEMPORARLY HIDE 3 DOTS OPTIONS FOR CUSTOMERS
              <If condition={!isCustomer()}>
                <>
                  {OrdoriaOptionsMenu ? (
                    OrdoriaOptionsMenu(orderObj)
                  ) : (
                    <div
                      style={{ width: '100%' }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <NestedMenuComponent
                        id={orderObj?.id}
                        leftPosition={true}
                        actions={[
                          {
                            action: 'status',
                            nested: false,
                            onClick: () => {
                              handleSelectOrder(orderObj);
                            },
                            element: (
                              <PopoverText>
                                <PopoverEl style={{ width: '100%' }}>
                                  <StyledPopoverLabel>
                                    {t('orders.view_details')}
                                  </StyledPopoverLabel>
                                </PopoverEl>
                              </PopoverText>
                            ),
                          },
                          {
                            action: 'assign_roundtrip',
                            nested: false,
                            onClick: () => {
                              handleOpenModal(orderObj._id, orderObj.code);
                            },
                            actions: [
                              { elements: null, action: 'assign_roundtrip' },
                            ],
                            element: checkUserAcl(authUser, [
                              'adminOrders',
                              'manageRoundtripsCluster',
                            ]) ? (
                              <PopoverText>
                                <PopoverEl style={{ width: '100%' }}>
                                  <StyledPopoverLabel>
                                    {t('orders.add_to_roundtrip')}
                                  </StyledPopoverLabel>
                                </PopoverEl>
                              </PopoverText>
                            ) : null,
                          },
                          {
                            seperator: true,
                            action: 'delete',
                            element: (
                              <div
                                className="seperator"
                                style={{
                                  width: '150px',
                                }}
                              />
                            ),
                          },
                          {
                            action: 'Change_Status',
                            nested: true,
                            actions: populateOrderStatusActions({
                              changeStatus: changeOrderStatus,
                              authUser,
                              configuration,
                            }),
                            element: checkUserAcl(authUser, [
                              'adminOrders',
                              'manageRoundtripsCluster',
                            ]) ? (
                              <PopoverText>
                                <PopoverEl style={{ width: '100%' }}>
                                  <StyledPopoverLabel>
                                    {t('common.change_status')}
                                  </StyledPopoverLabel>
                                </PopoverEl>
                              </PopoverText>
                            ) : null,
                          },
                        ]}
                      />
                    </div>
                  )}
                </>
              </If>
            ),
          },
        ),
      });
    }
    setRows(rowsDatas);
  }, [orders]);
};

const ElipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;
export const PopoverText = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledPopoverLabel = styled.div`
  padding-left: ${(props) => props.theme?.popoverLabelPadding};
`;
