import { Grid } from '@mui/material';
import { PhoneInputField } from 'app/components/Form/PhoneInputField';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomerFormcontext } from '../../context';
import { useContext } from 'react';
import { FormSection, SectionTitle } from 'app/components/Form/styles';
import FormTextField from 'app/components/Form/TextField';

export const ContactInformation = ({ control }) => {
  const { t } = useTranslation();
  const { canEdit, isCustomer } = useContext(CustomerFormcontext);
  const disabled = isCustomer || !canEdit;

  return (
    <FormSection>
      <SectionTitle>
        {t('drawer.fedipatInfo.navsection.branchesContact.contact_info')}
      </SectionTitle>
      <Grid container spacing="12px">
        <Grid item sm={6} xs={12}>
          <Controller
            name="mobilePhone"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <PhoneInputField
                {...field}
                specialLabel={t('mobile_phone')}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormTextField
            name="companyEmail"
            label="company_email_address"
            control={control}
            disabled={disabled}
            rules={{
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t('validation.email'),
              },
            }}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};
