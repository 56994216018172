export enum StatusToastVariant {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export interface StatusToasterNotification {
  duration: number;
  variant: StatusToastVariant;
  message: string;
  spacingRight: string;
  spacingBottom: string;
}
