import { lazyLoad } from 'utils/loadable';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

export const CategoriesPage = lazyLoad(
  () => import('./Categories'),
  (module) => module.Categories,
  {
    fallback: <LoadingIndicator />,
  },
);
