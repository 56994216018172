import { CustomCellRendererProps } from '@ag-grid-community/react';
import { FileTypeChip } from 'app/pages/CustomerPublic/components/Documents/components/DocumentRow';

function TypeCell(params: CustomCellRendererProps) {
  return (
    <div>
      <FileTypeChip type={params.value.typeData || ''} />
    </div>
  );
}

export default TypeCell;
