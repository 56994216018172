import { CustomCellRendererProps } from '@ag-grid-community/react';
import If from 'app/components/If';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { CLAIMS_STATS_STATUSES, CLAIMS_STATUS_CHIPS_COLORS } from '../config';
import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import { useTranslation } from 'react-i18next';
import { ActionPopover } from 'app/components/Popover';
import { useChangeStatusMutation } from 'common/services/conversationApi';

function StatusDotCell(params: CustomCellRendererProps) {
  const { t } = useTranslation();
  const status = params.value.statusData;
  const sanitizedStatus = status.replace(/-/g, '').toUpperCase();
  const statusInfo = CLAIMS_STATUS_CHIPS_COLORS[sanitizedStatus];

  const gridContext = useManagedContext<IGridContext>('grid');
  const isListMode = gridContext.viewMode === 'list';

  const statuses = Object.values(CLAIMS_STATS_STATUSES);
  const currentAvailableStatuses = statuses.filter(
    (status) => status.toLowerCase() !== params.value.statusData.toLowerCase(),
  );

  const claimId = params.value.claimId;
  const [changeStatus] = useChangeStatusMutation();

  function changeClaimStatus(status: string) {
    changeStatus({
      conversationId: claimId,
      body: {
        status: status,
      },
    });
  }

  return (
    <If condition={isListMode}>
      <ActionPopover
        id={params.value.id}
        transformOriginHorizontal={20}
        transformOriginVertical={-6}
        iconAction={
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '100%',
              backgroundColor: statusInfo?.dotColor,
            }}
          />
        }
        styling={{
          p: 0,
        }}
        actions={currentAvailableStatuses.map((status) => {
          const sanitizedStatus = status.replace(/-/g, '').toUpperCase();
          const statusInfo = CLAIMS_STATUS_CHIPS_COLORS[sanitizedStatus];

          return {
            action: `change-status-${status}`,
            element: (
              <ItemStatusComponent
                action={async () => changeClaimStatus(statusInfo.key)}
                label={t(statusInfo?.label)}
                color={statusInfo?.dotColor}
              />
            ),
          };
        })}
      />
    </If>
  );
}

export default StatusDotCell;
