import { useCallback, useEffect, useRef, useState } from 'react';
import { Typography, List, ListItem } from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLazyGetOrderActivityQuery } from 'common/services/orderApi';
import { StatusOrder } from '../../../../AddRoundTrips/data/RoundTripData/components/StatusOrder';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingPulse } from 'app/components/LoadingPulse';
import { themes } from 'styles/theme/themes';
import { getColorByOrderStatus } from 'app/pages/Orders/helpers';

interface Action {
  _id: string;
  createdAt: string;
  data: { newStatus: string };
  _user: { _id: string; fullName: string; firstName: string; lastName: string };
}

interface TrackOrderTabProps {
  orderId: string;
  orderStatus?: string;
  height?: string;
}

export function TrackOrderTab({
  orderId,
  orderStatus,
  height,
}: TrackOrderTabProps) {
  const authUser = useSelector(selectAuthUser);
  const { i18n, t } = useTranslation();
  const [trigger, { data }] = useLazyGetOrderActivityQuery();
  const [activities, setActivities] = useState([]);
  const page = useRef(1);

  useInfiniteScroll({
    responseData: data?.docs,
    data: activities,
    setData: setActivities,
    page: page.current,
  });

  const fetchData = useCallback(
    (page: number) => {
      if (!page) return;
      trigger({
        id: orderId,
        query: `limit=20&page=${page}`,
      });
    },
    [orderId, trigger],
  );

  const loadMore = () => {
    page.current += 1;
    fetchData(page.current);
  };

  useEffect(() => {
    if (page.current !== 1) return;
    fetchData(1);
  }, [fetchData, orderId, orderStatus]);

  const formatDate = useCallback(
    (dateString: string) => {
      const date = new Date(dateString);
      const locale = i18n.language;
      return date.toLocaleDateString(locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    [i18n.language],
  );

  const formatTime = useCallback(
    (dateString: string) => {
      const date = new Date(dateString);
      const locale = i18n.language;
      return date.toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    [i18n.language],
  );

  const actions = useCallback(() => {
    if (!activities) return {};

    return activities.reduce((acc, curr: Action, index) => {
      const date = formatDate(curr.createdAt);
      const time = formatTime(curr.createdAt);
      let name =
        curr._user?._id === authUser?._id ? t('you') : curr._user?.fullName;
      const created = index === 0;
      if (curr?._id === 'init') {
        name = curr?._user?.firstName;
      }
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push({
        time,
        status: curr.data?.newStatus,
        updatedBy: name,
        created,
      });
      return acc;
    }, {});
  }, [activities, authUser?._id, formatDate, formatTime, t]);

  const groupedActions = actions();

  return (
    <ScrollContainer height={height} id="scrollable-timeline">
      <InfiniteScroll
        dataLength={activities?.length || 0}
        next={loadMore}
        hasMore={data?.hasNextPage}
        loader={
          <LoaderText>
            <LoadingPulse />
          </LoaderText>
        }
        scrollableTarget="scrollable-timeline"
        style={{ overflow: 'visible' }}
      >
        {Object.keys(groupedActions).map((date, parentIndex) => (
          <DayContainer key={parentIndex}>
            <DayContent>{date}</DayContent>
            <List sx={{ padding: '0px !important' }}>
              {groupedActions[date].map((item, index) => (
                <ListItem key={index} sx={{ padding: '0' }}>
                  <StatusBlock status={item?.status}>
                    <RowContainer>
                      <Typography
                        fontSize="0.75rem"
                        lineHeight="1.25rem"
                        fontWeight="400"
                        letterSpacing="0.4px"
                        color={themes.default.black2}
                      >
                        {item.time}
                      </Typography>
                      <StatusOrder status={item?.status} />
                    </RowContainer>
                    {item?.updatedBy && (
                      <Typography
                        color={themes.default.lightGrayLabel}
                        fontSize="0.6875rem"
                        lineHeight="1.125rem"
                        fontWeight="400"
                        fontStyle="italic"
                        letterSpacing="0.4px"
                      >
                        {t(item?.created ? 'common.created_by' : 'updated_by')}{' '}
                        {item.updatedBy}
                      </Typography>
                    )}
                  </StatusBlock>
                </ListItem>
              ))}
            </List>
          </DayContainer>
        ))}
      </InfiniteScroll>
    </ScrollContainer>
  );
}

const ScrollContainer = styled.div<{ height?: string }>`
  height: ${(props) => (props.height ? props.height : 'auto')};
  overflow-y: auto;
  padding: 0 16px;
  position: relative;
`;

const LoaderText = styled.div`
  text-align: center;
  padding: 20px 0;
`;

const StatusBlock = styled.div<any>`
  position: relative;
  margin-bottom: 4px;

  /* Line before the status (from top to the status) */
  &::before {
    content: '';
    position: absolute;
    top: 17px;
    left: -20px;
    width: 1px;
    height: 60%;
    background-color: ${themes.default.silver}; /* Color of the line */
  }

  /* Line after the status (from status to the bottom) */
  &::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 10px;
    top: 5px;
    left: -23px;
    background-color: ${(props) => getColorByOrderStatus(props.status)};
  }
`;

const DayContainer = styled.div`
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
`;

const DayContent = styled.h6`
  position: relative;
  margin-bottom: 4px;
  margin-top: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.17px;
  color: ${themes.default.black2};

  &::before {
    content: '';
    position: absolute;
    top: 18px;
    left: -20px;
    width: 1px;
    height: 25%;
    background-color: ${themes.default.silver};
  }

  &::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 9px;
    border-radius: 10px;
    top: 4px;
    left: -24px;
    background-color: ${themes.default.silver};
  }
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .MuiChip-root {
    height: 18px !important;
  }
`;
