import { FormControlLabel, Grid } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import If from 'app/components/If';
import { FilterName, ToggleIcon } from '../../styles';
import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { CountChip } from '../CountChip';
import { Checkbox } from 'app/components/CustomCheckbox';

interface CheckboxGrid {
  openByDefault?: boolean;
  filterName: string;
  filterItems: {
    value: string | boolean;
    label: string;
    count?: number;
    chipLabel?: string;
  }[];
  checkedValues: Record<string, boolean>;
  handleFilterChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string,
  ) => void;
}

const CheckboxGrid = ({
  openByDefault,
  filterName,
  filterItems,
  checkedValues,
  handleFilterChange,
}: CheckboxGrid) => {
  const isOpen = !!openByDefault;
  const [open, setOpen] = useState<boolean>(isOpen || false);

  const { checkedCount, isHighlighted } = useMemo(() => {
    const count = filterItems.filter(
      (item) => checkedValues[item.label],
    ).length;
    return {
      checkedCount: count,
      isHighlighted: count > 0,
    };
  }, [filterItems, checkedValues]);

  return (
    <>
      <div className="title_action" onClick={() => setOpen(!open)}>
        {isHighlighted ? (
          <FilterName isHighlighted={true}>
            {filterName} ({checkedCount})
          </FilterName>
        ) : (
          <FilterName>{filterName}</FilterName>
        )}
        <ToggleIcon disableRipple>
          {open ? <RemoveIcon /> : <AddIcon />}
        </ToggleIcon>
      </div>
      <If condition={open}>
        <Grid container spacing={0}>
          {filterItems?.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleFilterChange(e, item.chipLabel || item.label);
                      }}
                      checked={
                        checkedValues[item.chipLabel || item.label] || false
                      }
                      value={item.value}
                    />
                  }
                  label={
                    <LabelContainer>
                      <div>{item.label}</div>
                      <CountChip count={item?.count} />
                    </LabelContainer>
                  }
                  sx={{
                    marginLeft: 0,
                    '& span': {
                      fontSize: '0.875rem',
                    },
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </If>
    </>
  );
};

export default CheckboxGrid;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;
