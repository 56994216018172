import { Box, CircularProgress, IconButton, SxProps } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Icon } from 'app/components/Icon';
import { Minus, Plus, Package } from '@phosphor-icons/react';
import If from 'app/components/If';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

interface Props {
  initialValue?: number;
  onChange: (value: number) => void;
  onAdd?: () => void;
  loading?: boolean;
}

export const ValueInput = ({
  initialValue = 0,
  onChange,
  onAdd = () => {},
  loading = false,
}: Props) => {
  const theme = useSelector(selectTheme);
  const [value, setValue] = useState<number>(initialValue);

  const handleChangeValue = useCallback(
    (newValue) => {
      if (loading) return;

      if (value === 0 && newValue > value) {
        onAdd();
      }
      setValue(newValue);
      onChange(newValue);
    },
    [loading, onAdd, onChange, value],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="2px"
      sx={{ transition: 'all 0.15s', opacity: loading ? 0.5 : 1 }}
    >
      <IconButton
        disabled={value < 1 || loading}
        sx={IconButtonSx}
        onClick={() => handleChangeValue(value - 1)}
        disableRipple={false}
        centerRipple={false}
      >
        <Icon icon={<Minus />} />
      </IconButton>

      <If
        condition={!loading}
        otherwise={
          <QuantityInputLoader>
            <CircularProgress size={14} sx={{ colot: theme.primary }} />
          </QuantityInputLoader>
        }
      >
        <QuantityInput
          type="number"
          value={value}
          onChange={(e) => handleChangeValue(parseInt(e.target.value))}
        />
      </If>

      <IconButton
        sx={IconButtonSx}
        onClick={() => handleChangeValue(value + 1)}
        disableRipple={false}
        centerRipple={false}
        disabled={loading}
      >
        <Icon icon={<Plus />} />
      </IconButton>

      <IconButton sx={UnitButtonSx} disableRipple={false} centerRipple={false}>
        <Icon icon={<Package />} size={13} color={themes.default.nobel} />
      </IconButton>
    </Box>
  );
};

const IconButtonSx: SxProps = {
  height: '24px',
  width: '24px',
  borderRadius: '4px',
  padding: '2px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${themes.default.silver}`,
};

const QuantityInput = styled.input`
  height: 24px;
  width: 28px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.174px;
  text-align: center;
  border: 1px solid ${themes.default.silver};
  background-color: ${themes.default.ordoriaBg};
  margin: 0;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

const UnitButtonSx: SxProps = {
  ...IconButtonSx,
  width: '28px',
  background: themes.default.grey,
};

const QuantityInputLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 28px;
  border-radius: 4px;
  border: 1px solid ${themes.default.silver};
`;
