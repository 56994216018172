import { AgentCategories } from '../RoundTripData/agentCategories';
import { Completed } from '../RoundTripData/statuses';

export const findRole = (agent, role) => agent._role.key === role;
export const roundtripStatus = (roundTripCopy, theme) => {
  return roundTripCopy.status === Completed
    ? theme.greenStatus
    : roundTripCopy.status === 'cancelled'
      ? theme.redStatus
      : roundTripCopy.status === 'empty'
        ? theme.greyDisabled
        : theme.orangePre;
};

export const handleAccordionToggle = (
  e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
  group: number,
  index: number,
  accordionStatus,
  setAccordionStatus,
) => {
  e.stopPropagation();
  let accordionStatusCopy;
  if (Array.isArray(accordionStatus)) {
    accordionStatusCopy = JSON.parse(JSON.stringify(accordionStatus));
  }
  if (accordionStatusCopy?.[group]) {
    accordionStatusCopy[group].value[index] =
      !accordionStatusCopy[group]?.value[index];
    setAccordionStatus?.(accordionStatusCopy);
  }
};
export const checkExistingRoundtrips = async (args) => {
  args.setIsLoading(true);
  if (args?.data) {
    if (args.data?.totalDocs) {
      args?.setDisabledProceed(true);
      args?.setAlreadyExists(true);
    } else {
      args?.setDisabledProceed(false);
      args?.setAlreadyExists(false);
    }
  }
  args.setIsLoading(false);
};
export const zoomedInWidth = (
  zoomedIn,
  theme,
  isInRoundtrip = false,
  closest: string | null = null,
  layout = '',
) => {
  if (closest === 'left' || closest === 'right') {
    return `calc(100vh - ${theme.toolBarHeight} - 15px)`;
  }
  return zoomedIn
    ? `calc(100vh - ${theme.toolBarHeight} - 15px - ${
        isInRoundtrip &&
        (closest === 'top' ||
          closest === 'bottom' ||
          layout === 'top' ||
          layout === 'bottom')
          ? `${theme.barWidth}px`
          : '0px'
      })` //15px padding
    : `calc(100vh - ${theme.topBarHeight} - ${theme.toolBarHeight} - 15px - ${
        isInRoundtrip &&
        (closest === 'top' ||
          closest === 'bottom' ||
          layout === 'top' ||
          layout === 'bottom')
          ? `${theme.barWidth}px`
          : '0px'
      })`; //15px padding
};
export const triggerClusterByMode = async (
  triggerByMode: any,
  mode: string,
  deck: string,
  dateBegin: string,
  dateEnd: string,
  agentCategory?: AgentCategories | null,
) => {
  if (!dateBegin || !dateEnd) {
    return;
  }
  return await triggerByMode(
    `?beginAt=${dateBegin}&endAt=${dateEnd}&limit=1000&mode=${mode}&_deck=${deck}&agentCategory=${agentCategory}`,
  );
};
export const updateAutocomplete = async ({
  triggerByMode,
  mode,
  warehouses,
  begin,
  end,
  selectedIndex,
  userContext,
}) => {
  await triggerClusterByMode(
    triggerByMode,
    mode,
    warehouses?.decks[selectedIndex]?._id,
    begin,
    end,
    userContext,
  );
};

export const disabledVehicle = (
  vehiculeData,
  vehicles,
  setVehiculeData,
  data,
  roundTripData,
) => {
  const inRoundtrip: any[] = [];
  const available: any[] = [];

  vehicles.map((t) => {
    const existingRoundtrip = roundTripData?.find(
      (d) => d?._vehicle?._id === t?._id,
    );
    const existingDataRoundtrip = data?.find((d) => d?._vehicle === t?._id);
    const existingCode = existingRoundtrip?.code || existingDataRoundtrip?.code;
    if (existingDataRoundtrip || existingRoundtrip) {
      inRoundtrip.push({ ...t, roundtripCode: existingCode, disabled: true });
    } else {
      available.push({ ...t, roundtripCode: existingCode, disabled: false });
    }
  });
  setVehiculeData(available.concat(inRoundtrip));
};
