import { CustomCellRendererProps } from '@ag-grid-community/react';
import { CLAIMS_TYPES } from '../config';
import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClaimIcon } from '../components/ClaimIcon';

function TypeCell(params: CustomCellRendererProps) {
  const { t } = useTranslation();
  const type = params?.value?.topicData;

  return (
    <Tooltip title={t(CLAIMS_TYPES[type.toUpperCase()]?.label)}>
      <TypeContainer
        backgroundColor={CLAIMS_TYPES[type.toUpperCase()]?.backgroundColor}
      >
        <ClaimIcon claimType={type} />
      </TypeContainer>
    </Tooltip>
  );
}

const TypeContainer = styled.div<{ backgroundColor: string }>`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${(props) => props.backgroundColor};
`;

export default TypeCell;
