import api from './api';

export const jobroleApi = api.injectEndpoints({
  endpoints: builder => ({
    getJobRoles: builder.query<any, string>({
      query: urlQuery => ({
        url: `/job-roles${urlQuery}`,
      }),
      providesTags: ['JobRoles'],
    }),
    postJobRole: builder.mutation<any, any>({
      query: body => ({
        url: `/job-roles/`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetJobRolesQuery, usePostJobRoleMutation } = jobroleApi;

export default jobroleApi;
