import React, { useContext } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Popover, ButtonBase, Typography } from '@mui/material';
import { If } from 'app/components/If';
import { NoDataComponents } from 'app/components/NoData/NoDataComponents';
import { useTranslation } from 'react-i18next';
import NoOrdersIcon from 'assets/img/ORDORIA/Catalog/NoOrdersIcon.svg';
import { Icon } from 'app/components/Icon';
import { Plus } from '@phosphor-icons/react';
import { CatalogContext } from 'app/pages/Ordoria/Catalog';

interface Props {
  anchorEl: any;
  handleClose: () => void;
  orders: any[];
}

export const CustomerOrdersPopover: React.FC<Props> = ({
  anchorEl,
  handleClose,
  orders = [],
}) => {
  const { t } = useTranslation();
  const { setOpenCreateOrderDrawer } = useContext(CatalogContext);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: -13,
        horizontal: 0,
      }}
    >
      <Wrapper>
        <If
          condition={orders?.length > 0}
          otherwise={
            <EmptyCenter>
              <NoDataComponents
                viewMode={true}
                Icon={NoOrdersIcon}
                text={t('no_orders_yet_')}
                IconButton={null}
                textButton=""
              />
            </EmptyCenter>
          }
        >
          <OrdersWrapper />
        </If>
        <PopoverActionsWrapper>
          <ButtonBase
            onClick={() => {
              handleClose();
              setOpenCreateOrderDrawer(true);
            }}
          >
            <ActionButtonWrapper>
              <Icon
                icon={<Plus />}
                color={themes?.ordoria?.primary}
                size={12}
              />
              <Typography
                fontSize="0.75rem"
                fontWeight="500"
                lineHeight="10px"
                letterSpacing="0.14px"
                color={themes?.ordoria?.primary}
                sx={{
                  padding: '2px 0 0 0',
                }}
              >
                {t('orders.create_orders')}
              </Typography>
            </ActionButtonWrapper>
          </ButtonBase>
        </PopoverActionsWrapper>
      </Wrapper>
    </Popover>
  );
};

export const Wrapper = styled.div`
  width: 251px;
  min-height: 133px;
  max-height: 273px;
  height: max-content;
  display: flex;
  flex-direction: column;
`;

export const OrdersWrapper = styled.div``;

export const EmptyCenter = styled.div`
  height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  p {
    margin: 0 !important;
    font-size: 0.8125rem;
    line-height: 13px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    color: ${themes?.default?.textBlack};
  }
`;

export const PopoverActionsWrapper = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  gap: 6px;
  background: ${themes?.default?.ordoriaLightActionsBg};
  padding: 0 10px;
`;

export const ActionButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 4px;
`;
