import GridContext from 'app/components/ManagedGrid/GridContext';
import { MultiplePaymentsGrid } from './MultiplePaiementsGrid';
import { getCustomMultiplePaymentsColumnConfig } from './config';
import { ListComponent } from './ListComponent';
import { ListPaymentProvider } from './ListComponent/ListPaymentProvider';
import { useTranslation } from 'react-i18next';

export function MultiplePayments() {
  const { t } = useTranslation();

  const columns = getCustomMultiplePaymentsColumnConfig(t);

  return (
    <ListPaymentProvider>
      <GridContext
        data={[]}
        customColumnConfig={columns}
        title="transactions.invoices.title"
        canExport={true}
        listComponent={ListComponent}
        /**
         * statsConfig={{
          colors: BALANCE_STATS_COLORS,
          statuses: BALANCE_STATS_STATUSES,
          title: 'customer.status',
          calculateGrandTotal,
          calculateStatusMetrics,
        }}
        */
      >
        <MultiplePaymentsGrid />
      </GridContext>
    </ListPaymentProvider>
  );
}
