import { Button, Typography } from '@mui/material';
import { Phone, PhoneOutgoing } from '@phosphor-icons/react';

import { themes } from 'styles/theme/themes';

export default function CallButton({
  onCall,
  onHangup,
  isLoading,
  status,
  disabled,
}: {
  onCall: () => void;
  onHangup: () => void;
  isLoading: boolean;
  status: string | null;
  disabled?: boolean;
}) {
  return (
    <>
      {!isLoading && status === null && (
        <Button
          variant="contained"
          disabled={disabled}
          onClick={onCall}
          sx={{
            background: '#009688',
            ':hover': {
              background: '#00786c',
            },
          }}
        >
          <Typography color="white" fontWeight="500" fontSize="13px">
            Appeler
          </Typography>
          <PhoneOutgoing
            color="white"
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '4px',
            }}
          />
        </Button>
      )}

      {(status === 'ringing' || isLoading) && (
        <Button
          variant="contained"
          onClick={onHangup}
          sx={{
            background: '#009688',
            ':hover': {
              background: '#00786c',
            },
          }}
        >
          <Typography color="white" fontWeight="500" fontSize="13px">
            Connexion...
          </Typography>
          <Phone
            color="white"
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '4px',
            }}
          />
        </Button>
      )}

      {status === 'connected' && (
        <Button
          variant="contained"
          onClick={onHangup}
          sx={{
            background: themes.default.redA700,
            ':hover': {
              background: themes.default.redA700,
            },
          }}
        >
          <Typography color="white" fontWeight="500" fontSize="13px">
            Raccrocher
          </Typography>
          <PhoneOutgoing
            color="white"
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '4px',
            }}
          />
        </Button>
      )}
    </>
  );
}
