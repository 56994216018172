import { useGetProductsCategoriesQuery } from 'common/services/productApi';
import styled from 'styled-components';
import Each from '../../../../../../common/UtilityComponents/Each';
import { CardCollapsableCardCategory } from './components/CardCollapsableCardCategory';

export interface Category {
  name: string;
  _id: string;
  productsCount: number;
}

export const CardComponent = () => {
  const { data: categories } = useGetProductsCategoriesQuery({
    urlQuery: '?sort=-name',
  });

  return (
    <Wrapper>
      <Each
        of={categories?.filter((cat) => !!cat._id && !!cat.name) || []}
        render={(cat: Category, index) => (
          <CardCollapsableCardCategory
            key={index}
            category={cat}
            expandedByDefault={index === 0}
          />
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 16px 0px 16px 20px;
  padding: 0px 20px 0px 0px;
  gap: 20px;
  overflow-y: auto;
  height: fit-content;
`;
