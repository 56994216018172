import { Stack } from '@mui/material';
import styled from 'styled-components';
import If from '../If';
import NoResults from '../NoData/NoResults';
import { themes } from '../../../styles/theme/themes';
import { AgGridReact } from '@ag-grid-community/react';
import { Ref, useCallback, useEffect, useMemo, useRef } from 'react';
import { GetRowIdParams, RowClassRules } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import '../../components/ManagedGrid/index.css';
import { useOnScreen } from '../ProductCard/hook';
import { StateSetter } from 'types';
import { getColumnOrderPriority } from '../ManagedGrid/helpers';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { IGridContext } from '../ManagedGrid/types';

interface Props {
  gridRef: Ref<AgGridReact>;
  rowData: any;
  columnDefs: any;
  loading: boolean;
  filtered: boolean;
  setSort?: StateSetter<string>;
  onScrollEndReached?: () => void;
  onSelectionChanged?: (value: any) => void;
  rowClassRules?: RowClassRules;
  refs?: AgGridReact[];
}

export default function ManagedGridList({
  gridRef,
  rowData,
  columnDefs,
  loading = false,
  filtered = false,
  setSort = () => {},
  onScrollEndReached = () => {},
  rowClassRules,
  refs,
}: Props) {
  const gridContext = useManagedContext<IGridContext>('grid');

  const gridEndRef = useRef<any>(null);

  const isScrollEndReached = useOnScreen(gridEndRef);

  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: false,
      suppressHeaderFilterButton: true,
      width: 150,
      cellStyle: {
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.875rem',
        fontFamily: 'Roboto, sans-serif',
      },
    };
  }, []);

  const getRowId = useCallback(
    (params: GetRowIdParams) => String(params.data.id),
    [],
  );

  const showCheckbox = useMemo(() => {
    return (
      (gridContext.canEdit || gridContext.canExport) &&
      !gridContext.suppressCheckbox
    );
  }, []);
  const rowSelection = useMemo(() => {
    if (!showCheckbox) {
      return undefined;
    }
    return {
      mode: 'multiRow' as 'multiRow',
      headerCheckbox: true,
    };
  }, [showCheckbox]);

  useEffect(() => {
    if (isScrollEndReached && rowData.length > 0) {
      onScrollEndReached();
    }
  }, [isScrollEndReached]);

  useEffect(() => {
    //@ts-ignore
    if (!gridRef?.current) return;
    gridContext.updateDataWithFunction((prev) => {
      prev.api = {
        ...prev.api,
        setColumnsVisible: (fields: string[], visible: boolean) => {
          const filteredFields = fields.filter((field) => {
            //@ts-ignore
            const column = gridRef.current?.api.getColumnDef(field);
            return !column?.lockVisible;
          });
          //@ts-ignore
          gridRef.current?.api.setColumnsVisible(filteredFields, visible);
          gridContext.updateDataWithFunction((prev) => {
            //@ts-ignore
            prev.columnDefs = gridRef.current?.api.getColumnDefs()!;
          });
          if (refs) {
            refs.forEach((reff) => {
              reff?.api.setColumnsVisible(filteredFields, visible);
            });
          }
        },
        disableToolBar: (extraCondition) => {
          return (
            gridContext.totalRows === 0 &&
            !gridContext.queryParams.search &&
            gridContext.filtersCount === 0 &&
            extraCondition
          );
        },
        clearSelection: () => {
          //@ts-ignore
          gridRef.current!.api.deselectAll();
        },
        getCurrentColumns: () => {
          //@ts-ignore
          if (!gridRef.current) return columnDefs;

          return (
            gridRef
              //@ts-ignore
              .current!.api.getAllGridColumns()
              // @ts-ignore
              .map((col) => col.colDef)
              .slice(!!gridContext.suppressCheckbox ? 0 : 1)
          );
        },
        pinColumn: (
          field: string,
          pinned: 'left' | 'right' | boolean | null | undefined,
        ) => {
          //@ts-ignore
          if (!gridRef.current) return;
          //@ts-ignore
          gridRef.current!.api.setColumnsPinned([field], pinned);

          gridContext.updateDataWithFunction((prev) => {
            prev.columnDefs = gridRef
              //@ts-ignore
              .current!.api.getColumnDefs()!
              .sort((a, b) => {
                // Define priority for column positioning

                const priorityA = getColumnOrderPriority(a);
                const priorityB = getColumnOrderPriority(b);

                if (priorityA !== priorityB) return priorityA - priorityB;

                // If priorities are equal, maintain original order
                return 0;
              });
          });
        },
      };
    });
    //@ts-ignore
  }, [gridRef?.current]);

  return (
    <Stack direction="column" width="100%" height="100%">
      {/* NO SEARCH/FILTER RESULTS */}
      <If condition={rowData.length === 0 && !loading && !!filtered}>
        <Stack direction="row" width="100%" height="300px">
          <EmptyCenter>
            <NoResults />
          </EmptyCenter>
        </Stack>
      </If>
      <If condition={!!rowData.length}>
        <TableWrapper>
          <GridContainer
            className="ag-theme-quartz"
            suppressHeader={gridContext.suppressHeader}
          >
            <AgGridReact
              rowData={rowData}
              ref={gridRef}
              columnDefs={columnDefs}
              domLayout="autoHeight"
              loading={loading}
              getRowId={getRowId}
              scrollbarWidth={20}
              suppressNoRowsOverlay={true}
              alwaysShowHorizontalScroll={false}
              alwaysShowVerticalScroll={false}
              defaultColDef={defaultColDef}
              rowSelection={rowSelection}
              selectionColumnDef={{
                pinned: 'left',
                lockPinned: true,
              }}
              onSortChanged={(e: any) => {
                if (!e.columns?.[0]) return;
                const order =
                  e.columns[e.columns.length - 1].sort === 'asc' ? '-' : '';
                const orderBy =
                  e.columns[e.columns.length - 1].getUserProvidedColDef()
                    ?.sortField;
                setSort(`${order}${orderBy}`);
              }}
              onSelectionChanged={(e) => {
                gridContext.updateData(
                  'selectedRows',
                  e.api.getSelectedRows().map((r) => r.id),
                );
              }}
              rowHeight={52}
              rowClassRules={rowClassRules}
            />
          </GridContainer>
        </TableWrapper>
      </If>
      <div ref={gridEndRef} />
    </Stack>
  );
}

const TableWrapper = styled.div({
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  boxShadow: 'none !important',
  padding: '0 2px',
});

const EmptyCenter = styled.div<{ top?: string }>`
  position: absolute;
  top: ${(props) =>
    props.top ? props.top : `calc(50% + ${themes.default.toolBarHeight})`};
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const GridContainer = styled.div<{ suppressHeader?: boolean }>`
  position: relative;
  .ag-body-horizontal-scroll {
    height: 8px !important;
    min-height: 8px !important;
    max-height: 8px !important;
  }
  .ag-center-cols-viewport {
    min-height: unset !important;
  }

  .ag-theme-quartz,
  .ag-theme-quartz-dark,
  .ag-theme-quartz-auto-dark {
    --ag-active-color: ${(props) => props.theme?.primary};
    --ag-border-radius: 2px;
  }

  .ag-checkbox .ag-input-wrapper {
    overflow: hidden;
  }

  .ag-checkbox-input-wrapper::after {
    color: transparent;
    box-shadow: inset 0px 0px 0px 1px var(--ag-checkbox-unchecked-color);
    border-radius: var(--ag-border-radius);
  }

  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: var(--ag-checkbox-unchecked-color);
    box-shadow: inset 0px 0px 0px 3px var(--ag-checkbox-unchecked-color);
    border-radius: var(--ag-border-radius);
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: ${(props) => props.theme?.primary};
    box-shadow: inset 0px 0px 0px 3px ${(props) => props.theme?.primary};
    border-radius: var(--ag-border-radius);
  }

  .ag-header {
    display: ${(props) => (props.suppressHeader ? 'none !important' : '')};
  }
`;
