import { CustomCellRendererProps } from '@ag-grid-community/react';
import { useManagedContext } from '../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
import { useTranslation } from 'react-i18next';
import { themes } from '../../../../../styles/theme/themes';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { PopoverEl } from 'app/components/Popover';
import { CLAIMS_STATS_STATUSES, CLAIMS_STATUS_CHIPS_COLORS } from '../config';
import { PopoverText } from 'hooks/Ordoria/useFormatOrdersData';
import { ItemStatusComponent } from 'app/pages/AddRoundTrips/data/RoundTripData/components/components/ItemStatusComponent';
import {
  useChangeStatusMutation,
  usePatchConversationMutation,
} from 'common/services/conversationApi';
import { useContext } from 'react';
import { ClaimsContext } from '../ClaimsProvider';
import { ActionComponent } from 'app/components/ManagedGrid/components/ActionComponent';
import { Check } from '@phosphor-icons/react';

const ActionsCell = (params: CustomCellRendererProps) => {
  const gridContext = useManagedContext<IGridContext>('grid');
  const { t } = useTranslation();
  const claimId = params.value.claimId;
  const statuses = Object.values(CLAIMS_STATS_STATUSES);
  const currentAvailableStatuses = statuses.filter(
    (status) => status.toLowerCase() !== params.value.statusData.toLowerCase(),
  );

  const { setOpenDetail, setSelectedClaimId } = useContext(ClaimsContext);

  const isArchivedFilter = gridContext.queryParams.filters.archived;

  const statusActions: Array<{ action: string; element: JSX.Element }> = [];

  const [patchConversation] = usePatchConversationMutation();
  const [changeStatus] = useChangeStatusMutation();

  function changeClaimStatus(status: string) {
    changeStatus({
      conversationId: claimId,
      body: {
        status: status,
      },
    });
  }

  function archiveClaim() {
    patchConversation({
      conversationId: claimId,
      body: {
        isArchived: true,
      },
    });
  }

  function unArchiveClaim() {
    patchConversation({
      conversationId: claimId,
      body: {
        isArchived: false,
      },
    });
  }

  currentAvailableStatuses.forEach((status) => {
    const sanitizedStatus = status.replace(/-/g, '').toUpperCase();
    const statusInfo = CLAIMS_STATUS_CHIPS_COLORS[sanitizedStatus];

    if (statusInfo) {
      statusActions.push({
        action: statusInfo.key,
        element: (
          <ItemStatusComponent
            action={async () => changeClaimStatus(statusInfo.key)}
            label={t(statusInfo.label)}
            color={statusInfo.dotColor}
          />
        ),
      });
    }
  });

  const allowedActions: any = [];

  if (
    gridContext.canEdit &&
    params.value.statusData === CLAIMS_STATS_STATUSES.PENDING
  ) {
    allowedActions.push({
      action: 'edit',
      onClick: () => {},
      element: (
        <PopoverEl redStatus="" style={{ width: '100%' }}>
          <div className="popover-item">{t('edit')}</div>
        </PopoverEl>
      ),
    });
  }

  if (gridContext.canEdit) {
    allowedActions.push({
      action: 'changeStatus',
      nested: true,
      actions: statusActions,
      element: (
        <PopoverText>
          <div
            style={{
              marginLeft: themes.default.popoverLabelPadding,
              fontSize: '0.85rem',
            }}
          >
            {t('common.change_status')}
          </div>
        </PopoverText>
      ),
    });
  }

  allowedActions.push({
    action: 'view',
    onClick: () => {
      setOpenDetail(true);
      setSelectedClaimId(claimId);
    },
    element: (
      <PopoverEl redStatus="" style={{ width: '100%' }}>
        <div className="popover-item">{t('common.button.view_details')}</div>
      </PopoverEl>
    ),
  });

  if (gridContext.canEdit && !isArchivedFilter) {
    allowedActions.push({
      action: 'archive',
      onClick: () => archiveClaim(),
      element: (
        <PopoverEl
          redStatus={themes.default.redStatus}
          style={{ width: '100%' }}
        >
          <div className="delete popover-item">{t('buttons.archive')}</div>
        </PopoverEl>
      ),
    });
  }

  if (gridContext.canEdit && isArchivedFilter) {
    allowedActions.push({
      action: 'unarchived',
      onClick: () => unArchiveClaim(),
      element: (
        <PopoverEl
          redStatus={themes.default.redStatus}
          style={{ width: '100%' }}
        >
          <div className="delete popover-item">{t('messages.unarchive')}</div>
        </PopoverEl>
      ),
    });
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ActionComponent
        actions={allowedActions}
        quickActions={
          params.value.statusData !== CLAIMS_STATS_STATUSES.RESOLVED
            ? [
                {
                  icon: <Check />,
                  onClick: async () =>
                    changeClaimStatus(CLAIMS_STATS_STATUSES.RESOLVED),
                },
              ]
            : []
        }
      />
    </div>
  );
};
export default ActionsCell;
