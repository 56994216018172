import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, InputAdornment } from '@mui/material';
import { CalendarBlank } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import styled from 'styled-components';
import { PickersDay } from '@mui/x-date-pickers';
import { useGetOrdersDeliveryDatesQuery } from 'common/services/orderApi';
import { useQueryParams } from 'hooks/useQueryParams';

export const DeliveryDateCalendar = ({
  dateValue,
  handleDateValueChange,
  sx,
  error = false,
}) => {
  const [dateFrom, setDateFrom] = useState<string>(
    moment().startOf('month').subtract(6, 'days').format('YYYY-MM-DD'),
  );
  const [dateTo, setDateTo] = useState<string>(
    moment().endOf('month').add(6, 'days').format('YYYY-MM-DD'),
  );

  const { t } = useTranslation();
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  const getDeliveryDates = (e) => {
    const firstFormattedDay = moment(e['$d'])
      .startOf('month')
      .subtract(6, 'days')
      .format('YYYY-MM-DD');

    const lastFormattedDay = moment(e['$d'])
      .endOf('month')
      .add(6, 'days')
      .format('YYYY-MM-DD');

    setDateFrom(firstFormattedDay);
    setDateTo(lastFormattedDay);
  };
  const fetchQuerysParams = useQueryParams({
    end: dateTo || '',
    begin: dateFrom || '',
  });

  const [deliveryDates, updateDeliveryDates] = useState<string[]>([]);

  const { data: order_, refetch: refetchDeliveryDates } =
    useGetOrdersDeliveryDatesQuery(`${fetchQuerysParams}`, {
      skip: !dateFrom || !dateTo,
    });

  useEffect(() => {
    if (order_ && datePickerOpen) {
      refetchDeliveryDates();
    }
  }, [datePickerOpen]);

  useEffect(() => {
    Promise.all(
      order_?.map(async (orderPromise) => {
        const order = await orderPromise;
        return order?._id;
      }),
    )
      .then((newDeliveryDates) => {
        const reformattedDates = newDeliveryDates.map((date) =>
          date.substr(0, 10),
        );
        updateDeliveryDates((prevDeliveryDates) => [
          ...prevDeliveryDates,
          ...reformattedDates,
        ]);
      })
      .catch((error) => {
        console.error('Error fetching delivery dates:', error);
      });
  }, [order_, dateValue]);

  return (
    <DatePicker
      onMonthChange={(e) => getDeliveryDates(e)}
      label={t('clients.orderDetails.deliveryDate')}
      inputFormat="MMM DD, YYYY"
      minDate={moment().format('YYYY/MM/DD')}
      components={{
        OpenPickerIcon: CalendarIcon,
      }}
      value={dateValue}
      open={datePickerOpen}
      onClose={() => setDatePickerOpen(false)}
      onOpen={() => setDatePickerOpen(true)}
      onChange={handleDateValueChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Icon icon={<CalendarBlank />} size={24} />
          </InputAdornment>
        ),
      }}
      renderInput={(params) => (
        <TextField
          size="small"
          label={t('clients.orderDetails.deliveryDate')}
          focused={datePickerOpen}
          onClick={() => setDatePickerOpen(true)}
          sx={sx}
          {...params}
          error={error}
        />
      )}
      renderDay={(date, _, DayComponentProps) => {
        let date_: any;
        const selectedDate = dateValue?.$d?.getTime();
        date_ = date;
        const formattedDate_ = `${date_?.$y}-${(date_?.$M + 1)
          .toString()
          .padStart(2, '0')}-${date_?.$D.toString().padStart(2, '0')}`;
        date_ = date_?.$d?.getTime();
        const datesAreMatching = date_ === selectedDate;
        const isDateInDeliveryDates = deliveryDates.includes(formattedDate_);

        return (
          <CalendarDayContainer>
            <PickersDay
              {...DayComponentProps}
              outsideCurrentMonth={false}
              selected={datesAreMatching}
            />
            {isDateInDeliveryDates ? (
              <CalenderDayMark color={datesAreMatching ? 'white' : ''} />
            ) : (
              ''
            )}
          </CalendarDayContainer>
        );
      }}
    />
  );
};

const CalendarDayContainer = styled.div`
  display: flex;
  position: relative;
`;

const CalenderDayMark = styled.span`
  height: 4px;
  width: 4px;
  border-radius: 3px;
  z-index: 100;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) =>
    props.color ? props.color : props.theme.primary};
`;

const CalendarIcon = () => {
  return <Icon icon={<CalendarBlank />} />;
};
