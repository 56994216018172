/**
 * Client Map Component
 * Contained title, autocomplete field and map with a geolocalisation options
 */

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Crosshair } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import MarkerPinBlue from '../../../assets/img/map/CustomerMap/MarkerPrimaryBlue.svg';
import { LocationInput } from '../LocationInput';
import If from '../If';
import { Controller } from 'react-hook-form';
import { GoogleMapComponent } from 'app/components/GoogleMapComponent';
import { StreetViewPanorama } from 'app/components/GoogleMapComponent/components/StreetViewPanorama';
import { Marker } from '@vis.gl/react-google-maps';
import { usePlaceContext } from 'app/pages/Customers/components/ViewCustomer/hooks/usePlaceContext';
import { useApplicationAssets } from 'common/hooks/useApplicationAssets';

export interface MapProps {
  mapHeight: string;
  mapWidth: string;
}

export function ClientMap({
  mapHeight,
  latitude,
  longitude,
  title,
  setLocationValue,
  mapWidth,
  defaultFieldValue,
  mainOption = false,
  control,
}) {
  const { canEdit } = usePlaceContext();

  const assets = useApplicationAssets();

  const [streetViewVisible, setStreetViewVisible] = useState<boolean>(false);

  const [center, setCenter] = useState({
    lat:
      defaultFieldValue !== undefined
        ? defaultFieldValue?.geometry?.coordinates[1]
        : 0,
    lng:
      defaultFieldValue !== undefined
        ? defaultFieldValue?.geometry?.coordinates[0]
        : 0,
  });

  useEffect(() => {
    if (defaultFieldValue) {
      setCenter({
        lat: defaultFieldValue?.geometry?.coordinates[1],
        lng: defaultFieldValue?.geometry?.coordinates[0],
      });
    }
  }, [defaultFieldValue]);

  const { t } = useTranslation();

  const containerStyle = {
    width: '100%',
    height: '589px',
    transition: 'width 0.2s ease-in-out, height 0.2s ease-in-out',
    borderRadius: '4px',
  };

  useEffect(() => {
    if (latitude && longitude) {
      setCenter({
        lat: latitude,
        lng: longitude,
      });
    }
  }, [latitude, longitude]);

  return (
    <>
      <Typography
        fontWeight={500}
        sx={{
          marginBottom: '16px',
        }}
      >
        {title}
      </Typography>

      <If
        condition={mainOption}
        otherwise={
          <LocationInput
            label={t('Add_Client_Address')}
            defaultValue={defaultFieldValue}
            size="small"
            onSelect={(loc) => setLocationValue(loc)}
            style={{ maxWidth: mapWidth }}
            disabled={!canEdit}
          />
        }
      >
        <Grid container alignItems="center">
          <div style={{ width: 'calc(80% - 10px)', marginRight: '16px' }}>
            <LocationInput
              label={t('Add_Client_Address')}
              defaultValue={defaultFieldValue}
              size="small"
              onSelect={(loc) => setLocationValue(loc)}
              style={{ maxWidth: mapWidth }}
              disabled={!canEdit}
            />
          </div>
          <FormGroup
            style={{ width: 'calc(20% - 16px)', marginBottom: '16px' }}
          >
            <Controller
              name="markAsMain"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      defaultChecked={Boolean(
                        control?.defaultValues?.markAsMain,
                      )}
                      checked={Boolean(field.value)}
                      onChange={(e) => field.onChange(e.target.checked)}
                      disabled={!canEdit}
                    />
                  }
                  label={t('customer.field.markAsMain')}
                />
              )}
            />
          </FormGroup>
        </Grid>
      </If>

      <MapWrapper mapHeight={mapHeight} mapWidth={mapWidth}>
        <GoogleMapComponent
          defaultCenter={center}
          defaultZoom={15}
          containerStyle={containerStyle}
          disableDefaultUI={true}
        >
          <>
            <Marker position={center} icon={assets?.mapMarker} />
            <StreetViewButton
              streetView={streetViewVisible}
              onClick={() => {
                setStreetViewVisible(!streetViewVisible);
              }}
            >
              <Crosshair size={20} color={themes?.default?.iconColor} />
            </StreetViewButton>
            <StreetViewPanorama
              options={{
                position: { lat: latitude, lng: longitude },
                visible: streetViewVisible,
                disableDefaultUI: true,
                enableCloseButton: false,
                markerIconUrl: MarkerPinBlue,
              }}
            />
          </>
        </GoogleMapComponent>
      </MapWrapper>
    </>
  );
}

const MapWrapper = styled.div<MapProps>`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.mapWidth};
  height: ${(props) => props.mapHeight};
  z-index: 2100;
  border-radius: 4px;
  margin-right: 17px;
  overflow: hidden;
`;

const MapButton = styled.div((props) => ({
  position: 'absolute',
  right: '51px',
  top: '15px',
  cursor: 'pointer',
  color: props.theme.icon,
  padding: '5px',
  backgroundColor: 'rgb(255,255,255)',
  borderRadius: '4px',
  zIndex: '1500',
  boxShadow: '1px 1px 4px 1px rgba(0, 0, 0, 0.25)',
}));

interface StreetViewButtonProps {
  streetView: boolean;
  onClick: () => void;
  children: React.ReactNode;
}

export const StreetViewButton = styled(MapButton)<StreetViewButtonProps>`
  right: 15px;
  background-color: ${(props) =>
    props.streetView ? props.theme.blueToggledButton : ''};
`;
