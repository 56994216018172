import { ColDef } from '@ag-grid-community/core';
import i18next from 'i18next';

export function getColumnDefs<U extends object>(
  memoData: U[],
  customColumnConfig,
) {
  if (!memoData.length) return [];
  return Object.keys(memoData[0])
    .filter((key) => key !== '__typename' && key !== 'id')
    .map((key, index) => {
      const col = customColumnConfig?.[index];
      const defaultHeaderName = key.startsWith('__')
        ? ''
        : key.split('_').join(' ');

      const columnValues = {
        field: key,
        unSortIcon: !!col?.sortField,
        sortable: !!col?.sortField,
        ...col,
        headerName: col?.headerName ?? defaultHeaderName,
        pinned: col?.lockPosition ? col?.lockPosition : col?.pinned,
        lockPinned: col?.lockPosition ? true : col?.lockPinned,
        suppressMovable:
          col?.suppressMovable ||
          (col?.pinned === 'right' && !!col?.lockPinned),
        resizable:
          col?.resizable !== false &&
          col?.pinned !== 'right' &&
          !col?.lockPinned,
      };
      return {
        ...columnValues,
        minWidth: calculateMinWidth(columnValues),
        ...(col?.pinned === 'right'
          ? {
              maxWidth: calculateMinWidth(columnValues),
            }
          : null),
      };
    });
}

export const calculateMinWidth = (columnDef: ColDef) => {
  const name = columnDef.headerName || '';
  const span = document.createElement('span');
  span.style.visibility = 'hidden';
  span.style.position = 'absolute';
  span.style.whiteSpace = 'nowrap';
  span.innerHTML = i18next.t(name);
  document.body.appendChild(span);

  const textWidth = span.offsetWidth;
  document.body.removeChild(span);

  if (!name) return columnDef.minWidth;

  const contentPadding = 32;
  const sortIconWidth = !!columnDef.sortable ? 24 : 0;
  const menuIconWidth = !columnDef.lockPinned ? 24 : 0;
  const pinIconWidth = !!columnDef.pinned ? 16 : 0;

  return Math.max(
    textWidth + contentPadding + sortIconWidth + menuIconWidth + pinIconWidth,
    columnDef.minWidth || 0,
  );
};

export const getColumnOrderPriority = (col) => {
  if (col?.lockPosition === 'left') return -3; // Locked left columns first
  if (col?.pinned === 'left' && col?.lockPinned) return -2; // Locked pinned left columns next
  if (col?.pinned === 'left') return -1; // Regular left pinned columns
  if (col?.lockPosition === 'right') return 3; // Locked right columns last
  if (col?.pinned === 'right' && col?.lockPinned) return 2; // Locked pinned right columns
  if (col?.pinned === 'right') return 1; // Regular right pinned columns
  return 0; // Unpinned columns in original order
};
