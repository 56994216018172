import { FormControlLabel, Popover, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FilterContainer,
  FilterName,
  FilterSection,
} from 'app/components/FilterComponents/styles';
import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { handleSelectFilterChange } from 'app/helpers/helpers';
import { useSelector } from 'react-redux';
import {
  selectCreditScores,
  selectOrganization,
} from 'common/store/organization/selectors';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useLazyGetUsersWebQuery } from 'common/services/userApi';
import { useQueryParams } from 'hooks/useQueryParams';
import { useGetCustomersQuery } from 'common/services/customerApi';
import { themes } from 'styles/theme/themes';
import If from 'app/components/If';
import { getAgent, getAgentFullName } from 'common/helpers/agent';
import { useAbility } from 'hooks/Abilities';
import { clientStatusColor } from 'common/utils/clientStatusColor';
import { useGetClientGroupsOptionsQuery } from 'common/services/clientGroupsApi';

interface VehicleFilterProps {
  setFilters: React.Dispatch<
    React.SetStateAction<{
      status: { value: string; label: string }[];
      customerTypes: { value: string; label: string }[];
      salesman: { value: string; label: string }[];
      creditScores: { value: string; label: string }[];
      lastOrderStatus: { value: string; label: string }[];
      isArchived: boolean;
    }>
  >;
  filterOn: HTMLButtonElement | null;
  handleCloseFilter: () => void;
  filters: {
    status: { value: string; label: string }[];
    customerTypes: { value: string; label: string }[];
    salesman: { value: string; label: string }[];
    creditScores: { value: string; label: string }[];
    lastOrderStatus: { value: string; label: string }[];
    isArchived: boolean;
  };
}

const CustomerFilter = ({
  filters,
  setFilters,
  filterOn,
  handleCloseFilter,
}: VehicleFilterProps) => {
  const ability = useAbility();

  const [triggerSalesmen, { data: salesData }] = useLazyGetUsersWebQuery();
  const { data: facetsData } = useGetCustomersQuery('page=1&limit=1');

  const { t } = useTranslation();

  const openFilter = Boolean(filterOn);
  const popOverId = openFilter ? 'filter' : undefined;

  const authOrganization = useSelector(selectOrganization);
  const creditScores = useSelector(selectCreditScores);
  const lastOrderStatuses = ['active', 'pending', 'stopped'];
  const { data: clientGroupsOptions } = useGetClientGroupsOptionsQuery('');

  // const orderFrequency = ['increase', 'stable', 'decrease'];

  const facets = useMemo(() => {
    return facetsData?.facets?.[0] || {};
  }, [facetsData]);

  const salesQueryParams = useQueryParams({
    roles: 'sales',
    context: 'users',
    page: 1,
    limit: 20,
  });

  const salesmen = useMemo(() => {
    return salesData?.docs || [];
  }, [salesData]);

  const searchSalesmen = useCallback(
    (search: string) => {
      triggerSalesmen(`${salesQueryParams}&text=${search}`);
    },
    [triggerSalesmen, salesQueryParams],
  );

  const toggleArchived = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setFilters({
        ...filters,
        isArchived: true,
      });
    } else {
      setFilters({
        ...filters,
        isArchived: false,
      });
    }
  };
  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    handleSelectFilterChange(filterKey, options, setFilters);
  };

  const handleClearAll = () => {
    setFilters({
      status: [],
      customerTypes: [],
      salesman: [],
      creditScores: [],
      lastOrderStatus: [],
      isArchived: false,
    });
  };

  useEffect(() => {
    if (ability.can('canview', 'users')) {
      searchSalesmen('');
    }
  }, [searchSalesmen]);

  return (
    <Popover
      id={popOverId}
      open={openFilter}
      anchorEl={filterOn}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <FilterContainer>
        <FilterHeader onClearAll={handleClearAll} />
        <FilterSection>
          {/* <CheckboxGrid
            openByDefault={true}
            filterName={t('common.status')}
            checkedValues={checkedValues}
            handleFilterChange={(e, label) => {
              handleCheckboxChange(e, label, 'status');
            }}
            filterItems={statusesFilterItems?.map(status => {
              return {
                value: status,
                label: t(`customer.status.${status}`),
                count: facets?.status?.find((stat: any) => stat._id === status)
                  ?.count,
              };
            })}
          /> */}
          <MultiSelect
            filterName={t('customer.type')}
            selectedOptions={filters.customerTypes}
            handleFilterChange={(options) => {
              handleOptionsChange('customerTypes', options);
            }}
            filterItems={clientGroupsOptions?.map((type) => {
              return {
                value: type._id,
                label: type?.label,
                count: facets?._customerType?.find(
                  (stat: any) => stat._id === type._id,
                )?.count,
              };
            })}
          />
          {/* <CheckboxGrid
            openByDefault={false}
            filterName={t('customer.frequently_order')}
            checkedValues={checkedValues}
            handleFilterChange={(e, label) => { }}
            filterItems={orderFrequency?.map(frequency => {
              return { value: frequency, label: t(`customer.frequency.${frequency}`) }
            })} /> */}

          <If condition={!!salesmen?.length}>
            <MultiSelect
              filterName={t('customer.salesman')}
              selectedOptions={filters.salesman}
              handleFilterChange={(options) => {
                handleOptionsChange('salesman', options);
              }}
              filterItems={salesmen?.map((sale) => {
                const preferredAgent = getAgent(sale, authOrganization);

                return {
                  value: sale._id,
                  label: getAgentFullName(preferredAgent),
                  count: facets?._salesman?.find(
                    (stat: any) => stat._id === sale._id,
                  )?.count,
                };
              })}
              onSearch={searchSalesmen}
            />
          </If>

          <MultiSelect
            filterName={t('customer.lastOrderDate')}
            selectedOptions={filters.lastOrderStatus}
            handleFilterChange={(options) => {
              handleOptionsChange('lastOrderStatus', options);
            }}
            filterItems={lastOrderStatuses?.map((status) => ({
              value: status,
              label: t(`customer.lastOrder.${status}`),
              color: clientStatusColor[status || 'other']?.color,
            }))}
          />

          <MultiSelect
            filterName={t('customer.creditScore')}
            selectedOptions={filters.creditScores}
            handleFilterChange={(options) => {
              handleOptionsChange('creditScores', options);
            }}
            filterItems={creditScores?.map((score) => {
              return { value: score, label: score };
            })}
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                onChange={toggleArchived}
                checked={filters.isArchived}
                value={filters.isArchived}
              />
            }
            label={<FilterName>{t(`custmers.show_archived`)}</FilterName>}
            sx={{
              marginLeft: 0,
              marginTop: '16px',
              color: filters.isArchived
                ? themes.default.primaryActiveColor
                : '',
            }}
          />
        </FilterSection>
      </FilterContainer>
    </Popover>
  );
};

export default CustomerFilter;
