import React from 'react';
import styled from 'styled-components';
import { Icon } from 'app/components/Icon';
import { CaretDown } from '@phosphor-icons/react';
import { IconButton, Tooltip } from '@mui/material';

interface Props {
  icon: JSX.Element;
  isDropDown?: boolean;
  tooltip?: string;
  active?: boolean;
  onClick?: any;
}

export const ToolbarButton: React.FC<Props> = ({
  icon,
  isDropDown,
  tooltip,
  active,
  onClick,
}) => {
  return (
    <Wrapper>
      <IconButtonWrapper>
        <Tooltip title={tooltip}>
          <IconButton onClick={onClick}>
            <Icon icon={icon} size={16} />
          </IconButton>
        </Tooltip>
      </IconButtonWrapper>
      {isDropDown && (
        <DropDownIcon active={!!active}>
          <Icon icon={<CaretDown weight="fill" />} size={12} />
        </DropDownIcon>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const DropDownIcon = styled.div<{ active: boolean }>`
  transform: ${(props) => (props.active ? 'rotate(-180deg)' : '')};
  transition: all 0.3s;
`;

const IconButtonWrapper = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
