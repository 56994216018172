import { CategoryCell } from './components/Cells/CategoryCell';
import { NameCell } from './components/Cells/NameCell';
import { UCCell } from './components/Cells/UCCell';
import { WeightCell } from './components/Cells/WeightCell';
import { StockCell } from './components/Cells/StockCell';
import { PriceCell } from './components/Cells/PriceCell';
import { QuantityInputCell } from './components/Cells/QuantityInputCell';
import { ActionsMenuCell } from './components/Cells/ActionsMenuCell';
import { FavBtnCell } from './components/Cells/FavBtnCell';
import { FlagCell } from './components/Cells/FlagCell';
import { IColDef } from 'app/components/ManagedGrid/types';

export const getColumnConfig = (t: (key: string) => string): IColDef[] => [
  {
    cellRenderer: FavBtnCell,
    width: 20,
    initialPinned: true,
    suppressMovable: true,
    resizable: false,
    lockPinned: true,
    cellStyle: {
      padding: '0px',
      width: '20px',
      overflow: 'visible',
    },
  },
  {
    cellRenderer: FlagCell,
    width: 20,
    initialPinned: true,
    suppressMovable: true,
    resizable: false,
    lockPinned: true,
    cellStyle: {
      padding: '0px',
      width: '20px',
      overflow: 'visible',
    },
  },
  {
    cellRenderer: NameCell,
    minWidth: 280,
    resizable: false,
    initialPinned: true,
    flex: 1,
    lockVisible: true,
    sortField: 'name',
    headerName: t('orderDetail.tab.product'),
  },
  {
    cellRenderer: CategoryCell,
    minWidth: 247,
    resizable: false,
    flex: 1,
    sortField: 'category',
    headerName: t('common.category'),
  },
  {
    cellRenderer: UCCell,
    minWidth: 136,
    resizable: false,
    flex: 1,
    sortField: 'uc',
    headerName: 'UC',
  },
  {
    cellRenderer: WeightCell,
    minWidth: 101,
    resizable: false,
    flex: 1,
    sortField: 'weight',
    headerName: t('roundtrips.others.weight'),
  },
  {
    cellRenderer: StockCell,
    minWidth: 109,
    resizable: false,
    flex: 1,
    sortField: 'stock',
    headerName: t('nav.mainNav.stock'),
  },
  {
    cellRenderer: PriceCell,
    minWidth: 129,
    resizable: false,
    flex: 1,
    sortField: 'stock',
    headerName: t('order.filter.price'),
  },
  {
    cellRenderer: QuantityInputCell,
    minWidth: 131,
    resizable: false,
    flex: 1,
    pinned: 'right',
    initialPinned: true,
    headerName: t('common.quantity'),
  },
  {
    cellRenderer: ActionsMenuCell,
    width: 52,
    resizable: false,
    lockPinned: true,
    pinned: 'right',
    initialPinned: true,
    suppressMovable: true,
  },
];
