import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Autocomplete,
  Stack,
  Box,
  SxProps,
  AutocompleteProps,
  TextFieldProps,
} from '@mui/material';
import { CrosshairSimple } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';
import { Address } from 'types';
import { GoogleMapComponent } from '../GoogleMapComponent';
import { Marker } from '@vis.gl/react-google-maps';
import { useTranslation } from 'react-i18next';
import If from '../If';
import { AddressProviderApi } from 'common/constants';
import { useDebounce } from 'common/hooks/useDebounce';
import { useApplicationAssets } from 'common/hooks/useApplicationAssets';

interface LocationPickerProps {
  defaultAddress?: Address;
  onChange: (address: Address | null) => void;
  withMap?: boolean;
  mapContainerSx?: SxProps;
  disabled?: boolean;
  isRequired?: boolean;
  autocompleteProps?: Partial<AutocompleteProps<string, false, true, true>> & {
    textFieldProps?: Partial<TextFieldProps>;
  };
}

const DEFAULT_LATITUDE = 49.029045;
const DEFAULT_LONGITUDE = 2.8033632;

const LocationPicker: React.FC<LocationPickerProps> = ({
  defaultAddress,
  onChange,
  withMap = true,
  mapContainerSx,
  disabled,
  isRequired,
  autocompleteProps,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState(defaultAddress?.formattedAddress || '');
  const [suggestions, setSuggestions] = useState<Address[]>([]);
  const [clicked, setClicked] = useState(false);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [location, setLocation] = useState({
    lat: defaultAddress?.geometry.coordinates[1] || DEFAULT_LATITUDE,
    lng: defaultAddress?.geometry.coordinates[0] || DEFAULT_LONGITUDE,
  });
  const [isEmpty, setIsEmpty] = useState<boolean>(
    !defaultAddress?.formattedAddress,
  );

  const assets = useApplicationAssets();

  useEffect(() => {
    if (defaultAddress?.formattedAddress) {
      setIsEmpty(false);
      setQuery(defaultAddress.formattedAddress);
      setLocation({
        lat: defaultAddress.geometry.coordinates[1],
        lng: defaultAddress.geometry.coordinates[0],
      });
    }
  }, [defaultAddress]);

  const debouncedQuery = useDebounce(query, 500);

  const reverseGeocode = async (latitude: number, longitude: number) => {
    try {
      const url = `${AddressProviderApi.reverse}?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
      const response = await fetch(url);
      const data = await response.json();

      return {
        name: data.display_name,
        city:
          data.address.city || data.address.town || data.address.village || '',
        department: data.address.county || '',
        region: data.address.state || '',
        continent: data.address.continent || '',
        zipCode: data.address.postcode || '',
        formattedAddress: data.display_name,
        shortAddress: `${data.address.road || ''}, ${data.address.city || ''}`,
        countryCode: data.address.country_code || '',
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      };
    } catch (error) {
      console.error('Error fetching address:', error);
      return null;
    }
  };

  const getSuggestions = useCallback(async (value) => {
    try {
      const url = `${
        AddressProviderApi.search
      }?format=json&q=${encodeURIComponent(value)}&addressdetails=1&limit=5`;
      const response = await fetch(url);
      const results = await response.json();

      if (results?.length) {
        const fetchedSuggestions = results.map((data) => ({
          name: data.display_name,
          city:
            data.address.city ||
            data.address.town ||
            data.address.village ||
            '',
          department: data.address.county || '',
          region: data.address.state || '',
          continent: data.address.continent || '',
          zipCode: data.address.postcode || '',
          formattedAddress: data.display_name,
          shortAddress: `${data.address.road || ''}, ${
            data.address.city || ''
          }`,
          countryCode: data.address.country_code || '',
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(data.lon), parseFloat(data.lat)],
          },
        }));
        setSuggestions(fetchedSuggestions);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  }, []);

  const handleGeolocationClick = async () => {
    setClicked(true);
    setValidationError(false);
    try {
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        },
      );
      const { latitude, longitude } = position.coords;

      const address = await reverseGeocode(latitude, longitude);
      if (address) {
        setQuery(address.formattedAddress);
        setLocation({ lat: latitude, lng: longitude });
        onChange(address);
        setSuggestions([]);
      }
    } catch (error) {
      console.error('Error getting location:', error);
      setValidationError(true);
    }
  };

  const handleInputChange = async (
    event: React.SyntheticEvent,
    value: string,
  ) => {
    setQuery(value);
    setValidationError(false);
    setIsEmpty(!value.trim());

    if (!value) {
      setSuggestions([]);
      onChange(null);
      return;
    }
  };

  const handleSuggestionSelect = (
    event: React.SyntheticEvent,
    value: string | null,
  ) => {
    if (value) {
      const selectedLocation = suggestions.find(
        (suggestion) => suggestion.formattedAddress === value,
      );
      if (selectedLocation) {
        setValidationError(false);
        onChange(selectedLocation);
        setQuery(value);
        setLocation({
          lat: selectedLocation.geometry.coordinates[1],
          lng: selectedLocation.geometry.coordinates[0],
        });
      }
    } else {
      onChange(null);
    }
  };

  const handleBlur = () => {
    if (!query.trim()) {
      setIsEmpty(true);
      onChange(null);
      return;
    }
    const match = suggestions.find(
      (s) => s.formattedAddress?.toLowerCase() === query?.toLowerCase(),
    );
    if (!match) {
      setValidationError(true);
      return;
    } else {
      setValidationError(false);
      setLocation({
        lat: match.geometry.coordinates[1],
        lng: match.geometry.coordinates[0],
      });
      onChange(match);
    }
  };

  const handleMarkerDragEnd = async (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();

    if (lat && lng) {
      setLocation({ lat, lng });
      const address = await reverseGeocode(lat, lng);
      if (address) {
        setQuery(address.formattedAddress);
        setValidationError(false);
        onChange(address);
      }
    }
  };

  useEffect(() => {
    getSuggestions(debouncedQuery);
  }, [debouncedQuery, getSuggestions]);

  const errorText = useMemo(() => {
    if (!!validationError) {
      return t('invalid_address');
    }
    if (!!isRequired && isEmpty) {
      return `${t('common.address')} ${t('is_required')}`;
    }
    return '';
  }, [isEmpty, isRequired, t, validationError]);

  return (
    <Stack>
      <Autocomplete
        freeSolo
        options={suggestions.map((suggestion) => suggestion.formattedAddress)}
        onInputChange={handleInputChange}
        onChange={handleSuggestionSelect}
        value={query}
        onBlur={handleBlur}
        {...autocompleteProps}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('common.address')}
            placeholder={t('common.address')}
            fullWidth
            size="small"
            error={!!validationError || (!!isRequired && isEmpty)}
            helperText={errorText}
            {...autocompleteProps?.textFieldProps}
            InputProps={{
              sx: {
                paddingRight: '6px !important',
              },
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleGeolocationClick}
                    sx={{ p: '4px' }}
                    disabled={disabled}
                  >
                    <CrosshairSimple
                      size={25}
                      style={{
                        color: clicked
                          ? themes?.default?.primaryActiveColor
                          : themes?.default?.iconColor,
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required={!!isRequired}
          />
        )}
        disabled={disabled}
      />
      <If condition={withMap}>
        <Box height="165px" sx={mapContainerSx}>
          <GoogleMapComponent
            defaultZoom={11}
            defaultCenter={location}
            disableDefaultUI={true}
          >
            <Marker
              position={location}
              draggable={!disabled}
              onDragEnd={handleMarkerDragEnd}
              icon={assets?.mapMarker}
            />
          </GoogleMapComponent>
        </Box>
      </If>
    </Stack>
  );
};

export default LocationPicker;
