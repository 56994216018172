import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { useGetProductsQuery } from 'common/services/productApi';
import ManagedGrid from 'app/components/ManagedGrid';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { getProcessedCatalogFunction } from '../../helpers';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useQueryParams } from 'hooks/useQueryParams';
import { ProductDetailsDrawer } from 'app/pages/Ordoria/Orders/components/ProductDetailsDrawer';
import styled from 'styled-components';
import { CatalogContext } from '../..';
import NoOrders from 'assets/img/Orders/no_orders_4.svg';
import { useTranslation } from 'react-i18next';
import { GRID } from 'app/components/TableComponent/constants';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { BulkActionsToolbar } from '../BulkActionsToolbar';
// import { useToaster } from 'hooks/useToaster';

export function FunctionalCatalog() {
  /* ------------------------------ Contexts ------------------------------ */
  const gridContext = useManagedContext<IGridContext>('grid');
  const {
    setViewMode,
    selectedProducts,
    setSelectedProducts,
    isCustomer,
    drawerOpenForProduct,
    setDrawerOpenForProduct,
  } = useContext(CatalogContext);

  /* ------------------------------ Variables ----------------------------- */
  const {
    queryParams: {
      page,
      rowsPerPage,
      order,
      orderBy,
      search,
      // filters,
      // status,
    },
  } = gridContext;
  const theme = useSelector(selectTheme);

  /* ----------------------------- Use States ----------------------------- */
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState<boolean>(false);

  /* -------------------------------- Hooks ------------------------------- */
  const { t } = useTranslation();
  const fetchQueryParams = useQueryParams({
    search,
    sort: `${order}${orderBy}`,
    page,
    limit: rowsPerPage,
  });

  /* ------------------------------ API Calls ----------------------------- */

  const {
    data: products,
    isLoading: isLoadingProducts,
    isFetching: isFetchingProducts,
  } = useGetProductsQuery(fetchQueryParams);

  /* ----------------------------- Use Effects ---------------------------- */
  useEffect(() => {
    if (!gridContext?.columnDefs[0]) return;
    gridContext.updateDataWithFunction((prev) => {
      prev.columnDefs[0].hide = !isCustomer;
    });
  }, [gridContext.columnDefs?.length, isCustomer]);

  // Set up grid rows
  useEffect(() => {
    if (isLoadingProducts || isFetchingProducts) {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = isFetchingProducts;
      });
      return;
    } else {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = false;
      });
    }
    if (!products || !products.docs) return;
    gridContext.updateDataWithFunction((prev) => {
      const processedEmployees = getProcessedCatalogFunction(products.docs);

      prev.totalRows = products.totalDocs;
      prev.rowData = processedEmployees;
      prev.loading = false;
      prev.responseData = products.docs;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingProducts,
    isFetchingProducts,
    products?.docs,
    products,
    gridContext.viewMode,
  ]);

  useEffect(() => {
    handleOpenDetailsDrawer(gridContext.selectedRow);
  }, [gridContext.selectedRow]);

  /**
   * Unselect selected products after clicking
   * on dismiss button in BulkActionsToolbar component.
   */
  useEffect(() => {
    if (
      selectedProducts.grid.length !== gridContext.selectedRows.length &&
      gridContext.viewMode === GRID
    ) {
      gridContext.updateDataWithFunction((prev) => {
        gridContext.api.clearSelection();
        prev.selectedRows = selectedProducts.grid || [];
      });
    } else if (selectedProducts?.categories) {
      const allCategoryIds: any[] = Object.values(
        selectedProducts.categories,
      ).flat();
      gridContext.updateDataWithFunction((prev) => {
        prev.selectedRows = allCategoryIds;
      });
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (gridContext.viewMode === GRID) {
      setSelectedProducts({ grid: gridContext.selectedRows });
    }
  }, [gridContext.selectedRows]);

  useEffect(() => {
    setViewMode(gridContext.viewMode);
  }, [gridContext.viewMode]);

  /* ------------------------------ Use Memos ----------------------------- */
  const toolbarConfig = useMemo(
    () => ({
      disabled: gridContext.disableToolBar,
      // FilterComponent: EmployeeFilter,
      BulkActionsComponent: BulkActionsToolbar,
    }),
    [gridContext],
  );

  const noDataConfig = useMemo(
    () => ({
      icon: NoOrders,
      onClick: () => {},
      text: t('catalog.no_products'),
      IconButton: undefined,
      textButton: '',
    }),
    [],
  );

  /* ------------------------------ Functions ----------------------------- */

  const handleOpenDetailsDrawer = useCallback(
    (fullProduct) => {
      if (!fullProduct) return;
      setDetailsDrawerOpen(true);
      setDrawerOpenForProduct(fullProduct);
    },
    [setDetailsDrawerOpen, setDrawerOpenForProduct],
  );

  const handleCloseDetailsDrawer = useCallback(() => {
    setDetailsDrawerOpen(false);
    setDrawerOpenForProduct(null);
    gridContext.updateDataWithFunction((prev) => {
      prev.selectedRow = null;
    });
  }, []);

  /* ---------------------------------------------------------------------- */
  return (
    <Wrapper theme={theme}>
      <ManagedGrid toolbarConfig={toolbarConfig} noDataConfig={noDataConfig} />
      <ProductDetailsDrawer
        open={detailsDrawerOpen}
        product={drawerOpenForProduct}
        allProducts={products?.docs || []}
        handleChangeProduct={(product) => {
          gridContext.updateDataWithFunction((prev) => {
            prev.selectedRow = product;
          });
        }}
        handleClose={handleCloseDetailsDrawer}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ theme }>`
  flex: 1;
  .ag-theme-quartz,
  .ag-theme-quartz-dark,
  .ag-theme-quartz-auto-dark {
    --ag-border-radius: 2px;
  }

  .ag-center-cols-viewport {
    max-width: 100% !important;
  }

  .ag-pinned-left-cols-container .ag-cell {
    &:first-of-type {
      display: flex;
      align-items: center;
    }
  }

  .ag-selection-checkbox {
    height: 52px !important;
  }

  .ag-checkbox-input-wrapper::after {
    color: transparent;
    box-shadow: inset 0px 0px 0px 1px var(--ag-checkbox-unchecked-color);
    border-radius: var(--ag-border-radius);
  }

  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: var(--ag-checkbox-unchecked-color);
    box-shadow: inset 0px 0px 0px 3px var(--ag-checkbox-unchecked-color);
    border-radius: var(--ag-border-radius);
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: var(--ag-active-color);
    box-shadow: inset 0px 0px 0px 3px var(--ag-active-color);
    border-radius: var(--ag-border-radius);
  }
`;
