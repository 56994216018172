import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Popper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useManagedContext } from '../../../../../../../common/UtilityComponents/ManagedContext/useManagedContext';
// import { IWarehouseLocation } from '../../../../../../../common/types/Warehouses';
import { IGridContext } from '../../../../../../components/ManagedGrid/types';
import ColorPicker from './ColorPicker';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import LocationPicker from 'app/components/LocationPicker';
import { useQueryParams } from 'hooks/useQueryParams';
import { useToaster } from 'hooks/useToaster';
import { useGetUsersBasicQuery } from 'common/services/userApi';
import { PhoneInputField } from 'app/components/Form/PhoneInputField';
import {
  usePatchWarehouseMutation,
  usePostWarehouseMutation,
} from 'common/services/organizationApi';
import { useAsyncData } from 'hooks/useAsyncData';

function WarehousesDrawer() {
  const gridContext = useManagedContext<IGridContext>('grid');
  const { dayjs } = window;
  const { t } = useTranslation();
  const [isSwitchOn, setSwitchOn] = useState<boolean>(true);
  const [updateWarehouse] = usePatchWarehouseMutation();
  const [createWarehouse] = usePostWarehouseMutation();
  const schema = z.object({
    name: z
      .string()
      .min(1, { message: t('rules.required') })
      .refine(
        (val) => {
          const nameExist = gridContext.responseData.some(
            (item) =>
              item.name === val && item._id !== gridContext.selectedRow?._id, // Skip current warehouse
          );
          return !nameExist;
        },
        { message: t('name_already_exist') },
      ),
    shortName: z
      .string()
      .min(1)
      .max(3)
      .refine(
        (val) => {
          const initialsExist = gridContext.responseData.some(
            (item) =>
              item.shortName === val &&
              item._id !== gridContext.selectedRow?._id, // Skip current warehouse
          );
          return !initialsExist;
        },
        { message: t('initials-already-exist') },
      ),
    code: z
      .string()
      .min(1, { message: t('rules.required') })
      .refine(
        (val) => {
          const codeExist = gridContext.responseData.some(
            (item) =>
              item.code === val && item._id !== gridContext.selectedRow?._id, // Skip current warehouse
          );
          return !codeExist;
        },
        { message: t('warehouse-code-already-exist') },
      ),
    color: z.string().min(1),
    location: z
      .any()
      .refine((val) => val !== null && val !== undefined, {
        message: t('rules.required'),
      })
      .refine(
        (val) => {
          if (!val) return true; // Skip duplicate check if no value
          if (!gridContext.selectedRow) return true; // Skip check for new warehouses
          const locationExists = gridContext.responseData.some(
            (item) =>
              item._id !== gridContext.selectedRow?._id && // Skip current warehouse
              item.location?.cellData?.location?.formattedAddress ===
                val?.formattedAddress,
          );
          return !locationExists;
        },
        {
          message: t('location-already-exist'),
        },
      ),
    volumeCapacity: z.number().positive().optional(),
    _manager: z.string().optional(),
    phone: z.string().optional(),
    email: z.string().email().optional(),
    status: z.enum(['active', 'inactive']).default('active'),
    operatingHours: z
      .object({
        openingTime: z.string(),
        closingTime: z.string(),
      })
      .optional(),
  });
  useEffect(() => {
    if (gridContext.selectedRow) {
      setSwitchOn(gridContext.selectedRow.status === 'active');
      if (gridContext.selectedRow.openingHours?.[0]?.openingTime) {
        setOpeningTime(
          dayjs(gridContext.selectedRow.openingHours[0].openingTime, 'HH:mm'),
        );
      }
      if (gridContext.selectedRow.openingHours?.[0]?.closingTime) {
        setClosingTime(
          dayjs(gridContext.selectedRow.openingHours[0].closingTime, 'HH:mm'),
        );
      }
    }
  }, [gridContext.selectedRow]);
  const [openingTime, setOpeningTime] = useState<Dayjs | null>(() => {
    const time =
      gridContext.selectedRow?.operating_hours?.cellData?.openingHours[0]
        ?.openingTime;
    return time || dayjs().startOf('day');
  });
  const [closingTime, setClosingTime] = useState<Dayjs | null>(() => {
    const time =
      gridContext.selectedRow?.operating_hours?.cellData?.openingHours[0]
        ?.closingTime;
    return time || dayjs().startOf('day');
  });

  const toast = useToaster();

  const methods = useForm({
    defaultValues: {
      name: gridContext.selectedRow?.name || '',
      shortName: gridContext.selectedRow?.shortName || '',
      code: gridContext.selectedRow?.code || '',
      color: gridContext.selectedRow?.color || '',
      location: gridContext.selectedRow?.location || null,
      volumeCapacity: gridContext.selectedRow?.capacity || undefined,
      _manager: gridContext.selectedRow?._manager || null,
      phone: gridContext.selectedRow?.phone || '',
    },

    resolver: zodResolver(schema),
  });

  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = methods;

  const handleSwitchChange = () => {
    setSwitchOn((prevState) => !prevState);
  };

  const name = watch('name');
  const initials = watch('shortName');
  const color = watch('color');
  const warehouseLocation = watch('location');
  const code = watch('code');
  const capacity = watch('volumeCapacity');
  const manager = watch('_manager');
  const contact = watch('phone');

  const fetchQueryParams = useQueryParams({
    page: 1,
    limit: 50,
  });

  const { data: users } = useGetUsersBasicQuery(
    `${fetchQueryParams}&context=users`,
  );

  const { callApi } = useAsyncData(
    async () => {
      const formatClosingTime = closingTime?.format('HH:mm');
      const formatOpeningTime = openingTime?.format('HH:mm');
      const warehouseData = {
        name,
        code,
        shortName: initials,
        location: warehouseLocation,
        capacity,
        _manager: manager?._id,
        phone: contact,
        status: isSwitchOn ? 'active' : 'inactive',
        color,
        openingHours: [
          {
            openingTime: formatOpeningTime,
            closingTime: formatClosingTime,
          },
        ],
      };

      if (gridContext.selectedRow) {
        await updateWarehouse({
          ...warehouseData,
          deckId: gridContext.selectedRow._id,
        }).unwrap();
      } else {
        await createWarehouse(warehouseData).unwrap();
      }
    },
    {
      onSuccess: () => {
        gridContext.updateDataWithFunction((prev) => {
          prev.openDrawer = false;
          prev.selectedRow = null;
        });
        toast(
          5000,
          'success',
          gridContext.selectedRow
            ? t('warehouse-updated-successfully')
            : t('warehouse-created-successfully'),
        );
      },
      onError: () => {
        toast(
          5000,
          'error',
          gridContext.selectedRow
            ? t('error-updating-warehouse')
            : t('error-creating-warehouse'),
        );
      },
    },
  );
  async function handlePatchWarehouse() {
    callApi();
  }
  return (
    <TwoViewModal
      onClose={() => {
        gridContext.updateDataWithFunction((prev) => {
          prev.openDrawer = false;
          prev.selectedRow = null;
        });
      }}
      width="473px"
      onOpen={gridContext.openDrawer}
      title={
        gridContext.selectedRow
          ? gridContext.canEdit
            ? t('edit_warehouse')
            : t('view_warehouse')
          : t('add_warehouse')
      }
    >
      <Wrapper>
        <TextFieldWrapper>
          <Box
            sx={{
              display: 'flex',
              gap: '19px',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '19px',
              }}
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    size="small"
                    onChange={(e) => {
                      field.onChange(e);
                      if (errors.name) {
                        trigger('name');
                      }
                    }}
                    defaultValue={
                      gridContext.selectedRow
                        ? gridContext.selectedRow.name
                        : ''
                    }
                    sx={{
                      width: '317px',
                    }}
                    disabled={!gridContext.canEdit}
                    onBlur={() => trigger('name')}
                    label={t('add_warehouse.name')}
                    error={!!errors.name}
                    helperText={
                      !!errors.name ? String(errors.name.message) : ''
                    }
                  />
                )}
              />
              <Controller
                name="shortName"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    {...field}
                    size="small"
                    onChange={(e) => {
                      if (e.target.value.length <= 3) {
                        field.onChange(e);
                      }
                      if (errors.shortName) {
                        trigger('shortName');
                      }
                    }}
                    defaultValue={
                      gridContext.selectedRow?.shortName
                        ? gridContext.selectedRow.shortName
                        : ''
                    }
                    sx={{
                      width: '97px',
                    }}
                    disabled={!gridContext.canEdit}
                    onBlur={() => trigger('shortName')}
                    label={t('add_warehouse.initials')}
                    error={!!errors.shortName}
                    helperText={
                      !!errors.shortName ? String(errors.shortName.message) : ''
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <TextField
                required
                {...field}
                size="small"
                onChange={(e) => {
                  field.onChange(e);
                  if (errors.code) {
                    trigger('code');
                  }
                }}
                defaultValue={
                  gridContext.selectedRow?.code
                    ? gridContext.selectedRow.code
                    : ''
                }
                sx={{
                  width: '426.6px',
                }}
                disabled={!gridContext.canEdit}
                onBlur={() => trigger('code')}
                label={t('add-warehouse-code')}
                error={!!errors.code}
                helperText={!!errors.code ? String(errors.code.message) : ''}
              />
            )}
          />
          <ColorPicker
            required={true}
            name="color"
            control={control}
            label={t('common.color')}
            disabled={!gridContext.canEdit}
            colors={[
              '#EEEEEE',
              '#9FA8DA',
              '#90CAF9',
              '#80CBC4',
              '#C5E1A5',
              '#FFF59D',
              '#FFAB91',
              '#EF9A9A',
              '#F48FB1',
              '#CE93D8',
              '#B39DDB',
              '#BDBDBD',
            ]}
          />
          <Controller
            name="location"
            control={control}
            render={({ field: { onChange, value } }) => (
              <LocationPicker
                isRequired={true}
                defaultAddress={value}
                onChange={(loc) => {
                  onChange(loc);
                  if (errors.location) {
                    trigger('location');
                  }
                }}
                mapContainerSx={{
                  width: '433px',
                  height: '160px',
                  marginBottom: '23px',
                }}
                disabled={!gridContext.canEdit}
                autocompleteProps={{
                  textFieldProps: {
                    error: !!errors.location,
                    helperText: errors.location
                      ? String(errors.location.message)
                      : '',
                    onBlur: () => trigger('location'),
                  },
                }}
              />
            )}
          />
          <Controller
            name="_manager"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                options={users?.docs || []}
                getOptionLabel={(option) => option?.fullName || ''}
                isOptionEqualToValue={(option, value) =>
                  option._id === (value?._id || value)
                }
                value={
                  typeof field.value === 'string'
                    ? users?.docs?.find((user) => user._id === field.value) ||
                      null
                    : field.value
                }
                loading={!users?.docs}
                disabled={!gridContext.canEdit}
                disablePortal
                onChange={(_, value) => field.onChange(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('add-warehouse-manager')}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {!users?.docs && <CircularProgress size={18} />}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                ListboxComponent={(props) => (
                  <ul
                    {...props}
                    style={{
                      maxHeight: '190px', // Adjust the height of the dropdown list
                      overflowY: 'scroll', // Add scroll if needed when content exceeds max height
                      width: '100%',
                    }}
                  />
                )}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    placement="bottom-start"
                    style={{
                      zIndex: 1300,
                      width: '91%',
                    }}
                    modifiers={[
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 8],
                        },
                      },
                    ]}
                  />
                )}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{
              required: false,
            }}
            render={({ field }) => (
              <PhoneInputField
                disabled={!gridContext.canEdit}
                {...field}
                specialLabel="Contact"
                onChange={(value) => {
                  const formattedValue = value.startsWith('+')
                    ? value
                    : `+${value}`;
                  field.onChange(formattedValue);
                  if (errors.phone) {
                    trigger('phone');
                  }
                }}
              />
            )}
          />
        </TextFieldWrapper>
        <div
          style={{
            display: 'flex',
            maxHeight: '40px',
            marginTop: '18px',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label={t('add-warehouse-openingtime')}
              value={openingTime}
              onChange={(newValue) => setOpeningTime(newValue)}
              inputFormat="HH:mm"
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{
                    width: '204.5px',
                    height: '40px',
                    mr: '12px',
                    '& .MuiSvgIcon-root': {
                      marginLeft: '-7px',
                      marginRight: '0px',
                    },
                    '& .MuiInputAdornment-root': {
                      order: -15,
                    },
                  }}
                  size="small"
                />
              )}
              disabled={!gridContext.canEdit}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label={t('add-warehouse-closingtime')}
              value={closingTime}
              onChange={(newValue) => setClosingTime(newValue)}
              inputFormat="HH:mm"
              renderInput={(props) => (
                <TextField
                  {...props}
                  sx={{
                    width: '204.5px',
                    height: '40px',
                    mr: '12px',
                    '& .MuiSvgIcon-root': {
                      marginLeft: '-7px',
                      marginRight: '0px',
                    },
                    '& .MuiInputAdornment-root': {
                      order: -15,
                    },
                  }}
                  defaultValue={closingTime}
                  size="small"
                />
              )}
              disabled={!gridContext.canEdit}
            />
          </LocalizationProvider>
        </div>
        <div
          style={{
            display: 'flex',
            maxHeight: '40px',
            marginTop: '15px',
          }}
        >
          <Controller
            name="volumeCapacity"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                onChange={(e) => {
                  const value = e.target.value;

                  if (value === '') {
                    // Allow clearing the field
                    field.onChange(undefined);
                  } else {
                    const parsedValue = parseFloat(value);
                    if (!isNaN(parsedValue)) {
                      field.onChange(parsedValue);
                    }
                  }

                  if (errors.volumeCapacity) {
                    trigger('volumeCapacity');
                  }
                }}
                onBlur={() => {
                  const parsedValue = parseFloat(field.value);

                  field.onChange(parsedValue);

                  trigger('volumeCapacity');
                }}
                value={field.value === undefined ? '' : field.value}
                type="number"
                inputProps={{
                  min: 0,
                  step: 1,
                }}
                id="filled-number"
                defaultValue={
                  gridContext.selectedRow?.capacity
                    ? gridContext.selectedRow.capacity
                    : ''
                }
                sx={{
                  width: '204.5px',
                }}
                disabled={!gridContext.canEdit}
                label={t('add-warehouse-capacity')}
                error={!!errors.volumeCapacity}
                helperText={
                  !!errors.volumeCapacity
                    ? String(errors.volumeCapacity.message)
                    : ''
                }
                style={{
                  width: '204.5px',
                  maxWidth: '204.5px',
                }}
              />
            )}
          />
        </div>
        <FormGroup
          sx={{
            ml: '5px',
            mt: '20px',
            width: '140px',
          }}
        >
          {gridContext.selectedRow ? (
            <Typography component="div">
              <Switch
                disabled={!gridContext.canEdit}
                checked={isSwitchOn}
                onChange={handleSwitchChange}
              />
              {isSwitchOn
                ? t('add-warehouse-active')
                : t('add-warehouse-notactive')}
            </Typography>
          ) : (
            <FormControlLabel
              control={
                <Switch
                  disabled={!gridContext.canEdit}
                  checked={isSwitchOn}
                  onChange={handleSwitchChange}
                />
              }
              label={
                isSwitchOn
                  ? t('add-warehouse-active')
                  : t('add-warehouse-notactive')
              }
            />
          )}
        </FormGroup>
      </Wrapper>

      <FooterWrapper>
        <Button
          disableElevation
          sx={{ color: '#9E9E9E' }}
          onClick={() => {
            gridContext.updateDataWithFunction((prev) => {
              prev.openDrawer = false;
              prev.selectedRow = null;
            });
          }}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          variant="contained"
          sx={{ mr: '20px' }}
          onClick={() => handlePatchWarehouse()}
          disabled={
            !gridContext.canEdit ||
            Object.keys(errors).length > 0 ||
            !name ||
            !initials ||
            !color ||
            !warehouseLocation ||
            (gridContext.selectedRow &&
              name === gridContext.selectedRow?.name &&
              initials === gridContext.selectedRow?.shortName &&
              color === gridContext.selectedRow?.color &&
              _.isEqual(warehouseLocation, gridContext.selectedRow?.location) &&
              capacity === gridContext.selectedRow?.capacity &&
              manager === gridContext.selectedRow?.manager &&
              contact === gridContext.selectedRow?.phone &&
              isSwitchOn === (gridContext.selectedRow?.status === 'active'))
          }
        >
          {gridContext.selectedRow ? t('common.buttons.save') : t('common.add')}
        </Button>
      </FooterWrapper>
    </TwoViewModal>
  );
}
export default WarehousesDrawer;

const TextFieldWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
`;

const Wrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
`;

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  background: #fff;
`;

// const ButtonWrapper = styled.div`
//   margin-left: auto;
// `;
