import { themes } from 'styles/theme/themes';
import { OrderStatus } from './data/statuses';

export const OrderStatusesOptions = {
  [OrderStatus.Pending]: { dotColor: themes.default.textPending },
  [OrderStatus.PendingCustomer]: {
    dotColor: themes.default.textPendingCustomer,
  },
  [OrderStatus.Validated]: { dotColor: themes.default.blue900 },
  [OrderStatus.ToBePrepared]: { dotColor: themes.default.teal800 },
  [OrderStatus.Prepared]: { dotColor: themes.default.purple },
  [OrderStatus.Loaded]: { dotColor: themes.default.lightblue50 },
  [OrderStatus.ReadyForDelivery]: { dotColor: themes.default.lightGreen600 },
  [OrderStatus.OnTheRoad]: { dotColor: themes.default.orange600 },
  [OrderStatus.Delivered]: { dotColor: themes.default.textDelivered },
  [OrderStatus.Canceled]: { dotColor: themes.default.errorMain },
  [OrderStatus.Unloaded]: { dotColor: themes.default.tapa },
  [OrderStatus.Blocked]: { dotColor: themes.default.brown },
  [OrderStatus.Postponed]: { dotColor: themes.default.grey2 },
  [OrderStatus.Deleted]: { dotColor: themes.default.brown },
  [OrderStatus.Initiated]: { dotColor: themes.default.whisper },
  [OrderStatus.InitiatedCustomer]: { dotColor: themes.default.whisper },
};

export function getColorByOrderStatus(status: OrderStatus): string | undefined {
  return OrderStatusesOptions[status].dotColor || themes?.default?.grey400;
}
