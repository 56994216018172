/* eslint-disable @typescript-eslint/no-unused-vars */
import { getImgCdn } from 'common/helpers/cdn';
import NoPhoto from 'assets/img/ORDORIA/Catalog/NoPhoto.svg';
import { toTitleCase } from 'common/helpers/stringFormatter';

export function getProcessedCatalogFunction(data) {
  return data?.map((prod, index) => {
    const pictureKey = prod?.pictures?.length > 0 ? prod?.pictures[0]?.key : '';

    const id = prod?._id || index;
    const favorite = prod?.favorite || false;

    const pictureUrl = !!pictureKey ? getImgCdn(pictureKey, 64) : NoPhoto;
    const code = prod?.code || '';
    const name = toTitleCase(prod?.name || '');
    const isNew = prod?.new || false;
    const category = prod?._category || '';
    const boxingUnit = prod?.boxingUnit || 0;
    const weight = prod?.weight?.toFixed(2);
    const stock = prod?.stock || 0;
    const discount = prod?.discount || 0;
    const price = prod?.boxPrice;

    return {
      id: prod._id,
      __favoriteIcon: {
        cellData: { favorite, id },
      },
      __flagIcon: {
        orderFrequency: prod?.orderFrequency,
      },
      product: {
        cellData: {
          id,
          pictureUrl,
          code,
          isNew,
          name,
          fullProduct: prod,
        },
      },
      category: {
        cellData: category,
      },
      uc: {
        cellData: boxingUnit,
      },
      weight: {
        cellData: weight,
      },
      stock: {
        cellData: stock,
      },
      price: {
        cellData: {
          price,
          discount,
        },
      },
      quantity: {
        cellData: {
          id,
        },
      },
      __actions: {
        cellData: {
          id: prod._id,
          product: prod,
        },
      },
    };
  });
}

export function getProcessedProductFunction(data, category) {
  return data?.map((prod, index) => {
    const pictureKey = prod?.pictures?.length > 0 ? prod?.pictures[0]?.key : '';

    const id = prod?._id || index;
    const favorite = prod?.favorite || false;

    const pictureUrl = !!pictureKey ? getImgCdn(pictureKey, 64) : NoPhoto;
    const code = prod?.code || '';
    const name = toTitleCase(prod?.name || '');
    const isNew = prod?.new || false;
    const boxingUnit = prod?.boxingUnit || 0;
    const weight = prod?.weight?.toFixed(2);
    const stock = prod?.stock || 0;
    const discount = prod?.discount || 0;
    const price = prod?.boxPrice;

    return {
      id: prod._id,
      __favoriteIcon: {
        cellData: { favorite, id },
      },
      __flagIcon: {
        orderFrequency: prod?.orderFrequency,
      },
      product: {
        cellData: {
          id,
          pictureUrl,
          code,
          isNew,
          name,
          fullProduct: prod,
        },
      },
      category: {
        cellData: category,
      },
      uc: {
        cellData: boxingUnit,
      },
      weight: {
        cellData: weight,
      },
      stock: {
        cellData: stock,
      },
      price: {
        cellData: {
          price,
          discount,
        },
      },
      quantity: {
        cellData: {
          id,
        },
      },
      __actions: {
        cellData: {
          id: prod._id,
          product: prod,
        },
      },
    };
  });
}
