import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Check } from '@phosphor-icons/react';
import useFormatCurrency from 'common/hooks/useFormatCurrency';
import { useCreateMultiPaymentMutation } from 'common/services/payments';

function InvoicesList({ invoices }) {
  const formatCurrency = useFormatCurrency();

  return (
    <Table sx={{ minWidth: '100%' }} aria-label="simple table">
      <TableBody>
        {invoices.map((row) => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <a href={row.initialUrl} target="_blank">
                #{row?.metadata?.invoiceCode || ''}
              </a>
            </TableCell>
            <TableCell align="right">
              {formatCurrency(row?.metadata?.structured?.totalAmountTax)}
            </TableCell>
          </TableRow>
        ))}
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            <Typography fontWeight="600">Total</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography fontWeight="600">
              {formatCurrency(
                invoices.reduce(
                  (acc, curr) =>
                    acc + curr.metadata?.structured?.totalAmountTax,
                  0,
                ),
              )}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default function ConfirmPaymentsModal({ items, open, onClose }) {
  const { t } = useTranslation();
  const [createPayment, { isLoading: paymentIsLoading }] =
    useCreateMultiPaymentMutation();

  const handleConfirmPayment = async () => {
    const invoiceIds = items.map((item) => item._id);

    const res = await createPayment({
      okUrl: `${window.location.origin}/ordoria/transactions/invoices?payment=ok`,
      koUrl: `${window.location.origin}/ordoria/transactions/invoices?payment=ko`,
      fileIds: invoiceIds,
    }).unwrap();

    if (res?.paymentUrl) {
      window.open(res.paymentUrl, '_blank');
    }
  };

  return (
    <Dialog
      open={open}
      PaperComponent={Paper}
      keepMounted
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      sx={{ zIndex: 9999 }}
    >
      <DialogTitle>
        <Typography color="black" fontWeight="600">
          Confirmer le paiement ({items.length} factures)
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <InvoicesList invoices={items} />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          padding: '16px',
        }}
      >
        {paymentIsLoading ? (
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={handleConfirmPayment}
          >
            <Check
              color="white"
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
            />
            <Typography color="white" fontWeight="500" fontSize="13px">
              {t('customerPortal.documents.payOnlineInProgress')}
            </Typography>
          </LoadingButton>
        ) : (
          <Button
            color="primary"
            variant="contained"
            disabled={items.length === 0}
            onClick={handleConfirmPayment}
          >
            <Check
              color="white"
              style={{
                width: '16px',
                height: '16px',
                marginRight: '4px',
              }}
            />
            <Typography color="white" fontWeight="500" fontSize="13px">
              {t('customerPortal.documents.payOnline')}
            </Typography>
          </Button>
        )}
        <Button onClick={onClose}>{t('common.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
}
