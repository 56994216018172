import React, { useMemo } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
  state: string;
}

// product_state.raw_frozen
// product_state.pre-pushed
// product_state.precooked_frozen
// product_state.cooked_frozen

export const CookState: React.FC<Props> = ({ state }) => {
  const { t } = useTranslation();

  const color = useMemo(() => {
    if (state === 'raw_frozen') {
      return themes?.default?.skyBlue;
    } else if (state === 'pre-pushed') {
      return themes?.default?.progressGreen;
    } else if (state === 'precooked_frozen') {
      return themes?.default?.Saffron;
    } else if (state === 'cooked_frozen') {
      return themes?.default?.darkRed;
    } else return '';
  }, [state]);

  return (
    <Tooltip title={t(`product_state.${state}`)}>
      <Union color={color}>
        <Circle color={color} />
      </Union>
    </Tooltip>
  );
};

const Union = styled.div<{ color }>`
  width: 16px;
  height: 16px;
  background-image: linear-gradient(
    0deg,
    ${(props) => props.color} 50%,
    transparent 50%
  );
`;

const Circle = styled.div<{ color }>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: ${(props) => props.color};
`;
