import { Box } from '@mui/material';
import { NestedMenu } from 'app/components/CustomMenu/NestedMenu';
import { ActionProps, QuickActionProps } from 'app/components/CustomMenu/types';

interface Props {
  actions: ActionProps[];
  quickActions?: QuickActionProps[];
}

export const ActionComponent = ({ actions, quickActions }: Props) => {
  return (
    <Box className="row-actions-menu">
      <NestedMenu actions={actions} quickActions={quickActions} />
    </Box>
  );
};
