import { useApplication } from 'hooks/useApplication';
import MarkerPrimaryOrdoria from 'assets/img/map/CustomerMap/MarkerPrimaryOrdoria.svg';

export function useApplicationAssets() {
  const currentApplication = useApplication().id;

  const assets = {
    ordoria: {
      mapMarker: MarkerPrimaryOrdoria,
    },
  };

  return assets[currentApplication];
}
