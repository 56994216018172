import { Stack, Typography } from '@mui/material';
import EmptyStateIllustration from 'assets/img/ORDORIA/Cart/EmptyStateIllustration.svg';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

export const EmptyCartLayout = () => {
  const { t } = useTranslation();
  return (
    <Stack justifyContent="center" alignItems="center" height="80%" gap="12px">
      <img src={EmptyStateIllustration} alt="" />
      <Typography
        marginTop="-46px"
        fontSize="0.75rem"
        textAlign="center"
        color={themes?.default?.textColorPrimary}
      >
        {t('cart.empty')}
      </Typography>
    </Stack>
  );
};
