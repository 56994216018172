import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Avatar, Tooltip, Typography } from '@mui/material';
import { stringAvatar } from 'app/helpers/helpers';

function SalesCell(params: CustomCellRendererProps) {
  return (
    <Tooltip
      title={
        params.value.firstNameSalesData + ' ' + params.value.lastNameSalesData
      }
    >
      <Avatar
        sx={{
          width: '24px',
          height: '24px',
          cursor: 'pointer',
        }}
      >
        <Typography fontSize="0.75rem">
          {stringAvatar(
            params.value.firstNameSalesData +
              ' ' +
              params.value.lastNameSalesData,
          )}
        </Typography>
      </Avatar>
    </Tooltip>
  );
}

export default SalesCell;
