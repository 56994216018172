import { CustomCellRendererProps } from '@ag-grid-community/react';
import { Grid, Typography } from '@mui/material';
import { HourglassSimpleMedium } from '@phosphor-icons/react';
import moment from 'moment';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

function ReportedDateCell(params: CustomCellRendererProps) {
  const reportDate = moment(params.value.reportDateData).format(
    'MM-DD-YYYY hh:mma',
  );
  const formattedDate = moment(params.value.reportDateData).fromNow() ?? '__';

  return (
    <Grid container alignItems="center" gap="8px">
      <Typography fontSize="0.75rem">{reportDate ?? '__'}</Typography>
      <FormattedDateDiv>
        <HourglassSimpleMedium color={themes?.default?.tooltipReport} />
        <Typography
          fontSize="0.75rem"
          fontWeight={500}
          color={themes?.default?.tooltipReport}
          mt="1px"
        >
          {formattedDate}
        </Typography>
      </FormattedDateDiv>
    </Grid>
  );
}

export default ReportedDateCell;

const FormattedDateDiv = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  width: fit-content;
  gap: 4px;
  padding: 3px 4px;
  background-color: ${themes?.default?.whisper};
  border-radius: 2px;
`;
