import { AgGridReact } from '@ag-grid-community/react';
import { Collapse } from '@mui/material';
import { IGridContext } from 'app/components/ManagedGrid/types';
import ManagedGridList from 'app/components/ManagedGridList';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { getProcessedMultiplePaiements } from '../../../helpers';
import { CategoryTitle } from '../Title';
import { ListPaymentContext } from '../../ListPaymentProvider';

export function ListCollapsablePayment({
  date,
  period,
  expanded,
  onToggle,
  isLoading,
}) {
  const [processedPayments, setProcessedPayments] = useState<any[]>([]);
  const [processedRow, setProcessedRow] = useState<any[]>([]);

  const gridRef = useRef<AgGridReact>(null);
  const gridContext = useManagedContext<IGridContext>('grid');

  const { columnDefs } = gridContext;

  const { rowDataList, setSelectedPaymentsIds } =
    useContext(ListPaymentContext);

  useEffect(() => {
    if (isLoading) {
      gridContext.updateDataWithFunction((prev) => {
        prev.loading = isLoading;
      });
      return;
    }
    if (!period) return;
    gridContext.updateDataWithFunction((prev) => {
      prev.responseData = period;
      prev.loading = false;
      prev.rowData = processedRow!;
    });
  }, [isLoading, period, processedPayments]);

  useEffect(() => {
    if (!period) return;
    setProcessedPayments(getProcessedMultiplePaiements(period) || []);
    setProcessedRow(getProcessedMultiplePaiements(rowDataList) || []);
  }, [period]);

  useEffect(() => {
    if (!expanded) {
      gridContext.updateDataWithFunction((prev) => {
        prev.selectedRows = [];
      });
      gridRef.current?.api.deselectAll();
    }
  }, [expanded]);

  useEffect(() => {
    setSelectedPaymentsIds(gridContext.selectedRows);
  }, [gridContext.selectedRows]);

  return (
    <div>
      <TitleContainer headerBackground={themes?.default?.roundtripGreyBg}>
        <CategoryTitle onClick={onToggle} date={date} expanded={expanded} />
      </TitleContainer>
      <Collapse in={expanded}>
        <ManagedGridList
          gridRef={gridRef}
          rowData={processedPayments}
          columnDefs={columnDefs}
          loading={isLoading}
          filtered={false}
          setSort={() => {}}
          onScrollEndReached={() => {}}
          onSelectionChanged={() => {}}
        />
      </Collapse>
    </div>
  );
}

const TitleContainer = styled.div<{ headerBackground }>`
  background: ${(props) => props.headerBackground};
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  margin-bottom: 10px;
`;
