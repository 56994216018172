import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import ManagedGrid from 'app/components/ManagedGrid';
import { IGridContext } from 'app/components/ManagedGrid/types';
import { useManagedContext } from 'common/UtilityComponents/ManagedContext/useManagedContext';
import EmptyStateClaims from 'assets/img/ORDORIA/Claims/EmptyStateClaims.svg';

import { useGetCustomerPortalInvoicesQuery } from 'common/services/customerPortalApi';
import { getProcessedMultiplePaiements } from './helpers';
import { useQueryParams } from 'hooks/useQueryParams';
import { BulkActionsToolbar } from './BulkActionsToolbar';
import { selectConnectors } from 'common/store/organization/selectors';
import { selectAuthCustomer } from 'common/store/auth/selectors';

export function MultiplePaymentsGrid() {
  const { t } = useTranslation();
  const location = useLocation();
  const gridContext = useManagedContext<IGridContext>('grid');
  const authCustomer = useSelector(selectAuthCustomer);

  const {
    queryParams: { page, rowsPerPage, search },
  } = gridContext;

  const commonQueryParams = {
    page: page,
    limit: rowsPerPage,
    text: search,
  };

  const urlParams = new URLSearchParams(location.search);
  const queryParams = useQueryParams(commonQueryParams);
  const connectors = useSelector(selectConnectors);
  const paynumConnector = connectors?.find(
    (connector) => connector.type === 'paynum' && connector.enable,
  );

  const paymentEnable = paynumConnector?.config?.paymentEnable;
  const {
    data: invoices,
    isLoading,
    isFetching,
    refetch,
  } = useGetCustomerPortalInvoicesQuery(queryParams);

  const noDataConfig = useMemo(
    () => ({
      icon: EmptyStateClaims,
      text: t('claims.emptyState'),
      textButton: t('claims.emptyState.button'),
    }),
    [gridContext.openDrawer],
  );

  const toolbarConfig = useMemo(
    () => ({
      disabled: gridContext.disableToolBar,
      BulkActionsComponent: BulkActionsToolbar,
    }),
    [gridContext],
  );

  useEffect(() => {
    if (!invoices) return;
    const processedMultiplePayments = getProcessedMultiplePaiements(
      invoices.docs,
      paymentEnable,
    );

    gridContext.updateDataWithFunction((prev) => {
      prev.totalRows = invoices.totalDocs;
      prev.responseData = invoices.docs;
      prev.loading = isLoading;
      prev.rowData = processedMultiplePayments!;
    });
    //statsConfig.updateData('facet', facetsData);
  }, [invoices, isLoading, isFetching, gridContext.viewMode]);

  useEffect(() => {
    if (
      !isFetching &&
      (authCustomer?._id || urlParams?.get('payments') === 'ok')
    ) {
      refetch();
    }
  }, [authCustomer?._id, urlParams?.get('payments')]);

  return (
    <Wrapper>
      <ManagedGrid toolbarConfig={toolbarConfig} noDataConfig={noDataConfig} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  flex: 1;
`;
