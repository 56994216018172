import { Stack, Typography } from '@mui/material';
import { useModal } from 'app/components/Dialog/hooks';
import {
  useChangeLeaveRequestStatusMutation,
  useGetLeaveRequestQuery,
} from 'common/services/leaveRequestApi';
import dayjs from 'dayjs';
import { useToaster } from 'hooks/useToaster';
import { useTranslation } from 'react-i18next';

interface Props {
  userName: string;
  leaveRequestId: string;
}
export const NewLeaveRequest = ({ userName, leaveRequestId }: Props) => {
  const { data } = useGetLeaveRequestQuery(leaveRequestId, {
    skip: !leaveRequestId,
    refetchOnMountOrArgChange: true,
  });

  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();
  const [changeStatus] = useChangeLeaveRequestStatusMutation();
  const toast = useToaster();
  const handleOpenLeaveModal = () => {
    openModal({
      title: t('Leave Request'),
      cancel: false,
      action: [
        {
          actionCallback: async () => {
            const res: any = await changeStatus({
              id: leaveRequestId,
              status: 'declined',
            });
            if (!res?.error) {
              toast(3000, 'success', t('leave-request-toast-decline'));
              closeModal();
            }
          },
          actionText: t('Decline'),
          buttonProps: { variant: 'text', color: 'error' },
        },
        {
          actionCallback: async () => {
            const res: any = await changeStatus({
              id: leaveRequestId,
              status: 'accepted',
            });
            if (!res?.error) {
              toast(3000, 'success', t('leave-request-toast-accept'));
              closeModal();
            }
          },
          actionText: t('Accept'),
          buttonProps: { variant: 'contained', color: 'primary' },
        },
      ],
      content: (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '16px',
            }}
          >
            <p style={{ margin: 0 }}>{t('leave-request-title')} </p>
            <p style={{ marginLeft: '5px' }}>{userName}</p>
          </div>
          <div
            style={{
              marginTop: '16px',
              fontSize: '16px',
              border: '1px solid #ccc',
              padding: '16px 16px 16px 32px',
              borderRadius: '8px',
              minWidth: '552px',
            }}
          >
            <p style={{ margin: '5px 0' }}>
              <strong>{t('Type')}</strong>
              <span style={{ marginLeft: '68px' }}>:</span>
              <span style={{ marginLeft: '10px' }}>
                {data?.cache?.leavetype?.english
                  ? data.cache?.leavetype?.english.charAt(0).toUpperCase() +
                    data.cache?.leavetype?.english.slice(1)
                  : 'N/A'}
              </span>
            </p>
            <p style={{ margin: '5px 0' }}>
              <strong>{t('Leave Dates')}</strong>
              <span style={{ marginLeft: '15px' }}>:</span>
              <span style={{ marginLeft: '10px' }}>
                {data?.startDate
                  ? dayjs(data.startDate).format('dddd MMM D, YYYY')
                  : 'N/A'}
                {' - '}
                {data?.endDate
                  ? dayjs(data.endDate).format('dddd MMM D, YYYY')
                  : 'N/A'}
              </span>
            </p>
            <p style={{ margin: '5px 0' }}>
              <strong>{t('Period')}</strong>
              <span style={{ marginLeft: '56px' }}>:</span>
              <span style={{ marginLeft: '10px' }}>
                {data?.differenceInDays || 'N/A'}{' '}
                {data?.differenceInDays ? t('days') : ''}
              </span>
            </p>
            <p style={{ margin: '5px 0' }}>
              <strong>{t('Description')}</strong>
              <span style={{ marginLeft: '21px' }}>:</span>
              <span style={{ marginLeft: '10px' }}>{data?.description}</span>
            </p>
          </div>
        </>
      ),
    });
  };

  return (
    <Stack
      direction="row"
      gap="5px"
      fontSize="0.75rem"
      onClick={handleOpenLeaveModal}
    >
      <Typography fontWeight={700} fontSize="0.75rem">
        <u>{userName}</u>
      </Typography>
      {t('applied_for_leave_request')}
    </Stack>
  );
};
