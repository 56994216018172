import React from 'react';
import GridContext from '../../../../../components/ManagedGrid/GridContext';
import {
  Permission,
  usePermission,
} from '../../../../../../hooks/Abilities/usePermission';
import FunctionalWarehouses from './components/FunctionalWarehouses';
import { CUSTOM_WAREHOUSES_COLUMN_CONFIG } from './config';

function WarehousesV2() {
  const can = usePermission('companysettings');

  return (
    <GridContext
      data={[]}
      customColumnConfig={CUSTOM_WAREHOUSES_COLUMN_CONFIG}
      title=""
      canEdit={can(Permission.EDIT)}
      canExport={can(Permission.EXPORT)}
      suppressToolbar
    >
      <FunctionalWarehouses />
    </GridContext>
  );
}
export default WarehousesV2;
