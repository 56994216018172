import React from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { ButtonBase } from '@mui/material';
import { Icon } from 'app/components/Icon';
import { CaretDown } from '@phosphor-icons/react';

interface Props {
  date: string;
  expanded: boolean;
  onClick?: () => void;
}

export const CategoryTitle: React.FC<Props> = ({ date, expanded, onClick }) => {
  return (
    <ButtonBase onClick={onClick} sx={{ borderRadius: '4px' }}>
      <Wrapper expanded={expanded}>
        <span
          style={{
            transform: `rotate(${expanded ? 0 : -90}deg)`,
            transitionDuration: '0.15s',
          }}
        >
          <Icon
            icon={<CaretDown />}
            size={12}
            color={
              expanded
                ? themes?.default?.accordionWhiteBg
                : themes?.default?.iconColorDark
            }
          />
        </span>
        <Count expanded={expanded}>{date}</Count>
      </Wrapper>
    </ButtonBase>
  );
};

const Wrapper = styled.div<{ expanded: boolean }>`
  width: fit-content;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 16px 0 16px;
  gap: 8px;
  border-radius: 4px;
  background: ${(props) =>
    props.expanded
      ? props.theme?.darkBackground
      : themes?.default?.accordionWhiteBg};
  border: 1px solid
    ${(props) =>
      props.expanded
        ? props.theme?.primaryActiveColor
        : themes?.default?.borderSilver};
`;

const Count = styled.span<{ expanded: boolean }>`
  margin: 0;
  display: inline;
  font-size: 0.75rem;
  line-height: 19.92px;
  letter-spacing: 0.4000000059604645px;
  color: ${(props) =>
    props.expanded
      ? themes?.default?.accordionWhiteBg
      : `${themes?.default?.black}61`};
  transition-duration: 0.15s;
`;
