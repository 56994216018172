import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectedWarehouse } from '../Employee/types';
import WarehouseDropdown from '../Warehouse/WarehouseDropdown';
import { useModal } from '../Dialog/hooks';
import { Alert, Stack, Step, StepLabel, Stepper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UploadZone } from '../UploadFiles';
import { UploadedFile } from '../UploadComponent';
import styled from 'styled-components';
import { AddByFileFormProps } from './types';
import { ValidationError } from 'common/types/User';
import { AcceptedFiles } from 'app/components/AcceptedFiles/Index';
import { download } from 'utils/download';
import If from '../If';
import { updateFileType } from 'utils/upload/updateFormFileType';
import { useSelector } from 'react-redux';
import { selectDecks } from 'common/store/organization/selectors';
import pluralize from 'pluralize';
import { LoadingIndicator } from 'app/components/LoadingIndicator';

const ErrorValue = ({ error }: { error: ValidationError }) => {
  const { t } = useTranslation();
  const { key, values } = error;
  const [field, row] = values.split('/');
  const strKey = 'import.file.' + key;
  const str = t(strKey);
  return (
    <li>
      <strong>{str}</strong> (at: <strong>{field}</strong>){' '}
      {!!row && `{${row}}`}
    </li>
  );
};

const useFormatModule = () => {
  const { t } = useTranslation();

  return (module: string, count = 1) => {
    return pluralize(t(module), count);
  };
};

export const AddByFileForm: React.FC<AddByFileFormProps> = ({
  onValidate,
  onImport,
  module,
  sampleFile,
  defaultWarehouse,
  backAction,
  showBackArrow,
  otherAllowedFiles,
  allowedFilesText,
}) => {
  const decks = useSelector(selectDecks);
  const warehouseValue = defaultWarehouse || decks?.[0];

  const [selectedWarehouse, setSelectedWarehouse] = useState<
    SelectedWarehouse | undefined
  >({
    id: warehouseValue?._id,
    label: `${warehouseValue?.name} ${
      warehouseValue?.shortName ? `(${warehouseValue?.shortName})` : ''
    }`,
  });
  const [step, setStep] = useState(!!defaultWarehouse?._id ? 1 : 0);
  const [file, setFile] = useState<File | null>(null);
  const [importDisabled, setImportDisabled] = useState(true);
  const [fatalErrors, setFatalErrors] = useState<ValidationError[]>([]);
  const [warningErrors, setWarningErrors] = useState<ValidationError[]>([]);
  const [allowedIndexes, setAllowedIndexes] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isImportLoading, setIsImportLoading] = useState<boolean>(false);

  const [notAllowedIndexesFatal, setNotAllowedIndexesFatal] = useState<
    number[]
  >([]);
  const [notAllowedIndexesWarning, setNotAllowedIndexesWarning] = useState<
    number[]
  >([]);

  const proceedDisabled = useMemo(() => {
    return (
      (step === 0 && !selectedWarehouse) || (step === 1 && !file && isLoading)
    );
  }, [file, selectedWarehouse, step, isLoading]);
  const isLastStep = useMemo(() => step === 2, [step]);
  const showBadStructureError = useMemo(
    () => fatalErrors.some((f) => f.key === 'import.file.structure-warning'),
    [fatalErrors],
  );

  const { setActionDisabled, setBackAction, setActionButton, closeModal } =
    useModal();
  const { t } = useTranslation();
  const formatModule = useFormatModule();

  const validateFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      let updatedFile = file;
      if (updatedFile.type === 'application/vnd.ms-excel')
        updatedFile = updateFileType(file, 'text/csv');
      formData.append('file', updatedFile);
      formData.append('warehouseId', selectedWarehouse!.id);
      formData.append('_deck', selectedWarehouse!.id);
      const {
        isImportValid,
        fatalErrors,
        warningErrors,
        allowedIndexes,
        notAllowedIndexesFatal,
        notAllowedIndexesWarning,
      } = await onValidate(formData);
      setImportDisabled(!isImportValid);
      setFatalErrors(fatalErrors);
      setWarningErrors(warningErrors);
      setAllowedIndexes(allowedIndexes);
      setNotAllowedIndexesFatal(notAllowedIndexesFatal);
      setNotAllowedIndexesWarning(notAllowedIndexesWarning);
    },
    [onValidate, selectedWarehouse],
  );

  const importFile = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('warehouseId', selectedWarehouse!.id);
      formData.append('_deck', selectedWarehouse!.id);
      await onImport(formData);
    },
    [onImport, selectedWarehouse],
  );

  const onFileChange = useCallback(async (file: UploadedFile[]) => {
    setFile(file[0]);
  }, []);

  const downloadSamplefile = useCallback(() => {
    if (sampleFile.fn) {
      sampleFile.fn();
    } else {
      download(sampleFile);
    }
  }, [sampleFile]);

  useEffect(() => {
    setActionDisabled(proceedDisabled);
    if (isLastStep && file) {
      setActionButton({
        actionText: t('common.buttons.import'),
        async actionCallback() {
          setActionDisabled(true);
          setIsImportLoading(true);
          try {
            await importFile(file);
            setActionDisabled(false);
            setIsImportLoading(false);
            closeModal();
          } catch {
            setIsImportLoading(false);
          }
        },
        buttonProps: { disabled: importDisabled },
      });

      setBackAction({
        actionCallback() {
          //setFile(null);
          setStep((prev) => prev - 1);
        },
      });
    } else if (step) {
      setBackAction(
        showBackArrow
          ? {
              actionCallback: () => {
                // setFile(null);
                if (!!backAction) {
                  backAction?.();
                  return;
                }
                setStep((prev) => prev - 1);
              },
            }
          : undefined,
      );
      if (file) {
        setActionButton({
          actionText: t('common.buttons.proceed'),
          async actionCallback() {
            setIsLoading(true);
            setActionDisabled(true);
            try {
              await validateFile(file);
              setIsLoading(false);
              setActionDisabled(false);
              setStep((prev) => prev + 1);
            } catch {
              setIsLoading(false);
            }
          },
        });
      } else {
        setActionDisabled(true);
      }
    } else {
      setActionButton({
        actionText: t('common.buttons.proceed'),
        actionCallback() {
          setStep((prev) => prev + 1);
        },
        buttonProps: { disabled: !selectedWarehouse },
      });
      setBackAction(undefined);
    }
  }, [
    selectedWarehouse,
    proceedDisabled,
    step,
    file,
    isLastStep,
    importDisabled,
    setActionDisabled,
    setBackAction,
    setActionButton,
    t,
    closeModal,
    validateFile,
    importFile,
    backAction,
  ]);

  return (
    <>
      {step === 0 && (
        <WarehouseDropdown
          value={selectedWarehouse}
          handleChange={(v) => setSelectedWarehouse(v)}
        />
      )}

      {step >= 1 && (
        <Stack gap="20px">
          {selectedWarehouse?.label !== ' ' && (
            <span>
              {t('warehouse')}: <strong>{selectedWarehouse?.label}</strong>
            </span>
          )}
          <Stepper activeStep={step - 1}>
            <Step completed={step > 1}>
              <StepLabel>{t('import.file.excel')}</StepLabel>
            </Step>
            <Step completed={step > 2}>
              <StepLabel>{t('import.file.validation')}</StepLabel>
            </Step>
          </Stepper>

          {step === 1 && (
            <Stack gap="10px">
              <Alert severity="warning">
                {t('import.file.structure-warning')}{' '}
                <button
                  onClick={downloadSamplefile}
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  {t('common.links.click_here').toLowerCase()}
                </button>
              </Alert>
              <UploadZone
                maxFiles={1}
                type=""
                title=""
                description={
                  allowedFilesText
                    ? `(${t(allowedFilesText)})`
                    : t('upload.csv')
                }
                onOk={onFileChange}
                onUnOk={() => setFile(null)}
                acceptFile={{
                  'text/csv': ['.csv'],
                  ...otherAllowedFiles,
                }}
                isUploading={isLoading}
              />
              {file && !isLoading && (
                <AcceptedFiles
                  file={file}
                  statusFile={{ [file.name]: true }}
                  hideProgressBar={{ [file.name]: true }}
                  fileProgress={{ [file.name]: true }}
                  deleteFile={() => setFile(null)}
                />
              )}
            </Stack>
          )}

          {step === 2 && (
            <>
              {showBadStructureError ? (
                <Alert severity="error">
                  {t('add-by-file.dialog.file-misconfiguration')}
                </Alert>
              ) : (
                <>
                  <If condition={!!allowedIndexes.length}>
                    <Alert severity="success">
                      {allowedIndexes.length}{' '}
                      {formatModule(module, allowedIndexes.length)}{' '}
                      {t('import.success-count')}
                    </Alert>
                    {isImportLoading && <LoadingIndicator />}
                  </If>
                  <If condition={!!fatalErrors.length}>
                    <Alert severity="error">
                      <strong>
                        {notAllowedIndexesFatal.length}{' '}
                        {formatModule(module, notAllowedIndexesFatal.length)}{' '}
                        {t('import.warning')}
                      </strong>
                      <List>
                        {fatalErrors.map((err, i) => (
                          <ErrorValue key={`${err.key}-${i}`} error={err} />
                        ))}
                      </List>
                    </Alert>
                  </If>
                  <If condition={!!warningErrors.length}>
                    <Alert severity="warning">
                      <strong>
                        {notAllowedIndexesWarning.length}{' '}
                        {formatModule(module, notAllowedIndexesWarning.length)}{' '}
                        {t('import.warning')}
                      </strong>
                      <List>
                        {warningErrors.map((err, i) => (
                          <ErrorValue key={`${err.key}-${i}`} error={err} />
                        ))}
                      </List>
                    </Alert>
                  </If>
                </>
              )}
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default AddByFileForm;

export const List = styled.ul`
  padding-inline-start: 22px;
  margin: 0;
`;
