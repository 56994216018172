import LegendActive from 'assets/img/map/legend/vehicle/LegendActive.svg';
import LegendActiveMoving from 'assets/img/map/legend/vehicle/LegendActiveMoving.svg';
import LegendReturning from 'assets/img/map/legend/vehicle/LegendReturning.svg';
import LegendReturningMoving from 'assets/img/map/legend/vehicle/LegendReturningMoving.svg';
import LegendInactive from 'assets/img/map/legend/vehicle/LegendInactive.svg';
import LegendNotTracked from 'assets/img/map/legend/vehicle/LegendNotTracked.svg';

export const vehicleLabels = {
  title: 'vehicles_labels',
  legends: [
    {
      text: 'roundtrips.tooltip.truck.active',
      icon: (
        <img
          style={{ height: '18px', width: '18px' }}
          src={LegendActive}
          alt=""
        />
      ),
    },
    {
      text: 'vehicles-status-activemoving',
      icon: (
        <img
          style={{ height: '18px', width: '18px' }}
          src={LegendActiveMoving}
          alt=""
        />
      ),
    },
    {
      text: 'vehicles.status.returning',
      icon: (
        <img
          style={{ height: '18px', width: '18px' }}
          src={LegendReturning}
          alt=""
        />
      ),
    },
    {
      text: 'labels.returning_moving',
      icon: (
        <img
          style={{ height: '18px', width: '18px' }}
          src={LegendReturningMoving}
          alt=""
        />
      ),
    },
    {
      text: 'vehicles.status.inactive',
      icon: (
        <img
          style={{ height: '18px', width: '18px' }}
          src={LegendInactive}
          alt=""
        />
      ),
    },
    {
      text: 'vehicles-status-nottracked',
      icon: (
        <img
          style={{ height: '18px', width: '18px' }}
          src={LegendNotTracked}
          alt=""
        />
      ),
    },
  ],
};
