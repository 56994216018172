import React, { useCallback } from 'react';
import { FormSection } from 'app/components/Form/styles';
import { useTranslation } from 'react-i18next';
import {
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Stack,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { CancelButton } from 'app/components/Dialog/styles/index';
import { PhoneInputField } from 'app/components/Form/PhoneInputField';
import { v4 as uuidv4 } from 'uuid';
import { Contact } from 'common/types/Customer';
import FormTextField from 'app/components/Form/TextField';
import FormSelectField from 'app/components/Form/SelectField';
import { isEmpty } from 'lodash';
import DynamicAutocomplete from 'app/components/Form/DynamicAutocomplete';
import { useSelector } from 'react-redux';
import { selectJobRoles } from 'common/store/organization/selectors';
import { JobRole } from 'common/types/JobRole';
import { usePostJobRoleMutation } from 'common/services/jobRoleApi';
interface Props {
  onSubmit: (data: Contact) => void;
  data?: Contact | null;
  renderFooter?: boolean;
  closeModal?: () => void;
  main?: boolean;
}

//TODO: this will be dynamic
enum JobType {
  LOGISTICS = 'logistics',
  SALES = 'sales',
  OTHER = 'other',
}

export const AddPhoneForm: React.FC<Props> = ({
  onSubmit,
  data = null,
  closeModal,
  main,
}) => {
  const { t } = useTranslation();
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      name: data?.name || '',
      role: data?.role || '',
      phone: data?.phone || '',
      main: main ? true : data?.main ? data?.main : false,
      jobType: data?.jobType || '',
      email: data?.email || '',
      _id: data?._id || uuidv4(),
    },
  });

  const role = watch('role');

  const [postJob] = usePostJobRoleMutation();

  const addJob = useCallback(
    async (label: string) => {
      return await postJob({ label }).unwrap();
    },
    [postJob],
  );

  const jobRoles = useSelector(selectJobRoles);

  const mapJobRole = useCallback((jobRole: JobRole) => {
    return jobRole.label;
  }, []);

  return (
    <>
      <form id="add-new-form" onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <FormSelectField
            name="jobType"
            label={t('customer.field.jobType')}
            control={control}
            data={Object.values(JobType).map((d) => {
              return {
                value: d,
                label: t(d),
              };
            })}
            required
          />

          <FormTextField
            name="name"
            label="customer.field.fullName"
            control={control}
            required
            rules={{ required: true }}
          />

          <DynamicAutocomplete
            label="role"
            defaultOptions={jobRoles?.map(mapJobRole)}
            addOption={addJob}
            mapOption={mapJobRole}
            onChange={(option) => {
              setValue('role', option || '');
            }}
            value={role}
            textFieldProps={{
              required: true,
            }}
          />

          <Controller
            name="phone"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <PhoneInputField
                {...field}
                country="fr"
                specialLabel={t('Add_Client_Mobile_Phone') + ' *'}
                inputProps={{
                  name: 'phone',
                }}
              />
            )}
          />

          <FormTextField
            name="email"
            label="field.email"
            control={control}
            rules={{
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t('validation.email'),
              },
            }}
          />

          <FormGroup>
            <Controller
              name="main"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      defaultChecked={control?._defaultValues?.main}
                      disabled={main}
                    />
                  }
                  label={t('customer.field.markAsMain')}
                />
              )}
            />
          </FormGroup>
        </FormSection>
      </form>
      <Stack direction="row" justifyContent="flex-end">
        <CancelButton
          onClick={() => {
            closeModal?.();
          }}
        >
          {t('Add_Client_Cancel')}
        </CancelButton>
        <Button
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || !isEmpty(errors) || !role}
        >
          {t(data ? 'common.buttons.save' : 'common.buttons.add')}
        </Button>
      </Stack>
    </>
  );
};
