import api from './api';
import { IProduct } from '../types/Product';

export const productApi = api.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<any, string>({
      query: urlParams => ({
        url: `/products${urlParams}`,
      }),
      providesTags: ['Products'],
    }),
    getProductsCategories: builder.query<any, { urlQuery: string }>({
      query: ({ urlQuery }) => ({
        url: `/products/categories${urlQuery}`,
      }),
    }),
    getProductsByCategory: builder.query<
      any,
      { categoryId: string; urlQuery: string }
    >({
      query: ({ categoryId, urlQuery }) => ({
        url: `/products/categories/${categoryId}${urlQuery}`,
      }),
    }),
    updateShowToCatalog: builder.mutation<void, { id: string; show: boolean }>({
      query: ({ id, show }) => ({
        url: `/products/${id}/show-in-catalog`,
        method: 'PATCH',
        body: { showInCatalog: show },
      }),
      invalidatesTags: ['Products'],
    }),
    updateShowToGallery: builder.mutation<void, { id: string; show: boolean }>({
      query: ({ id, show }) => ({
        url: `/products/${id}/show-in-gallery`,
        method: 'PATCH',
        body: { showInGallery: show },
      }),
      invalidatesTags: ['Products'],
    }),
    updateStatus: builder.mutation<
      void,
      { productIds: string[]; status: string }
    >({
      query: ({ productIds, status }) => ({
        url: `/products/status`,
        method: 'PATCH',
        body: { productIds: productIds, status: status },
      }),
      invalidatesTags: ['Products'],
    }),
    getProductsStats: builder.query<any, string>({
      query: urlParams => ({
        url: `/products/stats${urlParams}`,
        method: 'GET',
      }),
      providesTags: ['Products'],
    }),
    getProductsSubCategories: builder.query<any, string>({
      query: urlQuery => ({
        url: `/products/sub-categories${urlQuery}`,
      }),
    }),
    updateProductDiscount: builder.mutation<
      void,
      { productIds: string[]; discount: number }
    >({
      query: ({ productIds, discount }) => ({
        url: `/products/discount`,
        method: 'PATCH',
        body: { productIds, discount },
      }),
      invalidatesTags: ['Products'],
    }),
    updateProductBoxBuyingPrice: builder.mutation<
      void,
      { productIds: string[]; boxBuyingPrice: number }
    >({
      query: ({ productIds, boxBuyingPrice }) => ({
        url: `/products/box-buying-price`,
        method: 'PATCH',
        body: { productIds, boxBuyingPrice },
      }),
      invalidatesTags: ['Products'],
    }),
    checkProductBarCodeValidity: builder.query<any, string>({
      query: urlQuery => ({
        url: `/products/check-barcode${urlQuery}`,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    checkProductCodeValidity: builder.query<any, string>({
      query: urlQuery => ({
        url: `/products/check-code${urlQuery}`,
      }),
      extraOptions: {
        maxRetries: 0,
      },
    }),
    addProduct: builder.mutation<unknown, any>({
      query: body => ({
        url: `/products`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Products'],
    }),
    getProductsCategoriesOptions: builder.query<
      IProduct[],
      { parentCategory: string | null }
    >({
      query: ({ parentCategory }) => ({
        url: `/product-categories/options?_parentCategory=${parentCategory}`,
      }),
    }),
    getProductsDetails: builder.query<IProduct, { id: string }>({
      query: ({ id }) => ({
        url: `/products/${id}`,
      }),
    }),
    reassignProduct: builder.mutation<void, { body }>({
      query: ({ body }) => ({
        url: `/products/category`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Products'],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductsCategoriesQuery,
  useGetProductsByCategoryQuery,
  useLazyGetProductsByCategoryQuery,
  useUpdateShowToCatalogMutation,
  useUpdateShowToGalleryMutation,
  useUpdateStatusMutation,
  useGetProductsStatsQuery,
  useGetProductsSubCategoriesQuery,
  useUpdateProductDiscountMutation,
  useUpdateProductBoxBuyingPriceMutation,
  useLazyCheckProductBarCodeValidityQuery,
  useLazyCheckProductCodeValidityQuery,
  useAddProductMutation,
  useGetProductsCategoriesOptionsQuery,
  useLazyGetProductsDetailsQuery,
  useReassignProductMutation,
} = productApi;

export default productApi;
