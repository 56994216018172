import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { useTranslation } from 'react-i18next';
import { MessageOptionsMenu } from '../MessageOptionsMenu';
import { FileIcon } from 'app/components/Chat/components/ChatWindow/components/FileIcon';
import EllipsisText from 'app/components/EllipsisText';
import { ActionsHandleProps } from 'app/components/Chat/components/ChatWindow';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { StateSetter } from 'types';
import { SelectionCheckbox } from '../../../SelectionCheckbox';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

interface Props {
  messageUuid: string;
  type: 'sent' | 'received';
  similarToPrevMessage: boolean;
  content: string;
  name?: string;
  description?: string;
  contentType: string;
  createdAt: string;
  handleMessageActions: (action: ActionsHandleProps) => void;
  isEdited: boolean;
  reply?: any;
  senderFullName?: string;
  handleScrollToMessage?: (messageUuid) => void;
  highlightMessageUuid?: string;
  setHighlightMessageUuid?: StateSetter<string>;
  selectedMessagesUuid?: string[];
  handleToggleSelect?: (messageUuid: string, selected: boolean) => void;
}

export const ChatMessage: React.FC<Props> = ({
  messageUuid,
  type,
  similarToPrevMessage,
  content,
  name,
  description,
  contentType,
  createdAt,
  handleMessageActions,
  isEdited,
  reply,
  senderFullName,
  handleScrollToMessage,
  highlightMessageUuid,
  setHighlightMessageUuid,
  selectedMessagesUuid,
  handleToggleSelect,
}) => {
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const formatTimestamp = (timestamp) => {
    return dayjs(timestamp).format('h:mm A');
  };

  const canEdit = useMemo(() => {
    if (type !== 'sent') return false;
    return true;

    // const allowedEditDurationInMinutes = 10;
    // const editTimeUnix = allowedEditDurationInMinutes * 60 * 1000;

    // const nowUnix = new Date().getTime();
    // const createdAtUnix = new Date(createdAt).getTime();

    // if (nowUnix - editTimeUnix > createdAtUnix) {
    //   return false;
    // } else {
    //   return true;
    // }
    // return false;
  }, [contentType, type, createdAt]);

  const fileType = useMemo(() => {
    if (name) {
      const splittedFileName = name.split('.');
      return splittedFileName[splittedFileName?.length - 1];
    } else if (content) {
      const splittedFileName = content.split('.');
      return splittedFileName[splittedFileName?.length - 1];
    } else {
      return '';
    }
  }, [name, content]);

  useEffect(() => {
    if (!highlightMessageUuid || !messageUuid) return;
    if (highlightMessageUuid === messageUuid) {
      setIsHighlighted(true);
    }
  }, [highlightMessageUuid]);

  useEffect(() => {
    if (!isHighlighted) return;

    const timeoutId = setTimeout(() => {
      setIsHighlighted(false);
      setHighlightMessageUuid && setHighlightMessageUuid('');
    }, 4000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isHighlighted]);

  useEffect(() => {
    if (selectedMessagesUuid?.length === 0) {
      setIsSelected(false);
    } else if (selectedMessagesUuid?.some((uuid) => uuid === messageUuid)) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selectedMessagesUuid]);

  function replaceNewlinesWithBr(content: string): React.ReactNode {
    return content.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <Wrapper
      type={type}
      similarToPrevMessage={similarToPrevMessage}
      isHighlighted={isHighlighted}
      highlightBackground={
        type === 'sent'
          ? theme?.strokePrimaryOpaque
          : themes?.default?.blueGray400Opaque
      }
      isSelected={isSelected}
    >
      {selectedMessagesUuid && selectedMessagesUuid.length > 0 ? (
        <SelectionCheckbox
          checked={isSelected}
          handleToggleSelect={handleToggleSelect}
          messageUuid={messageUuid}
        />
      ) : (
        <span />
      )}
      <MessageContainer
        contentType={contentType}
        type={type}
        id={messageUuid}
        isSelected={isSelected}
      >
        <MessageWithReplyWrapper>
          {!!reply?.content && (
            <ReplyWrapper
              type={type}
              borderColor={theme?.primaryDark}
              backgroundColor={theme?.strokePrimaryOpaque}
              onClick={() => {
                handleScrollToMessage &&
                  handleScrollToMessage(reply?.messageUuid);
              }}
            >
              <div>
                <p className="name">{senderFullName}</p>
                <p className="content">
                  {reply?.contentType === 'file'
                    ? reply?.name
                    : reply?.contentType === 'images'
                      ? '🖼️ Image'
                      : replaceNewlinesWithBr(reply?.content)}
                </p>
              </div>
              {reply?.contentType === 'file' && (
                <FileIcon
                  fileName={reply?.name || ''}
                  size={{ height: 32, width: 32 }}
                />
              )}
              {reply?.contentType === 'images' && (
                <img src={reply?.content} alt="img" />
              )}
            </ReplyWrapper>
          )}
          {contentType === 'images' ? (
            <>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'space-between',
                  margin: '4px 8px 0 4px',
                }}
              >
                {replaceNewlinesWithBr(description || '')}
                {createdAt && (
                  <div className="time">{formatTimestamp(createdAt)}</div>
                )}
              </div>
              <img
                src={content}
                onClick={() =>
                  handleMessageActions({
                    actionType: 'view',
                    messageUuid: messageUuid,
                    messageContent: content,
                    fileType: 'images',
                  })
                }
              />
            </>
          ) : contentType === 'file' ? (
            <FileMessageWrapper type={type}>
              {replaceNewlinesWithBr(description || '')}
              <div className="contentDateWrapper">
                <div className="leftSection">
                  <FileIcon
                    fileName={name || ''}
                    fileType={fileType}
                    size={{ height: 40, width: 40 }}
                  />
                  <div>
                    <EllipsisText text={name || ''} width={190} />
                    <p className="fileType">{fileType?.toUpperCase()}</p>
                  </div>
                </div>
                {createdAt && (
                  <div className="time">{formatTimestamp(createdAt)}</div>
                )}
              </div>
            </FileMessageWrapper>
          ) : (
            <div
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'space-between',
              }}
            >
              {replaceNewlinesWithBr(content)}
              {createdAt && (
                <div className="time">
                  {isEdited && t('common.edited')} {formatTimestamp(createdAt)}
                </div>
              )}
            </div>
          )}
        </MessageWithReplyWrapper>
        <MessageOptionsMenuContainer
          className="messageOptionsMenuContainer"
          contentType={contentType}
        >
          <MessageOptionsMenu
            messageUuid={messageUuid}
            messageContent={content}
            canEdit={canEdit && contentType === 'text'}
            canView={contentType === 'images' || contentType === 'file'}
            canDownload={contentType === 'images' || contentType === 'file'}
            canReply={true}
            canSelect={true}
            fileName={name || `Untitled.${fileType}`}
            fileType={contentType}
            handleMessageActions={handleMessageActions}
          />
        </MessageOptionsMenuContainer>
      </MessageContainer>
    </Wrapper>
  );
};

export const Wrapper = styled.div<{
  similarToPrevMessage: boolean;
  isHighlighted?: boolean;
  highlightBackground?: string;
  isSelected?: boolean;
  type: 'sent' | 'received';
}>`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.type === 'sent' ? 'space-between' : 'flex-start'};
  align-items: center;
  margin-bottom: ${(props) => (props.similarToPrevMessage ? '4px' : '12px')};
  background: ${(props) =>
    props.isSelected ? themes?.default?.greenHighlightBg : ''};
  padding: 2px 13px 2px 20px;

  animation: ${(props) => (props.isHighlighted ? 'fadeInOut 4s forwards' : '')};

  @keyframes fadeInOut {
    0% {
      background: ${(props) => props.highlightBackground};
    }
    100% {
      background: transparent;
    }
  }
`;

export const MessageContainer = styled.div<{
  type: 'sent' | 'received';
  contentType?: string;
  isSelected?: boolean;
}>((props) => ({
  width: 'fit-content',
  maxWidth: '400px',
  padding: props.contentType === 'images' ? '4px' : '8px 12px 4px 12px',
  borderRadius: '8px',
  fontFamily: 'Roboto',
  fontSize: '0.85rem',
  lineHeight: '20px',
  color:
    props.type === 'sent'
      ? themes?.default?.chatMessageSent
      : themes?.default?.chatMessageRecieved,
  background: props.isSelected
    ? themes?.default?.teal100
    : props.type === 'sent'
      ? themes?.default?.primaryLight1
      : themes?.default?.whisper,
  alignSelf: props.type === 'sent' ? 'flex-end' : 'flex-start',
  display: 'flex',
  gap: '8px',
  '.time': {
    fontSize: '0.71rem',
    lineHeight: '11.72px',
    textAlign: 'right',
    alignSelf: 'flex-end',
    textTransform: 'lowercase',
    color: props.isSelected
      ? themes?.default?.teal600
      : props.type === 'sent'
        ? themes?.default?.strokePrimary
        : themes?.default?.nobel,
  },
  position: 'relative',
  '&:hover': {
    '.messageOptionsMenuContainer': {
      opacity: 1,
      background: props.isSelected
        ? themes?.default?.teal100
        : props.type === 'sent'
          ? `${themes?.default?.primaryLight1}F2`
          : `${themes?.default?.whisper}F2`,
    },
  },
  img: {
    maxWidth: '392px',
    maxHeight: '400px',
    borderRadius: 6,
    objectFit: 'contain',
    cursor: 'pointer',
  },

  '.description': {
    margin: '4px 4px 0 4px',
  },
}));

export const MessageOptionsMenuContainer = styled.div<{ contentType }>`
  position: absolute;
  top: 0;
  bottom: ${(props) => (props.contentType === 'images' ? 'none' : 0)};
  right: 0;
  min-height: 30px;
  width: 42px;
  padding: 0px 8px;
  border-radius: ${(props) =>
    props.contentType === 'images' ? '0 8px 0 8px' : '0px 8px 8px 0px'};
  display: flex;
  opacity: 0;
  transition-duration: 0.2s;
`;

export const FileMessageWrapper = styled.div<{ type?: string }>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .contentDateWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }

  .leftSection {
    height: 48px;
    min-width: 120px;
    max-width: 240px;
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 4px 6px 4px 4px;
    background: ${themes?.default?.accordionWhiteBg};
    border: 0.5px solid ${themes?.default?.gainsboro2};
    border-radius: 4px;
  }

  .fileType {
    margin: 0;
    font-size: 0.71rem;
    line-height: 11.72px;
    text-transform: uppercase;
    color: ${themes?.default?.nobel};
  }
`;

export const MessageWithReplyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const ReplyWrapper = styled.div<{
  type: string;
  borderColor: string;
  backgroundColor: string;
}>`
  min-height: 36px;
  width: 100%;
  padding: 0px 8px 0px 4px;
  border-radius: 4px;
  border-left: 2px solid
    ${(props) =>
      props.type === 'sent' ? props.borderColor : themes?.default?.blueGray400};
  background: ${(props) =>
    props.type === 'sent'
      ? props.backgroundColor
      : themes?.default?.blueGray400Opaque};

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  cursor: pointer;

  p {
    margin: 0;
  }

  div {
    width: 100%;
  }

  .name {
    font-size: 0.71rem;
    font-weight: 700;
    line-height: 18px;
    color: ${(props) =>
      props.type === 'sent' ? props.borderColor : themes?.default?.blueGray400};
  }

  .content {
    font-size: 0.71rem;
    font-weight: 400;
    line-height: 18px;
    color: ${(props) =>
      props.type === 'sent'
        ? themes?.default?.chatMessageSent
        : themes?.default?.chatMessageRecieved};
    width: 100%;
  }

  img {
    height: 36px;
    width: 36px;
    object-fit: cover;
    border-radius: 0px 4px 4px 0px;
  }
`;
