import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  ButtonBase,
  IconButton,
  Stack,
  Tab,
  Typography,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { getImgCdn } from 'common/helpers/cdn';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import {
  Grid3x3Outlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from '@mui/icons-material';
import EmptyBoxIcon from 'assets/img/ORDORIA/Orders/EmptyBoxIcon.svg';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { useTranslation } from 'react-i18next';
import { NavigationButtons } from 'app/components/TwoViewModal/components/NavigationButtons';
import EllipsisText from 'app/components/EllipsisText';
import { themes } from 'styles/theme/themes';
import { ItemCode } from 'app/pages/Ordoria/Catalog/components/Cells/NameCell';
import { Icon } from 'app/components/Icon';
import useFormatCurrency from 'common/hooks/useFormatCurrency';
import If from 'app/components/If';
import FavoriteDefault from 'assets/img/ORDORIA/Catalog/FavoriteDefault.svg';
import FavoriteFilled from 'assets/img/ORDORIA/Catalog/FavoriteFilled.svg';
import { CaretDown, DotsThree } from '@phosphor-icons/react';
import { QuantityInputCell } from 'app/pages/Ordoria/Catalog/components/Cells/QuantityInputCell';
import { FullScreenImageView } from 'app/components/Chat/components/ChatWindow/components/FullScreenImageView';
import { toTitleCase } from 'common/helpers/stringFormatter';
import { useToaster } from 'hooks/useToaster';
import { useToggleFavoriteItemsMutation } from 'common/services/customerApi';
import { CatalogContext } from 'app/pages/Ordoria/Catalog';
import { FlagCell } from 'app/pages/Ordoria/Catalog/components/Cells/FlagCell';
import { OrderFrequency } from 'app/pages/Ordoria/ViewEditOrder/types';
import { CustomerOrdersPopover } from 'app/pages/Ordoria/Catalog/components/BulkActionsToolbar/components/CustomerOrdersPopover';

interface ProductDetailsProp {
  open?: boolean;
  handleClose?: () => void;
  product: any;
  allProducts?: any[];
  handleChangeProduct?: (fullProduct: any) => void;
  onAddItem?: any;
  selectedProducts?: any;
}

export function ProductDetailsDrawer({
  open = false,
  handleClose = () => {},
  product,
  allProducts,
  handleChangeProduct = () => {},
}: ProductDetailsProp) {
  const { t } = useTranslation();
  const [drawerViewMode, setDrawerViewMode] = useState('');
  const [selectedTab, setSelectedTab] = React.useState('1');
  const [isFavorite, setIsFavorite] = useState(product?.favorite);
  const [customerOrdersPopoverAnchorEl, setCustomerOrdersPopoverAnchorEl] =
    useState<EventTarget | null>(null);
  const toast = useToaster();
  const { favoriteItems, setFavoriteItems, isCustomer } =
    React.useContext(CatalogContext);

  const [toggleFavoriteItems] = useToggleFavoriteItemsMutation();

  const formatCurrency = useFormatCurrency();

  // const canAddItem = () => {
  //   //check if the product in the selected products or not to prevet user from adding the item twice
  //   if (selectedProducts === undefined) {
  //     return false;
  //   }
  //   return selectedProducts?.every(
  //     (orderProduct) => orderProduct?._id !== product?._id,
  //   );
  // };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const toggleFavorite = async () => {
    const newFavoriteStatus = !isFavorite;
    setIsFavorite(newFavoriteStatus);
    try {
      const response = await toggleFavoriteItems({
        products: [product?._id],
      }).unwrap();
      if (response?.error) {
        throw new Error(response.error);
      }
      setFavoriteItems(response.result);
      toast(3000, 'success', t('toggle_fav.success'));
    } catch (error: any) {
      setIsFavorite(!newFavoriteStatus);
      toast(3000, 'error', error?.data?.message);
    }
  };

  const handleCloseCustomerOrderPopover = () => {
    setCustomerOrdersPopoverAnchorEl(null);
  };

  React.useEffect(() => {
    if (favoriteItems) {
      setIsFavorite(favoriteItems?.includes(product?._id));
    } else {
      setIsFavorite(product?.favorite || false);
    }
  }, [favoriteItems, product]);

  return (
    <TwoViewModal
      onOpen={open}
      onClose={handleClose}
      title={t('product_details')}
      handleViewChange={(mode) => setDrawerViewMode(mode)}
      width={drawerViewMode === 'sidebar' ? '640px' : '100%'}
      maxWidth={drawerViewMode === 'sidebar' ? '640px' : '965px'}
      height={57}
      borderRadius="12px"
      bodyPadding="10px"
      headerContent={
        allProducts ? (
          <NavigationButtons
            itemId={product?._id || ''}
            allItems={allProducts}
            handleChangeItem={handleChangeProduct}
          />
        ) : null
      }
      headerStyle={{ padding: '16px 10px 16px 16px' }}
      headerTitleProps={{
        fontSize: '1rem',
        lineHeight: '1.125rem',
        fontWeight: '500',
        color: themes.default.textBlack,
      }}
    >
      <Wrapper>
        <ContentWrapper
          style={{
            flexDirection: drawerViewMode === 'sidebar' ? 'column' : 'row',
            gap: drawerViewMode === 'sidebar' ? '20px' : '24px',
          }}
        >
          <DetailsWrapper>
            <InfoActionsConteiner>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <TopBarActionsConteiner>
                  <If condition={isCustomer}>
                    <IconButton
                      sx={{
                        width: '28px',
                        height: '28px',
                        padding: '4px',
                        borderRadius: '4px',
                        border: `1px solid ${themes?.default?.ordoriaLight}`,
                      }}
                      onClick={toggleFavorite}
                    >
                      <Icon
                        icon={
                          <img
                            src={isFavorite ? FavoriteFilled : FavoriteDefault}
                            alt="fav"
                            width="16px"
                            height="16px"
                          />
                        }
                        tooltip={t('favorite')}
                        size={16}
                      />
                    </IconButton>
                  </If>
                  <ButtonBase
                    sx={menuButtonSx}
                    onClick={(e) =>
                      setCustomerOrdersPopoverAnchorEl(e.currentTarget)
                    }
                  >
                    <Typography
                      fontSize="0.75rem"
                      fontWeight="500"
                      letterSpacing="0.4px"
                      color={themes?.default?.textBlack}
                    >
                      {t('orders.title.addToOrders')}
                    </Typography>
                    <Icon
                      icon={<CaretDown weight="bold" />}
                      size={12}
                      color={themes?.default?.textBlack}
                    />
                  </ButtonBase>
                  {false && ( // HIDE 3 DOTS OPTIONS TEMPORARLY
                    <IconButton
                      sx={{
                        width: '28px',
                        height: '28px',
                        padding: '4px',
                        borderRadius: '4px',
                        border: `1px solid ${themes?.default?.gainsboro2}`,
                        background: themes?.default?.lightGrey,
                      }}
                    >
                      <Icon
                        icon={<DotsThree weight="bold" />}
                        size={16}
                        color={themes?.default?.textBlack}
                      />
                    </IconButton>
                  )}
                </TopBarActionsConteiner>
                <QuantityInputCell value={{ cellData: { id: product?._id } }} />
              </Stack>
              <Stack>
                <DetailsContainer>
                  <Stack flex="1" gap="2px">
                    <Stack flexDirection="row" gap="8px" alignItems="center">
                      <ItemCode>
                        <span>
                          <Icon
                            icon={
                              <Grid3x3Outlined
                                sx={{
                                  width: '16px',
                                  color: `${themes?.default?.black}8A`,
                                }}
                              />
                            }
                          />
                        </span>
                        {product?.code}
                      </ItemCode>
                    </Stack>
                    <Typography
                      fontSize="1.25rem"
                      lineHeight="1.25rem"
                      fontWeight="500"
                      letterSpacing="0.15px"
                      color={themes.default.textBlack}
                    >
                      <EllipsisText
                        width={440}
                        text={toTitleCase(product?.name || '')}
                      />
                    </Typography>
                  </Stack>
                  <PriceContainer>
                    <Typography
                      fontSize="1.25rem"
                      fontWeight="700"
                      color={themes?.default?.textBlack}
                    >
                      {formatCurrency(product?.boxPrice || 0)}
                    </Typography>
                  </PriceContainer>
                </DetailsContainer>
                <Stack
                  flexDirection="row"
                  gap="4px"
                  minHeight="28px"
                  alignItems="center"
                >
                  <FlagCell
                    value={{ orderFrequency: product?.orderFrequency }}
                  />
                  <Typography
                    fontSize="0.75rem"
                    color={themes?.default?.textBlack}
                    letterSpacing="0.4px"
                  >
                    {product?.orderFrequency === OrderFrequency.NOT_ORDERED
                      ? t('common.not-ordered')
                      : t('products.order_frequency', {
                          frequency: t(`common.${product?.orderFrequency}`),
                        })}
                  </Typography>
                </Stack>
              </Stack>
            </InfoActionsConteiner>

            <ImageGallery pictures={product?.pictures} />
          </DetailsWrapper>

          <TabsSectionWrapper>
            <TabContext value={selectedTab}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  width: '100%',
                }}
              >
                <TabList
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                  sx={{
                    flex: '1',
                  }}
                >
                  <StyledTab label="DESCRIPTION" value="1" />
                  <StyledTab label="INGREDIENTS" value="2" />
                  <StyledTab label="NUTRITION FACTS" value="3" />
                </TabList>
                <TabsConteiner>
                  <TabPanel value="1" sx={{ padding: 0 }}>
                    <br />
                    <InfoRow>
                      <Label>Net Weight</Label>
                      <Detail>{product?.weight} Kg</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Pallet Quantity</Label>
                      <Detail>{product?.pallet} Items/Box</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Collection Unit Price</Label>
                      <Detail>$0.48 each</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Size Or Cut</Label>
                      <Detail>1x48</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Quantity per Layer</Label>
                      <Detail>7</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>
                        Storage: Keep frozen at -18 C. Do not refreeze after
                        defrosting
                      </Label>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>
                        Crisp, flaky all-butter frozen pastry with a smooth dark
                        chocolate centre.
                        <br />
                        <b>Cooking Instructions</b>
                        <br />
                        Spread out on a baking tray and leave to defrost and
                        prove overnight at room temperature. Egg wash and bake
                        in the oven at 190°c/ 375°f/ Gas Mark 5 until golden
                        brown (Approximately 8-10 minutes).
                      </Label>
                    </InfoRow>
                  </TabPanel>
                  <TabPanel value="2" sx={{ padding: 0 }}>
                    <br />
                    <Typography>
                      WHEAT Flour, Water, Sugar, Rapeseed Oil, Mix [WHEAT Flour,
                      WHEAT Gluten, EGG Powder, Skimmed MILK Powder, Whey Powder
                      (MILK), Emulsifiers: E481 (Sodium Stearoyl-2-Lactylate)
                      And E471 (Mono-And Diglycerides Of Fatty Acids), Aroma,
                      Colour: E160a (Carotenes)], Butter Powder (MILK), WHEAT
                      Gluten, Yeast, Glaze [Water, Vegetable Protein, Sunflower
                      Oil, Dextrose, Maltodextrine, Starch], EGG Powder, Salt.
                    </Typography>
                    <br />
                    <Typography>
                      <b>Allergy Advice</b>
                    </Typography>
                    <hr />
                    <Typography>May contain sesame seeds and soya.</Typography>
                    <Typography>
                      WHEAT Flour, WHEAT Gluten, Skimmed MILK Powder
                    </Typography>
                  </TabPanel>
                  <TabPanel value="3" sx={{ padding: 0 }}>
                    <br />
                    <InfoRow>
                      <LabelHead>Per 100g/ml</LabelHead>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Energy</Label>
                      <Detail>1304 KCAL</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Fat</Label>
                      <Detail>8.3 G</Detail>
                    </InfoRow>
                    <InfoRow>
                      <Label>Of which Saturates</Label>
                      <Detail>2.1 G</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Carbohydrates</Label>
                      <Detail>48 G</Detail>
                    </InfoRow>
                    <InfoRow>
                      <Label>Of which Sugars</Label>
                      <Detail>8.8 G</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Protein</Label>
                      <Detail>9.7 G</Detail>
                    </InfoRow>
                    <Line />
                    <InfoRow>
                      <Label>Salt</Label>
                      <Detail>0.7 G</Detail>
                    </InfoRow>
                    <Line />
                  </TabPanel>
                </TabsConteiner>
              </Box>
            </TabContext>
          </TabsSectionWrapper>
        </ContentWrapper>
        <CustomerOrdersPopover
          anchorEl={customerOrdersPopoverAnchorEl}
          handleClose={handleCloseCustomerOrderPopover}
          orders={[]}
        />
      </Wrapper>
    </TwoViewModal>
  );
}

const ImageGallery = ({ pictures }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [viewImageSrc, setViewImageSrc] = useState('');

  const handleImageClick = (imageSrc) => {
    setViewImageSrc(imageSrc);
  };

  return (
    <>
      {!pictures || pictures?.length === 0 ? (
        <SwiperSlide key="slide-1">
          <EmptyImageBox>
            <img src={EmptyBoxIcon} alt="Slide 1" height="118px" />
          </EmptyImageBox>
        </SwiperSlide>
      ) : pictures?.length > 1 ? (
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '60px !important',
              height: '100%',
              marginRight: '10px',
              position: 'relative',
            }}
          >
            <If condition={pictures?.length > 4}>
              <ArrowUpWrapper className="swiper-button-prev-custom">
                <StyledArrowUp />
              </ArrowUpWrapper>
              <ArrowDownWrapper className="swiper-button-next-custom">
                <StyledArrowDown />
              </ArrowDownWrapper>
            </If>

            <Swiper
              navigation={{
                nextEl: '.swiper-button-next-custom',
                prevEl: '.swiper-button-prev-custom',
              }}
              style={{ height: '293px' }}
              direction="vertical"
              onSwiper={setThumbsSwiper}
              loop={true}
              spaceBetween={10}
              slidesPerView={5}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
            >
              {pictures?.map((image, index) => (
                <SwiperSlide key={`slide-${index}`}>
                  <img
                    style={{
                      width: '70px',
                      height: '52px',
                      borderRadius: '4px',
                      objectFit: 'cover',
                    }}
                    src={getImgCdn(image?.key, 64)}
                    alt={`Slide ${index}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div style={{ flex: '1', height: '293px' }}>
            <Swiper
              direction="vertical"
              style={{ height: '100%' }}
              loop={true}
              spaceBetween={10}
              navigation={false}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper2"
            >
              {pictures?.map((image, index) => (
                <SwiperSlide key={`slide-${index}`}>
                  <MainImage
                    src={getImgCdn(image?.key)}
                    alt={`Slide ${index}`}
                    onClick={() => handleImageClick(getImgCdn(image.key))}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : (
        <div style={{ height: '293px' }}>
          <Swiper
            direction="vertical"
            style={{ height: '100%' }}
            loop={true}
            spaceBetween={10}
            navigation={false}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {pictures?.map((image, index) => (
              <SwiperSlide key={`slide-${index}`}>
                <If
                  condition={!!image?.key}
                  otherwise={
                    <EmptyImageBox>
                      <img src={EmptyBoxIcon} alt="Slide 1" height="118px" />
                    </EmptyImageBox>
                  }
                >
                  <MainImage
                    src={getImgCdn(image.key)}
                    alt={`Slide ${index}`}
                    onClick={() => handleImageClick(getImgCdn(image.key))}
                  />
                </If>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      <FullScreenImageView
        open={!!viewImageSrc}
        handleClose={() => setViewImageSrc('')}
        imgSrc={viewImageSrc}
      />
    </>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  padding-top: 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const StyledArrowUp = styled(KeyboardArrowUpOutlined)``;

const StyledArrowDown = styled(KeyboardArrowDownOutlined)``;

const ArrowUpWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  top: 4px;
  border-radius: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    color: rgba(129, 145, 152, 1);
  }
`;

const ArrowDownWrapper = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: white;
  bottom: 7px;
  border-radius: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    color: rgba(129, 145, 152, 1);
  }
`;

const InfoRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '5px',
});

const LabelHead = styled(Typography)({
  fontWeight: '500 !important',
  fontSize: '1.143rem !important',
});

const Label = styled(Typography)({
  fontWeight: '400 !important',
  fontSize: '1rem !important',
  color: 'rgba(0, 0, 0, 0.87)',
});

const Detail = styled(Typography)({
  textAlign: 'right',
  fontWeight: '500 !important',
  fontSize: '1rem !important',
});

const Line = styled('hr')({
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  border: 'none',
  height: '1px',
  margin: '10px 0',
});

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const PriceContainer = styled.div`
  align-self: flex-end;
`;

const StyledTab = styled(Tab)`
  flex: 1;
  &.Mui-selected {
    background-color: rgba(131, 42, 255, 0.04);
  }
`;

const DetailsWrapper = styled.div`
  flex: 1;
  height: fit-content;
  max-height: 608px;
  min-width: 381px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
`;

const EmptyImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 10px solid ${themes?.default?.gainsboro2};
  border-radius: 6px;
  height: 293px;
`;

const InfoActionsConteiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TopBarActionsConteiner = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 293px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid ${themes?.default?.borderSilverLight};
  cursor: zoom-in;
`;

const menuButtonSx: any = {
  display: 'flex',
  alignItems: 'cetner',
  gap: '10px',
  width: 'fit-content',
  height: '28px',
  padding: '2px 10px',
  borderRadius: '4px',
  border: `1px solid ${themes?.default?.ordoriaLight}`,
};

const TabsSectionWrapper = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TabsConteiner = styled.div`
  height: calc(100vh - 44px - 12px - 406px - 20px - 50px - 12px);
  overflow-y: auto;
  padding: 0px 16px;
`;
